import WebViewer, {WebViewerInstance} from '@pdftron/webviewer'
import React, {FC, ComponentProps} from 'react'
import {Select, MenuItem, FormControl} from '@material-ui/core'
import {
  flowMax,
  addDisplayName,
  addRef,
  addState,
  addEffect,
  addHandlers,
  addWrapper,
  addProps,
  addStateHandlers,
  branch,
  renderNothing,
} from 'ad-hok'
import {Modal, Card, CardContent} from '@material-ui/core'
import {
  branchIfFalsy,
  branchIfFailsPredicate,
  branchIfNullish,
  branchIfTruthy,
  removeProps,
} from 'ad-hok-utils'
import {forEach} from 'lodash'
import {PureQueryOptions} from 'apollo-boost'

import {makeClasses, addClasses} from 'theme'
import typedAs from 'utils/typedAs'
import Body1 from 'components/Body1'
import {FileTemplates_fileTemplates} from 'graphql/deserializedTypes/FileTemplates'
import {
  addCreateEditableFileMutation,
  addUpdateEditableFileMutation,
  addFileTemplateMappingQuery,
  addPersonQuery,
  addFederalPovertyLevelsQuery,
  addCountyFaxesQuery,
  addDeleteEditableFileApplicationAssociationMutation,
  addMarkReceivedWebformFileTemplateClearedMutation,
} from 'graphql/generated'
import {addApplicationFormContext} from 'components/EditApplicationForm/applicationFormContext'
import Button from 'components/Button'
import {addTranslationHelpers} from 'utils/i18n'
import {EditableFileFields} from 'graphql/deserializedTypes/EditableFileFields'
import {EDITABLE_FILES_QUERY} from 'graphql/queries'
import addFetchSignedDocumentUploadUrl from 'utils/addFetchSignedDocumentUploadUrl'
import {EditableFileStatus} from 'graphql/deserializedTypes/globalTypes'
import Tooltip from 'components/Tooltip'
import {isDataLoading} from 'utils/dataLoading'
import {FileTemplateMapping_fileTemplateMapping} from 'graphql/deserializedTypes/FileTemplateMapping'
import {addAppSnackbarContext} from 'utils/addAppSnackbar'
import ConfirmationDialog from 'components/ConfirmationDialog'
import {getPdftronLicenseKey} from 'utils/pdftron'
import {
  getCharityCareApplicationMapping,
  getWebformRecordsFileTemplateMapping,
  getMedicaidApplicationMapping,
  getSlideApplicationMapping,
} from 'utils/fileTemplateMapping'
import {addFetchDocumentFileUrl} from 'components/DocumentFileLink'
import {isSignature} from 'graphql/converters'
import {PersonReceivedWebformFileTemplates_person_mostRecentReceivedWebform_receivedWebformFileTemplates} from 'graphql/deserializedTypes/PersonReceivedWebformFileTemplates'
import {addIsPatientPortal} from 'utils/patientPortal'
import {makeEditableFileKey} from 'utils/editableFiles'
import {getHouseholdMembersWithRelationshipTypes} from 'utils/householdMembers'
import {addLoadingIndicator} from 'utils/dataLoading'
import {getFormattedPhoneNumber} from 'utils/phone'

const classes = makeClasses((theme) => ({
  backdrop: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    pointerEvents: 'none',
  },
  card: {
    pointerEvents: 'auto',
    width: 'calc(100% - 100px)',
    height: 'calc(100% - 100px)',
    display: 'flex',
  },
  contents: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  pdfContainer: {
    flex: 1,
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  buttonSubContainer: {
    display: 'flex',
  },
  outForSigning: {
    textAlign: 'center',
  },
  deleteButtonWrapper: {
    marginRight: 'auto',
  },
  discardButton: {
    marginRight: 'auto',
  },
  phoneNumbersContainer: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginRight: 'auto',
  },
  sendToCellForSigningButton: {
    marginLeft: theme.spacing(1),
  },
}))

type AddTooltipIfDisabled = <
  TProps extends {
    disabled?: boolean
  }
>(
  props: TProps
) => TProps

const addTooltipIfDisabled: AddTooltipIfDisabled = flowMax(
  addTranslationHelpers,
  addWrapper((render, {disabled, t}) =>
    disabled ? (
      <Tooltip title={t('editPdfDialog.saveInProgress')}>
        <div>{render()}</div>
      </Tooltip>
    ) : (
      <>{render()}</>
    )
  )
)

type AddFaxTooltipIfDisabled = <
  TProps extends {
    disabled?: boolean
  }
>(
  props: TProps
) => TProps

const addFaxTooltipIfDisabled: AddFaxTooltipIfDisabled = flowMax(
  addTranslationHelpers,
  addWrapper((render, {disabled, t}) =>
    disabled ? (
      <Tooltip title={t('editPdfDialog.saveInProgress')}>
        <div>{render()}</div>
      </Tooltip>
    ) : (
      <>{render()}</>
    )
  )
)

interface SaveButtonProps extends ComponentProps<typeof Button> {
  file?: EditableFileFields
}

const SaveButton: FC<SaveButtonProps> = flowMax(
  addDisplayName('SaveButton'),
  addIsPatientPortal,
  addProps(({file, isPatientPortal}) => ({
    outForSigning:
      file?.status === EditableFileStatus.outForSigning && !isPatientPortal,
  })),
  addTooltipIfDisabled,
  addTranslationHelpers,
  addWrapper((render, {outForSigning, t}) =>
    outForSigning ? (
      <Tooltip title={t('editPdfDialog.outForSigningDisabled')}>
        <div>{render()}</div>
      </Tooltip>
    ) : (
      <>{render()}</>
    )
  ),
  removeProps(['isPatientPortal']),
  ({disabled, outForSigning, t, ...props}) => (
    <Button {...props} disabled={disabled || outForSigning} />
  )
)

const getBlobFromPdf = async (instance: WebViewerInstance) => {
  const {documentViewer, annotationManager} = instance.Core

  const document = documentViewer.getDocument()
  const xfdfString = await annotationManager.exportAnnotations()

  const data = await document.getFileData({xfdfString})
  return new Blob([new Uint8Array(data)], {type: 'application/pdf'})
}

type AddSetReadonlyOnMountType = <
  TProps extends {
    instance: WebViewerInstance | null
    file: FileTemplateWithSignedUrl | EditableFileWithSignedUrl
  }
>(
  props: TProps
) => TProps

const addSetReadonlyOnMount: AddSetReadonlyOnMountType = addEffect(
  ({instance, file}) => () => {
    if (!instance) {
      return
    }

    const {annotationManager, Annotations} = instance.Core

    const readonly = isEditableFile(file) && file.status === 'outForSigning'
    if (readonly) {
      annotationManager.enableReadOnlyMode()

      // https://www.pdftron.com/documentation/web/guides/forms/modify-fields/
      annotationManager.addEventListener(
        'annotationChanged',
        (annotations, action, {imported}) => {
          if (imported && action === 'add') {
            annotations.forEach((annot: any) => {
              if (annot instanceof Annotations.WidgetAnnotation) {
                annot.fieldFlags.set('ReadOnly', true)
              }
            })
          }
        }
      )
    }
  },
  ['instance', 'file']
)

type AddFileTemplateMapping = <
  TProps extends {
    applicationId: string
    personId: string
    receivedWebformFileTemplate?: ReceivedWebformFileTemplateType | null
  }
>(
  props: TProps
) => TProps & {
  fileTemplateMapping: Pick<
    FileTemplateMapping_fileTemplateMapping,
    'mapping'
  > | null
}

const addFileTemplateMapping: AddFileTemplateMapping = flowMax(
  addFileTemplateMappingQuery({
    variables: ({applicationId, receivedWebformFileTemplate}) =>
      !!receivedWebformFileTemplate
        ? {
            receivedWebformFileTemplateId: receivedWebformFileTemplate.id,
            applicationId,
          }
        : {applicationId},
    fetchPolicy: 'network-only',
  }),
  addApplicationFormContext,
  addPersonQuery({
    variables: ({personId: id}) => ({
      id,
    }),
  }),
  addFederalPovertyLevelsQuery({
    variables: ({
      application: {benefit: String, initialDateOfService: Date},
    }) => ({
      benefit: String,
      initialDateOfService: Date,
    }),
  }),
  addTranslationHelpers,
  addProps(
    ({
      fileTemplateMapping,
      person,
      application,
      federalPovertyLevels,
      receivedWebformFileTemplate,
      application: {initialDateOfService},
      t,
    }) => ({
      initialDateOfService: {application: {initialDateOfService}},
      fileTemplateMapping: isDataLoading(fileTemplateMapping)
        ? null
        : receivedWebformFileTemplate
        ? {
            mapping: {
              ...fileTemplateMapping.data.mapping,
              ...getWebformRecordsFileTemplateMapping(
                receivedWebformFileTemplate.receivedWebform.response
                  .nestedPerson
              ),
            },
          }
        : isDataLoading(person) || isDataLoading(federalPovertyLevels)
        ? null
        : {
            mapping: {
              ...fileTemplateMapping.data.mapping,
              ...getCharityCareApplicationMapping({
                application,
                person: person.data,
                federalPovertyLevels: federalPovertyLevels.data,
                t,
              }),
              ...getMedicaidApplicationMapping({
                application,
                person: person.data,
              }),
              ...getSlideApplicationMapping({
                application,
                person: person.data,
                federalPovertyLevels: federalPovertyLevels.data,
                initialDateOfService: initialDateOfService
                  ? initialDateOfService
                  : new Date(),
                t,
              }),
            },
          },
    }),
    [
      'fileTemplateMapping',
      'application',
      'person',
      'federalPovertyLevels',
      'receivedWebformFileTemplate',
      't',
    ]
  )
)

const hideSignHereButtons = ({
  Core: {
    Annotations: {SignatureWidgetAnnotation},
  },
}: WebViewerInstance) => {
  const createSignHereElement =
    SignatureWidgetAnnotation.prototype.createSignHereElement

  SignatureWidgetAnnotation.prototype.createSignHereElement = function (
    ...args
  ) {
    const signHereElement = createSignHereElement.apply(this, args)

    signHereElement.style.display = 'none'
    return signHereElement
  }
}

const getDataUrlFromContents = (url: string): Promise<string> =>
  fetch(url)
    .then((response) => response.blob())
    .then(
      (blob) =>
        new Promise((callback) => {
          let reader = new FileReader()
          reader.onload = (event) => {
            callback(event!.target!.result as string)
          }
          reader.readAsDataURL(blob)
        })
    )

type AddFillFormFieldsOnMountType = <
  TProps extends {
    applicationId: string
    personId: string
    instance: WebViewerInstance | null
    file: FileTemplateWithSignedUrl | EditableFileWithSignedUrl
    receivedWebformFileTemplate?: ReceivedWebformFileTemplateType | null
  }
>(
  props: TProps
) => TProps

const addFillFormFieldsOnMount: AddFillFormFieldsOnMountType = flowMax(
  addFileTemplateMapping,
  addState('hasFilledFields', 'setHasFilledFields', false),
  addFetchDocumentFileUrl,
  addEffect(
    ({
      instance,
      file,
      fileTemplateMapping,
      hasFilledFields,
      setHasFilledFields,
      fetchDocumentFileUrl,
    }) => () => {
      if (!instance || !fileTemplateMapping || hasFilledFields) {
        return
      }

      hideSignHereButtons(instance)

      const {documentViewer, annotationManager, Annotations} = instance.Core

      if (isFileTemplate(file)) {
        documentViewer.addEventListener('documentLoaded', () => {
          documentViewer.getAnnotationsLoadedPromise().then(() => {
            const fieldManager = annotationManager.getFieldManager()

            forEach(fileTemplateMapping.mapping, (value, key) => {
              const field = fieldManager.getField(key)
              if (!field || !value) return

              if (isSignature(value)) {
                if (!(field instanceof Annotations.Forms.Field)) return

                fetchDocumentFileUrl(value)
                  .then(getDataUrlFromContents)
                  .then((dataUrl) => {
                    field.widgets?.forEach((widget) => {
                      const stampAnnot = new Annotations.StampAnnotation()
                      stampAnnot.PageNumber = widget.getPageNumber()
                      stampAnnot.X = widget.getX()
                      stampAnnot.Y = widget.getY()
                      stampAnnot.Width = widget.getWidth()
                      stampAnnot.Height = widget.getHeight()
                      stampAnnot.Author = annotationManager.getCurrentUser()
                      stampAnnot.setImageData(dataUrl, false)

                      annotationManager.addAnnotation(stampAnnot)
                      annotationManager.redrawAnnotation(stampAnnot)
                    })
                  })
              } else {
                field.setValue(value)
              }
            })
          })
        })
      }

      setHasFilledFields(true)
    },
    ['instance', 'file', 'fileTemplateMapping', 'hasFilledFields']
  )
)

export interface FileTemplateWithSignedUrl extends FileTemplates_fileTemplates {
  signedUrl: string
}

export interface EditableFileWithSignedUrl extends EditableFileFields {
  signedUrl: string
}

const isEditableFile = (
  value: FileTemplateWithSignedUrl | EditableFileWithSignedUrl
): value is EditableFileWithSignedUrl => value['__typename'] === 'EditableFile'

const isFileTemplate = (
  value: FileTemplateWithSignedUrl | EditableFileWithSignedUrl
): value is FileTemplateWithSignedUrl => value['__typename'] === 'FileTemplate'

interface DeleteButtonProps {
  file: FileTemplateWithSignedUrl | EditableFileWithSignedUrl | null
  applicationId: string
  onClose: () => void
  disabled?: boolean
  onDelete?: () => void
}

const DeleteButton: FC<DeleteButtonProps> = flowMax(
  addDisplayName('DeleteButton'),
  addClasses(classes),
  addWrapper((render, {classes}) => (
    <div className={classes.deleteButtonWrapper}>{render()}</div>
  )),
  addTooltipIfDisabled,
  branchIfNullish('file'),
  branchIfFailsPredicate('file', isEditableFile),
  addIsPatientPortal,
  branchIfTruthy('isPatientPortal'),
  addApplicationFormContext,
  addProps(({file, isPatientPortal}) => ({
    outForSigning:
      file?.status === EditableFileStatus.outForSigning && !isPatientPortal,
  })),
  removeProps(['isPatientPortal']),
  addProps(({disabled, outForSigning}) => ({
    disabled: disabled || outForSigning,
  })),
  addDeleteEditableFileApplicationAssociationMutation({
    variables: ({file: {id: editableFileId}, applicationId}) => ({
      editableFileId,
      applicationId,
    }),
    refetchQueries: ({application}) => [
      {
        query: EDITABLE_FILES_QUERY,
        variables: {
          applicationId: application.id,
        },
      },
    ],
    awaitRefetchQueries: true,
  }),
  addStateHandlers(
    {
      isShowingConfirmDialog: false,
    },
    {
      onClick: () => () => ({
        isShowingConfirmDialog: true,
      }),
      onConfirmCancel: () => () => ({
        isShowingConfirmDialog: false,
      }),
    }
  ),
  addAppSnackbarContext,
  addTranslationHelpers,
  addHandlers({
    onConfirm: ({
      mutateDeleteEditableFileApplicationAssociation,
      onClose,
      showSnackbarMessage,
      onDelete,
      t,
    }) => () => {
      mutateDeleteEditableFileApplicationAssociation().then(() => {
        showSnackbarMessage(t('editPdfDialog.editableFileDeleted'))
        onDelete?.()
      })
      onClose()
    },
  }),
  ({
    onClick,
    isShowingConfirmDialog,
    onConfirmCancel,
    onConfirm,
    disabled,
    t,
  }) => (
    <>
      <Button onClick={onClick} color="primary" disabled={disabled}>
        {t('buttons.delete')}
      </Button>
      <ConfirmationDialog
        open={isShowingConfirmDialog}
        onConfirm={onConfirm}
        onCancel={onConfirmCancel}
        cancelText={t('buttons.cancel')}
        confirmText={t('buttons.delete')}
        title={t('editPdfDialog.confirmDeleteTitle')}
        message={t('editPdfDialog.confirmDeleteMessage')}
      />
    </>
  )
)

interface DiscardButtonProps {
  onClick: () => void
  disabled?: boolean
}

const DiscardButton: FC<DiscardButtonProps> = flowMax(
  addDisplayName('DiscardButton'),
  addTooltipIfDisabled,
  addTranslationHelpers,
  addClasses(classes),
  ({onClick, disabled, classes, t}) => (
    <Button
      onClick={onClick}
      color="secondary"
      disabled={disabled}
      className={classes.discardButton}
    >
      {t('buttons.discard')}
    </Button>
  )
)

interface CancelButtonProps {
  onClick: () => void
  disabled?: boolean
}

const CancelButton: FC<CancelButtonProps> = flowMax(
  addDisplayName('CancelButton'),
  addIsPatientPortal,
  branchIfTruthy('isPatientPortal'),
  addTooltipIfDisabled,
  addTranslationHelpers,
  ({onClick, disabled, t}) => (
    <Button onClick={onClick} color="secondary" disabled={disabled}>
      {t('editPdfDialog.cancel')}
    </Button>
  )
)

type ReceivedWebformFileTemplateType = PersonReceivedWebformFileTemplates_person_mostRecentReceivedWebform_receivedWebformFileTemplates

type AddApplicationFormSpecificBehavior = <
  TProps extends {
    personId: string
    instance: WebViewerInstance | null
    file: FileTemplateWithSignedUrl | EditableFileWithSignedUrl
    receivedWebformFileTemplate?: ReceivedWebformFileTemplateType | null
  }
>(
  props: TProps
) => TProps & {
  refetchQueries: PureQueryOptions[]
}

const addApplicationFormSpecificBehavior: AddApplicationFormSpecificBehavior = flowMax(
  addIsPatientPortal,
  branch(
    ({isPatientPortal}) => !isPatientPortal,
    flowMax(
      addApplicationFormContext,
      addProps(({application: {id: applicationId}}) => ({
        applicationId,
      })),
      addSetReadonlyOnMount,
      addFillFormFieldsOnMount,
      addProps(({applicationId}) => ({
        refetchQueries: [
          {
            query: EDITABLE_FILES_QUERY,
            variables: {applicationId},
          },
        ],
      }))
    ),
    addProps({
      refetchQueries: [],
    })
  )
)

interface SendDocumentForRemoteSigningButtonProps {
  onSend: (esignSessionId: string) => void
  disabled: boolean
  file?: EditableFileFields
}

const SendDocumentForRemoteSigningButton: FC<SendDocumentForRemoteSigningButtonProps> = flowMax(
  addDisplayName('SendDocumentForRemoteSigningButton'),
  addApplicationFormContext,
  addTranslationHelpers,
  branch(
    ({application: {householdMembers}}) => !householdMembers.length,
    renderNothing()
  ),
  addPersonQuery({
    variables: ({
      application: {
        person: {id},
      },
    }) => ({id}),
  }),
  addLoadingIndicator({}),
  addProps(
    ({application, person}) => ({
      phoneNumbers: getHouseholdMembersWithRelationshipTypes({
        application,
        person,
      })
        .map(({relationshipType, person}) => ({
          relationshipType,
          phoneNumbers: person.phoneNumbers.filter(
            ({usedForEsign}) => usedForEsign
          ),
        }))
        .flatMap(({relationshipType, phoneNumbers}) =>
          phoneNumbers.map(({id, number, comment}) => ({
            id,
            esignSessionId: id!,
            display: `${getFormattedPhoneNumber(number)} - ${relationshipType}${
              comment && comment.length > 0
                ? `- ${comment.substring(0, 15)}`
                : ''
            }`,
          }))
        ),
    }),
    ['application', 'person']
  ),
  addState(
    'selected',
    'setSelected',
    ({phoneNumbers}) => phoneNumbers[0]?.esignSessionId
  ),
  addHandlers({
    selectChangeHandler: ({setSelected}) => (
      event: React.ChangeEvent<{value: unknown}>
    ) => {
      const esignSessionId = event.target.value as string
      setSelected(esignSessionId)
    },
    onSend: ({onSend, selected}) => () => {
      if (!selected) return
      onSend(selected)
    },
  }),
  addTooltipIfDisabled,
  addIsPatientPortal,
  addProps(({file, isPatientPortal}) => ({
    outForSigning:
      file?.status === EditableFileStatus.outForSigning && !isPatientPortal,
  })),
  addWrapper((render, {outForSigning, t}) =>
    outForSigning ? (
      <Tooltip title={t('editPdfDialog.outForSigningDisabled')}>
        <div>{render()}</div>
      </Tooltip>
    ) : (
      <>{render()}</>
    )
  ),
  removeProps(['isPatientPortal']),
  addProps(({disabled, outForSigning}) => ({
    disabled: disabled || outForSigning,
  })),
  addClasses(classes),
  ({
    phoneNumbers,
    selected,
    selectChangeHandler,
    onSend,
    classes,
    disabled,
  }) => (
    <div>
      {!!phoneNumbers.length && (
        <div>
          <FormControl size="medium" margin="dense">
            <Select
              id="phone-select"
              value={selected}
              onChange={selectChangeHandler}
              variant="outlined"
            >
              {phoneNumbers.map((phoneNumber) => (
                <MenuItem
                  value={phoneNumber.esignSessionId}
                  key={phoneNumber.id}
                >
                  {phoneNumber.display}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl
            size="medium"
            margin="dense"
            className={classes.sendToCellForSigningButton}
          >
            <Button onClick={onSend} color="primary" disabled={disabled}>
              Send File to Cell
            </Button>
          </FormControl>
        </div>
      )}
      {phoneNumbers.length === 0 && (
        <b style={{color: 'red'}}>Please add/enable Client Phone for E-Sign</b>
      )}
    </div>
  )
)

interface FaxDocumentButtonProps {
  onSend: (countyFaxId: string) => void
  disabled: boolean
  file?: EditableFileFields
}

const FaxDocumentButton: FC<FaxDocumentButtonProps> = flowMax(
  addDisplayName('FaxDocumentButton'),
  addApplicationFormContext,
  addTranslationHelpers,
  branch((countyFaxes) => !countyFaxes, renderNothing()),
  //branchIfEmpty('countyFaxes'),
  addCountyFaxesQuery({}),
  addLoadingIndicator({}),
  addState('selected', 'setSelected', ({countyFaxes}) =>
    countyFaxes.length ? countyFaxes[0].id : '0'
  ),
  addHandlers({
    selectChangeHandler: ({setSelected}) => (
      event: React.ChangeEvent<{value: unknown}>
    ) => {
      const countyFaxId = event.target.value as string
      setSelected(countyFaxId)
    },
    onSend: ({onSend, selected}) => () => {
      if (!selected) return
      onSend(selected)
    },
  }),
  addFaxTooltipIfDisabled,
  addIsPatientPortal,
  removeProps(['isPatientPortal']),
  addClasses(classes),
  ({countyFaxes, selected, selectChangeHandler, onSend, classes, disabled}) => (
    <div>
      {!!countyFaxes.length && (
        <div>
          <FormControl size="medium" margin="dense">
            <Select
              id="fax-select"
              value={selected}
              onChange={selectChangeHandler}
              variant="outlined"
            >
              {countyFaxes.map((countyFax) => (
                <MenuItem value={countyFax.id} key={countyFax.id}>
                  {countyFax.countyName} County ({countyFax.state.stateCode})
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl
            size="medium"
            margin="dense"
            className={classes.sendToCellForSigningButton}
          >
            <Button onClick={onSend} color="primary" disabled={disabled}>
              Fax File to County
            </Button>
          </FormControl>
        </div>
      )}
      {countyFaxes.length === 0 && (
        <b style={{color: 'red'}}>No county fax numbers available</b>
      )}
    </div>
  )
)

interface Props {
  personId: string
  benefit?: string
  applicationId?: string
  faxEnabled?: boolean | null
  file: FileTemplateWithSignedUrl | EditableFileWithSignedUrl | null
  onClose: () => void
  receivedWebformFileTemplate?: ReceivedWebformFileTemplateType | null
  onDelete?: () => void
  disallowClosingWithoutSave?: boolean
  title?: string
}

const EditPdfDialog: FC<Props> = flowMax(
  addDisplayName('EditPdfDialog'),
  addWrapper((render, {file, onClose, disallowClosingWithoutSave}) => (
    <Modal
      open={!!file}
      onClose={disallowClosingWithoutSave ? () => {} : onClose}
    >
      <>{render()}</>
    </Modal>
  )),
  branchIfFalsy('file'),
  addRef('viewerRef', typedAs<HTMLDivElement | null>(null)),
  addState('instance', 'setInstance', typedAs<WebViewerInstance | null>(null)),
  addEffect(
    ({file: {signedUrl}, viewerRef, setInstance}) => () => {
      async function initWebViewer() {
        const response = await fetch(decodeURIComponent(signedUrl))
        const contentType = response.headers.get('content-type')
        const extension =
          contentType === 'image/jpeg'
            ? 'jpeg'
            : contentType === 'image/png'
            ? 'png'
            : 'pdf'
        const webViewer = await WebViewer(
          {
            path: '/assets/webviewer/lib',
            initialDoc: decodeURIComponent(signedUrl),
            extension,
            licenseKey: getPdftronLicenseKey(),
          },
          viewerRef.current!
        )
        setInstance(webViewer)
      }
      initWebViewer()
    },
    ['signedUrl']
  ),
  addApplicationFormSpecificBehavior,
  addCreateEditableFileMutation({}),
  addUpdateEditableFileMutation({}),
  addFetchSignedDocumentUploadUrl,
  addMarkReceivedWebformFileTemplateClearedMutation({}),
  addHandlers({
    markCleared: ({
      receivedWebformFileTemplate,
      mutateMarkReceivedWebformFileTemplateCleared,
    }) => () => {
      if (!receivedWebformFileTemplate) return
      mutateMarkReceivedWebformFileTemplateCleared({
        variables: {
          id: receivedWebformFileTemplate.id,
        },
        optimisticResponse: {
          markReceivedWebformFileTemplateCleared: {
            __typename: 'ReceivedWebformFileTemplate',
            id: receivedWebformFileTemplate.id,
            cleared: true,
          },
        },
      })
    },
  }),
  addStateHandlers(
    {
      isSaving: false,
    },
    {
      onSaving: () => () => ({
        isSaving: true,
      }),
      onNoLongerSaving: () => () => ({
        isSaving: false,
      }),
    }
  ),
  addIsPatientPortal,
  addTranslationHelpers,
  addProps(({benefit, faxEnabled, file, title}) => ({
    name: title ?? (isEditableFile(file) ? file.filename : file.name),
    editableFile: isEditableFile(file) ? file : undefined,
    outForSigning:
      isEditableFile(file) && file.status === EditableFileStatus.outForSigning,
    sendDocumentType:
      (isEditableFile(file) || isFileTemplate(file)) && file.documentSendType
        ? file.documentSendType
        : undefined,
    isEsignDocument:
      (isEditableFile(file) || isFileTemplate(file)) &&
      file.documentSendType === 'eSignDocument'
        ? true
        : false,
    isDocumentSendAndSave:
      (isEditableFile(file) || isFileTemplate(file)) &&
      file.documentSendType === 'editAndSendDocument'
        ? true
        : false,
    isDocumentSendOnly:
      isFileTemplate(file) && file.documentSendType === 'sendDocument'
        ? true
        : false,
    isFaxableDocument:
      faxEnabled &&
      benefit === 'medicaid' &&
      isEditableFile(file) &&
      (file.filename?.startsWith('FINAL_') ||
        file.filename?.startsWith('DRAFT_'))
        ? true
        : false,
  })),
  addHandlers({
    onSave: ({
      file,
      personId,
      applicationId,
      refetchQueries,
      mutateCreateEditableFile,
      mutateUpdateEditableFile,
      onClose,
      fetchSignedDocumentUploadUrl,
      instance,
      markCleared,
      onSaving,
      onNoLongerSaving,
      isPatientPortal,
      sendDocumentType,
      isDocumentSendOnly,
      isDocumentSendAndSave,
      isEsignDocument,
      t,
    }) => async ({
      sendForSigning,
      sendForRemoteSigningSessionId,
      sendForFax,
    }: {
      sendForSigning: boolean
      sendForRemoteSigningSessionId?: string
      sendForFax?: string
    }) => {
      if (!instance) {
        return
      }

      onSaving()
      try {
        const blob = await getBlobFromPdf(instance)
        const fileKey = makeEditableFileKey(personId)
        const url = await fetchSignedDocumentUploadUrl(fileKey)

        const response = await fetch(url, {method: 'PUT', body: blob})
        if (!response.ok) {
          throw new Error('S3 upload failed')
        }

        if (isEditableFile(file)) {
          await mutateUpdateEditableFile({
            variables: {
              editableFile: {
                id: file.id,
                extension: 'pdf',
                fileKey,
                ...(isPatientPortal
                  ? {
                      status: EditableFileStatus.returned,
                    }
                  : {}),
              },
              applicationId,
              sendForSigning,
              sendForRemoteSigningSessionId,
              sendForFax,
              markUnreviewed: isPatientPortal,
              sendDocumentType,
              isDocumentSendOnly,
              isDocumentSendAndSave,
              isEsignDocument,
            },
            refetchQueries,
          })
        } else {
          await mutateCreateEditableFile({
            variables: {
              editableFile: {
                personId,
                fileKey,
                documentName: file.name,
                extension: 'pdf',
                allowSigning: file.allowSigning,
              },
              applicationId,
              sendForSigning,
              sendForRemoteSigningSessionId,
              sendDocumentType,
              isDocumentSendOnly,
              isDocumentSendAndSave,
              isEsignDocument,
            },
            refetchQueries,
          })
        }

        markCleared()

        onClose()
        onNoLongerSaving()
      } catch (e) {
        onNoLongerSaving()
        window.alert(t('editPdfDialog.failedToSave'))
      }
    },
    onDiscardClick: ({markCleared, onClose}) => () => {
      markCleared()
      onClose()
    },
  }),
  addProps(({isSaving}) => ({
    areActionButtonsDisabled: isSaving,
  })),

  addClasses(classes),
  ({
    name,
    outForSigning,
    editableFile,
    viewerRef,
    onClose,
    onSave,
    file,
    onDiscardClick,
    receivedWebformFileTemplate,
    areActionButtonsDisabled,
    onDelete,
    isPatientPortal,
    applicationId,
    classes,
    isDocumentSendAndSave,
    isDocumentSendOnly,
    isFaxableDocument,
    sendDocumentType,
    t,
  }) => (
    <div className={classes.backdrop}>
      <Card className={classes.card}>
        <CardContent className={classes.contents}>
          <Body1>{name}</Body1>
          {outForSigning && !isPatientPortal && (
            <Body1 className={classes.outForSigning}>
              {t('editPdfDialog.outForSigning')}
            </Body1>
          )}
          <div ref={viewerRef} className={classes.pdfContainer} />
          <div className={classes.buttonContainer}>
            <div className={classes.buttonSubContainer}>
              {!!receivedWebformFileTemplate && (
                <DiscardButton
                  onClick={onDiscardClick}
                  disabled={areActionButtonsDisabled}
                />
              )}
              {applicationId && (
                <DeleteButton
                  file={file}
                  applicationId={applicationId}
                  onClose={onClose}
                  disabled={areActionButtonsDisabled}
                  onDelete={onDelete}
                />
              )}
            </div>
            {((!isPatientPortal && file.allowSigning) ||
              isDocumentSendOnly ||
              isDocumentSendAndSave) && (
              <div className={classes.buttonSubContainer}>
                <SendDocumentForRemoteSigningButton
                  file={editableFile}
                  onSend={(esignSessionId) =>
                    onSave({
                      sendForSigning: false,
                      sendForRemoteSigningSessionId: esignSessionId,
                    })
                  }
                  disabled={areActionButtonsDisabled}
                />
              </div>
            )}
            {!isPatientPortal && isFaxableDocument && (
              <div className={classes.buttonSubContainer}>
                <FaxDocumentButton
                  file={editableFile}
                  onSend={(countyFaxId) =>
                    onSave({
                      sendForSigning: false,
                      sendForFax: countyFaxId,
                    })
                  }
                  disabled={areActionButtonsDisabled}
                />
              </div>
            )}
            <div className={classes.buttonSubContainer}>
              <CancelButton
                onClick={onClose}
                disabled={areActionButtonsDisabled}
              />

              {!isDocumentSendOnly && (
                <SaveButton
                  file={editableFile}
                  onClick={() => onSave({sendForSigning: false})}
                  color="primary"
                  disabled={areActionButtonsDisabled}
                >
                  {isPatientPortal
                    ? t('patientPortal.submit')
                    : t('editPdfDialog.save')}
                </SaveButton>
              )}

              {((!isPatientPortal && file.allowSigning) ||
                (!isPatientPortal && !sendDocumentType)) && (
                <SaveButton
                  file={editableFile}
                  onClick={() => onSave({sendForSigning: true})}
                  color="primary"
                  disabled={areActionButtonsDisabled}
                >
                  {t('editPdfDialog.sendToTablet')}
                </SaveButton>
              )}
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  )
)

export default EditPdfDialog
