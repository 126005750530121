import {TFunction} from 'i18next'

import {getAge} from 'utils/date'
import {
  CI_STATUS_LEGAL_RESIDENT,
  CI_STATUS_OTHER,
  CI_STATUS_US_CITIZEN,
  MARITAL_STATUS_MARRIED,
  MARITAL_STATUS_SEPARATED,
  TAX_FILING_STATUS_DEPENDENT,
  TAX_FILING_STATUS_FILER,
  TAX_FILING_STATUS_FILING_JOINTLY,
  TAX_FILING_STATUS_NOT_FILING,
  WHO_SUPPORT_WITHOUT_INCOME_OTHER,
  WHO_SUPPORT_WITHOUT_INCOME_SELF,
  RELATIONSHIP_TYPE_SPOUSE,
  NJ_RESIDENCY_DOC_HOMELESS_ATTESTATION,
  NJ_RESIDENCY_DOC_OTHERS_ATTESTATION,
  CI_DOCUMENT_CANT_PROVIDE,
} from 'components/EditPersonForm/schema'
import {
  INCOME_SOURCE_TYPE_SALARY_WAGES,
  INCOME_SOURCE_TYPE_FREELANCE,
  INCOME_SOURCE_TYPE_SELF_EMPLOYED,
} from 'utils/form/fieldTypes'
import {
  makeDeterminerRule,
  START_TOKEN,
  makeDeterminer,
} from 'utils/form/determiners'
import {BenefitType} from 'utils/benefits'
import {
  Person_person_openApplications_MonitorApplication,
  Person_person_openApplications_CharityCareApplication,
  Person_person_openApplications_MedicaidApplication,
  Person_person_openApplications_SlideApplication,
  Person_person_openApplications_RyanWhiteApplication,
} from 'graphql/deserializedTypes/Person'
import {FederalPovertyLevels_federalPovertyLevels} from 'graphql/deserializedTypes/FederalPovertyLevels'
import {isUnder18, isUnder19, isUnder22} from 'utils/age'
import {isMinorMedicaid} from 'utils/medicaid'
import {
  CHARITY_CARE_INCOME_ANNUAL_NUM_MONTHS,
  isHouseholdIncomeIneligibleFullCharityCare,
  isHouseholdIncomeIneligibleFullMedicaid,
  isHouseholdIncomeIneligibleFullSlide,
  isHouseholdIncomeIneligibleFullRyanWhite,
  isHouseholdIncomeIneligiblePreliminaryCharityCare,
  isHouseholdIncomeIneligiblePreliminaryMedicaid,
} from 'utils/income'
import {isPersonInHousehold} from 'utils/householdSize'
import {isCharityCareApplicationWithInitialDateOfService} from 'utils/charityCare'
import {isSlideApplicationWithInitialDateOfService} from 'utils/slide'
import {isRyanWhiteApplicationWithInitialDateOfService} from 'utils/ryanWhite'
const CI_ELIGIBLE = 'C/I eligible'

const isOver65 = makeDeterminerRule({
  name: 'person.dob',
  necessaryIf: [START_TOKEN],
  ineligibleIf: ({value}) => value && getAge(value) >= 65,
})

const age = makeDeterminerRule({
  name: 'person.dob',
  necessaryIf: [CI_ELIGIBLE],
})

const alreadyHasInsurance = makeDeterminerRule({
  name: 'person.hasInsurance',
  necessaryIf: ['person.dob'],
  ineligibleIf: ({value}) => value,
  shouldCheckIneligibilityImmediately: true,
})

const doesntLiveInNj = makeDeterminerRule({
  name: 'person.livesInNj',
  necessaryIf: ['person.hasInsurance'],
  ineligibleIf: ({value}) => value === false,
  shouldCheckIneligibilityImmediately: true,
})

const ciStatus = makeDeterminerRule({
  name: 'person.ciStatus',
  necessaryIf: ['person.livesInNj'],
  achieves: ({value}) => (value === CI_STATUS_US_CITIZEN ? CI_ELIGIBLE : null),
})

const ciDocumentMedicaid = (
  application: Person_person_openApplications_MedicaidApplication
) =>
  makeDeterminerRule({
    name: 'person.ciDocumentType',
    necessaryIf: [START_TOKEN],
    necessaryIfResolved: necessaryUnlessNewbornMedicaid(application),
  })

const necessaryUnlessNewbornMedicaid = ({
  mcdType,
}: Person_person_openApplications_MedicaidApplication) => () =>
  mcdType !== 'Newborn'

const notFiveYearsInUs = makeDeterminerRule({
  name: 'person.fiveYearsInUs',
  necessaryIf: {'person.ciStatus': CI_STATUS_LEGAL_RESIDENT},
  achieves: ({
    value,
    values: {
      person: {pregnant},
      person,
    },
  }) =>
    value || (value === false && (isMinorMedicaid(person) || pregnant))
      ? CI_ELIGIBLE
      : null,
})

const specialCategory = makeDeterminerRule({
  name: 'person.specialCategory',
  necessaryIf: {
    'person.ciStatus': CI_STATUS_OTHER,
    'person.fiveYearsInUs': ({
      values: {
        person: {fiveYearsInUs, pregnant},
        person,
      },
    }: any) =>
      fiveYearsInUs === false && !isMinorMedicaid(person) && pregnant === false,
  },
  ineligibleIf: ({value}) => value === false,
  achieves: ({value}) => (value ? CI_ELIGIBLE : null),
})

const pregnant = makeDeterminerRule({
  name: 'person.pregnant',
  necessaryIf: {
    'person.fiveYearsInUs': ({value, values: {person}}: any) =>
      value === false && !isMinorMedicaid(person) && person.gender !== 'Male',
    [CI_ELIGIBLE]: ({values}: any) => values.person.gender !== 'Male',
  },
})

const maritalStatus = makeDeterminerRule({
  name: 'person.maritalStatus',
  necessaryIf: [CI_ELIGIBLE],
})

const livesWithSpouse = makeDeterminerRule({
  name: 'person.spouseLiveWithMe',
  necessaryIf: {
    'person.maritalStatus': [MARITAL_STATUS_MARRIED, MARITAL_STATUS_SEPARATED],
  },
})

const spousePregnant = makeDeterminerRule({
  name: 'person.spousePregnant',
  necessaryIf: {
    'person.spouseLiveWithMe': true,
  },
})

const expectedChildren = makeDeterminerRule({
  name: 'person.expectedChild',
  necessaryIf: {
    [CI_ELIGIBLE]: ({
      values: {
        person: {pregnant},
      },
    }: any) => pregnant,
  },
})

const taxFilingStatus = makeDeterminerRule({
  name: 'person.taxFilingStatus',
  necessaryIf: {
    [CI_ELIGIBLE]: true,
  },
})

const claimedBySomeoneOtherThanParents = makeDeterminerRule({
  name: 'person.claimedBySomeoneOtherThanParents',
  necessaryIf: {
    'person.taxFilingStatus': TAX_FILING_STATUS_DEPENDENT,
  },
})

const livingWithParentsWhoDoNotFileJointly = makeDeterminerRule({
  name: 'person.livingWithParentsWhoDoNotFileJointly',
  necessaryIf: {
    'person.taxFilingStatus': ({
      value,
      values: {
        person: {claimedBySomeoneOtherThanParents},
        person,
      },
    }: any) =>
      value === TAX_FILING_STATUS_DEPENDENT &&
      isMinorMedicaid(person) &&
      claimedBySomeoneOtherThanParents === false,
  },
})

const estTaxDependentCount = makeDeterminerRule({
  name: 'person.estTaxDependentCount',
  necessaryIf: {
    'person.taxFilingStatus': ({
      value,
      values: {
        person: {
          claimedBySomeoneOtherThanParents,
          livingWithParentsWhoDoNotFileJointly,
        },
        person,
      },
    }: any) =>
      [TAX_FILING_STATUS_FILER, TAX_FILING_STATUS_FILING_JOINTLY].includes(
        value
      ) ||
      (value === TAX_FILING_STATUS_DEPENDENT &&
        claimedBySomeoneOtherThanParents === false &&
        (livingWithParentsWhoDoNotFileJointly === false ||
          !isMinorMedicaid(person))),
  },
})

const taxDependentInclSpouseLiveWithClient = makeDeterminerRule({
  name: 'person.taxDependentInclSpouseLiveWithClient',
  necessaryIf: {
    'person.taxFilingStatus': ({
      value,
      values: {
        person: {
          maritalStatus,
          claimedBySomeoneOtherThanParents,
          livingWithParentsWhoDoNotFileJointly,
        },
        person,
      },
    }: any) =>
      [MARITAL_STATUS_MARRIED, MARITAL_STATUS_SEPARATED].includes(
        maritalStatus
      ) &&
      (value === TAX_FILING_STATUS_FILER ||
        (value === TAX_FILING_STATUS_DEPENDENT &&
          claimedBySomeoneOtherThanParents === false &&
          (!isMinorMedicaid(person) ||
            livingWithParentsWhoDoNotFileJointly === false))),
  },
})

const estParentLiveWithClient = makeDeterminerRule({
  name: 'person.estParentLiveWithClient',
  necessaryIf: {
    'person.taxFilingStatus': ({
      value,
      values: {
        person: {
          claimedBySomeoneOtherThanParents,
          livingWithParentsWhoDoNotFileJointly,
        },
        person,
      },
    }: any) =>
      isMinorMedicaid(person) &&
      (value === TAX_FILING_STATUS_NOT_FILING ||
        (value === TAX_FILING_STATUS_DEPENDENT &&
          (claimedBySomeoneOtherThanParents === true ||
            livingWithParentsWhoDoNotFileJointly === true))),
  },
})

const estChildren19LiveWithClient = makeDeterminerRule({
  name: 'person.estChildren19LiveWithClient',
  necessaryIf: {
    'person.taxFilingStatus': ({
      value,
      values: {
        person: {
          claimedBySomeoneOtherThanParents,
          livingWithParentsWhoDoNotFileJointly,
        },
        person,
      },
    }: any) =>
      value === TAX_FILING_STATUS_NOT_FILING ||
      (value === TAX_FILING_STATUS_DEPENDENT &&
        (claimedBySomeoneOtherThanParents === true ||
          (claimedBySomeoneOtherThanParents === false &&
            isMinorMedicaid(person) &&
            livingWithParentsWhoDoNotFileJointly === true))),
  },
})

const children18LiveWithClient = makeDeterminerRule({
  name: 'person.children18LiveWithClient',
  necessaryIf: [CI_ELIGIBLE],
})

const estMinor19SiblingLiveWithClient = makeDeterminerRule({
  name: 'person.estMinor19SiblingLiveWithClient',
  necessaryIf: {
    'person.taxFilingStatus': ({
      value,
      values: {
        person: {
          claimedBySomeoneOtherThanParents,
          livingWithParentsWhoDoNotFileJointly,
        },
        person,
      },
    }: any) =>
      isMinorMedicaid(person) &&
      (value === TAX_FILING_STATUS_NOT_FILING ||
        (value === TAX_FILING_STATUS_DEPENDENT &&
          (claimedBySomeoneOtherThanParents === true ||
            livingWithParentsWhoDoNotFileJointly === true))),
  },
})

const minor18SiblingLiveWithClient = makeDeterminerRule({
  name: 'person.minor18SiblingLiveWithClient',
  necessaryIf: {
    [CI_ELIGIBLE]: ({
      values: {
        person: {
          maritalStatus,
          spouseLiveWithMe,
          dob,
          isStudent,
          estParentLiveWithClient,
        },
      },
    }: any) =>
      !(maritalStatus === MARITAL_STATUS_MARRIED && spouseLiveWithMe) &&
      !(dob && !isUnder18(dob) && !(isStudent && estParentLiveWithClient >= 1)),
  },
})

const isOver65Relationship = makeDeterminerRule({
  name: 'person.relationships[].otherPerson.dob',
  necessaryIf: [START_TOKEN],
})

const hasInsurance = makeDeterminerRule({
  name: 'person.hasInsurance',
  necessaryIf: [START_TOKEN],
})

const hasInsuranceHouseholdMembers = makeDeterminerRule({
  name: 'person.relationships[].otherPerson.hasInsurance',
  necessaryIf: [START_TOKEN],
  necessaryIfResolved: ({
    values,
    values: {
      person: {relationships},
    },
    resolvedIndexes,
  }: any) => {
    const relationshipValues = relationships[resolvedIndexes[0]]
    const personValues = relationshipValues.otherPerson
    return isPersonInHousehold(BenefitType.charityCare)(values, personValues)
  },
})

const alreadyHasInsuranceRelationship = makeDeterminerRule({
  name: 'person.relationships[].otherPerson.hasInsurance',
  necessaryIf: [START_TOKEN],
})

const insuranceName = makeDeterminerRule({
  name: 'person.relationships[].otherPerson.currentInsuranceName',
  necessaryIf: {'person.relationships[].otherPerson.hasInsurance': true},
})

const insurancePolicyNumber = makeDeterminerRule({
  name: 'person.relationships[].otherPerson.currentInsurancePolicyNo',
  necessaryIf: {'person.relationships[].otherPerson.hasInsurance': true},
})

const doesntLiveInNjRelationship = makeDeterminerRule({
  name: 'person.relationships[].otherPerson.livesInNj',
  necessaryIf: {'person.relationships[].otherPerson.wantsCoverage': true},
})

const livesInNjRelationship = makeDeterminerRule({
  name: 'person.relationships[].otherPerson.livesInNj',
  necessaryIf: [START_TOKEN],
  necessaryIfResolved: ({
    values,
    values: {
      person: {relationships},
    },
    resolvedIndexes,
  }: any) => {
    const relationshipValues = relationships[resolvedIndexes[0]]
    const personValues = relationshipValues.otherPerson
    return isPersonInHousehold(BenefitType.charityCare)(values, personValues)
  },
})

const njResidencyDoc = makeDeterminerRule({
  name: 'person.njResidencyDoc',
  necessaryIf: {'person.livesInNj': true},
})

const njResidencyDocMedicaid = (
  application: Person_person_openApplications_MedicaidApplication
) =>
  makeDeterminerRule({
    name: 'person.njResidencyDoc',
    necessaryIf: {'person.livesInNj': true},
    necessaryIfResolved: necessaryUnlessNewbornMedicaid(application),
  })

const njResidencyDocRelationshipMedicaid = (
  application: Person_person_openApplications_MedicaidApplication
) =>
  makeDeterminerRule({
    name: 'person.relationships[].otherPerson.njResidencyDoc',
    necessaryIf: {'person.relationships[].otherPerson.livesInNj': true},
    necessaryIfResolved: necessaryUnlessNewbornMedicaid(application),
  })

const livedInNjSinceMonth = makeDeterminerRule({
  name: 'person.livedInNjSinceMonth',
  necessaryIf: {'person.livesInNj': true},
})

const livedInNjSinceMonthRelationship = makeDeterminerRule({
  name: 'person.relationships[].otherPerson.livedInNjSinceMonth',
  necessaryIf: [START_TOKEN],
  necessaryIfResolved: ({
    values,
    values: {
      person: {relationships},
    },
    resolvedIndexes,
  }: any) => {
    const relationshipValues = relationships[resolvedIndexes[0]]
    const personValues = relationshipValues.otherPerson
    return (
      personValues.livesInNj &&
      isPersonInHousehold(BenefitType.charityCare)(values, personValues)
    )
  },
})

const livedInNjSinceYear = makeDeterminerRule({
  name: 'person.livedInNjSinceYear',
  necessaryIf: {'person.livesInNj': true},
})

const livedInNjSinceYearRelationship = makeDeterminerRule({
  name: 'person.relationships[].otherPerson.livedInNjSinceYear',
  necessaryIf: [START_TOKEN],
  necessaryIfResolved: ({
    values,
    values: {
      person: {relationships},
    },
    resolvedIndexes,
  }: any) => {
    const relationshipValues = relationships[resolvedIndexes[0]]
    const personValues = relationshipValues.otherPerson
    return (
      personValues.livesInNj &&
      isPersonInHousehold(BenefitType.charityCare)(values, personValues)
    )
  },
})

const ciStatusRelationship = makeDeterminerRule({
  name: 'person.relationships[].otherPerson.ciStatus',
  necessaryIf: {'person.relationships[].otherPerson.wantsCoverage': true},
})

const notFiveYearsInUsRelationship = makeDeterminerRule({
  name: 'person.relationships[].otherPerson.fiveYearsInUs',
  necessaryIf: {
    'person.relationships[].otherPerson.ciStatus': CI_STATUS_LEGAL_RESIDENT,
  },
})

const specialCategoryRelationship = makeDeterminerRule({
  name: 'person.relationships[].otherPerson.specialCategory',
  necessaryIf: {
    'person.relationships[].otherPerson.ciStatus': ({value}: any) =>
      [CI_STATUS_LEGAL_RESIDENT, CI_STATUS_OTHER].includes(value),
  },
})

const ciDocumentRelationshipMedicaid = (
  application: Person_person_openApplications_MedicaidApplication
) =>
  makeDeterminerRule({
    name: 'person.relationships[].otherPerson.ciDocumentType',
    necessaryIf: {'person.relationships[].otherPerson.wantsCoverage': true},
    necessaryIfResolved: necessaryUnlessNewbornMedicaid(application),
  })

const wantsCoverageRelationship = makeDeterminerRule({
  name: 'person.relationships[].otherPerson.wantsCoverage',
  necessaryIf: [START_TOKEN],
})

const ciDocumentNameMedicaid = (
  application: Person_person_openApplications_MedicaidApplication
) =>
  makeDeterminerRule({
    name: 'person.ciDocumentName',
    necessaryIf: {
      'person.ciDocumentType': ({value}: any) =>
        value !== CI_DOCUMENT_CANT_PROVIDE,
    },
    necessaryIfResolved: necessaryUnlessNewbornMedicaid(application),
  })

const ciDocumentNameRelationshipMedicaid = (
  application: Person_person_openApplications_MedicaidApplication
) =>
  makeDeterminerRule({
    name: 'person.relationships[].otherPerson.ciDocumentName',
    necessaryIf: {
      'person.relationships[].otherPerson.ciDocumentType': ({value}: any) =>
        value !== CI_DOCUMENT_CANT_PROVIDE,
    },
    necessaryIfResolved: necessaryUnlessNewbornMedicaid(application),
  })

const dateOfEntry = makeDeterminerRule({
  name: 'person.dateOfEntry',
  necessaryIf: {
    'person.ciStatus': ({value}: any) => value !== CI_STATUS_US_CITIZEN,
  },
})

const dateOfEntryRelationship = makeDeterminerRule({
  name: 'person.relationships[].otherPerson.dateOfEntry',
  necessaryIf: {
    'person.relationships[].otherPerson.ciStatus': ({value}: any) =>
      value !== CI_STATUS_US_CITIZEN,
  },
})

const uscisNo = makeDeterminerRule({
  name: 'person.uscisNo',
  necessaryIf: {
    'person.ciStatus': ({value}: any) => value !== CI_STATUS_US_CITIZEN,
  },
})

const uscisNoRelationship = makeDeterminerRule({
  name: 'person.relationships[].otherPerson.uscisNo',
  necessaryIf: {
    'person.relationships[].otherPerson.ciStatus': ({value}: any) =>
      value !== CI_STATUS_US_CITIZEN,
  },
})

const prCardNo = makeDeterminerRule({
  name: 'person.prCardNo',
  necessaryIf: {
    'person.ciStatus': ({value}: any) => value !== CI_STATUS_US_CITIZEN,
  },
})

const prCardNoRelationship = makeDeterminerRule({
  name: 'person.relationships[].otherPerson.prCardNo',
  necessaryIf: {
    'person.relationships[].otherPerson.ciStatus': ({value}: any) =>
      value !== CI_STATUS_US_CITIZEN,
  },
})

const maritalStatusRelationship = makeDeterminerRule({
  name: 'person.relationships[].otherPerson.maritalStatus',
  necessaryIf: [START_TOKEN],
})

const pregnantRelationship = makeDeterminerRule({
  name: 'person.relationships[].otherPerson.pregnant',
  necessaryIf: [START_TOKEN],
  necessaryIfResolved: ({
    values: {
      person: {relationships},
    },
    resolvedIndexes,
  }: any) => {
    const personValues = relationships[resolvedIndexes[0]].otherPerson
    return personValues.gender !== 'Male'
  },
})

const dueDate = makeDeterminerRule({
  name: 'person.dueDate',
  necessaryIf: {'person.pregnant': true},
})

const dueDateRelationship = makeDeterminerRule({
  name: 'person.relationships[].otherPerson.dueDate',
  necessaryIf: {'person.relationships[].otherPerson.pregnant': true},
})

const expectedChildrenRelationship = makeDeterminerRule({
  name: 'person.relationships[].otherPerson.expectedChild',
  necessaryIf: {'person.relationships[].otherPerson.pregnant': true},
})

const livesWithSpouseRelationship = makeDeterminerRule({
  name: 'person.relationships[].otherPerson.spouseLiveWithMe',
  necessaryIf: {
    'person.relationships[].otherPerson.maritalStatus': [
      MARITAL_STATUS_MARRIED,
      MARITAL_STATUS_SEPARATED,
    ],
  },
})

const fullTimeStudent = makeDeterminerRule({
  name: 'person.isStudent',
  necessaryIf: {
    'person.dob': ({
      value,
      values: {
        person: {taxFilingStatus},
      },
    }: any) =>
      value &&
      isUnder22(value) &&
      !isUnder19(value) &&
      ![TAX_FILING_STATUS_FILER, TAX_FILING_STATUS_FILING_JOINTLY].includes(
        taxFilingStatus
      ),
  },
})

const fullTimeStudentRelationship = makeDeterminerRule({
  name: 'person.relationships[].otherPerson.isStudent',
  necessaryIf: {
    'person.relationships[].otherPerson.wantsCoverage': true,
    'person.relationships[].otherPerson.dob': ({value}: any) =>
      value && isUnder22(value) && !isUnder19(value),
  },
})

const homeAddressStreet = makeDeterminerRule({
  name: 'person.homeAddressStreet',
  necessaryIf: [START_TOKEN],
})

const homeAddressCity = makeDeterminerRule({
  name: 'person.homeAddressCity',
  necessaryIf: [START_TOKEN],
})

const homeAddressState = makeDeterminerRule({
  name: 'person.homeAddressState',
  necessaryIf: [START_TOKEN],
})

const homeAddressZip = makeDeterminerRule({
  name: 'person.homeAddressZip',
  necessaryIf: [START_TOKEN],
})

const ssn = makeDeterminerRule({
  name: 'person.ssn',
  necessaryIf: [START_TOKEN],
})

const ssnRelationshipMedicaid = makeDeterminerRule({
  name: 'person.relationships[].otherPerson.ssn',
  necessaryIf: [START_TOKEN],
  necessaryIfResolved: ({
    values,
    values: {
      person: {relationships},
    },
    resolvedIndexes,
  }: any) => {
    const personValues = relationships[resolvedIndexes[0]].otherPerson
    return (
      personValues.wantsCoverage &&
      isPersonInHousehold(BenefitType.medicaid)(values, personValues)
    )
  },
})

const ssnRelationshipMonitor = ({
  application,
}: {
  application: Person_person_openApplications_MonitorApplication
}) =>
  makeDeterminerRule({
    name: 'person.relationships[].otherPerson.ssn',
    necessaryIf: [START_TOKEN],
    necessaryIfResolved: ({
      values: {
        person: {relationships},
      },
      resolvedIndexes,
    }: any) => {
      const personValues = relationships[resolvedIndexes[0]].otherPerson
      return (
        personValues.id &&
        personValues.id === application.primaryPointOfContact?.id
      )
    },
  })

const phoneNumber = makeDeterminerRule({
  name: 'person.phoneNumbers',
  necessaryIf: [START_TOKEN],
  getIsBlank: (value: any[]) => !value.some(({usedInApp}) => usedInApp),
})

const phoneNumberNumber = makeDeterminerRule({
  name: 'person.phoneNumbers[].number',
  necessaryIf: [START_TOKEN],
})

const phoneNumberNumberRelationship = makeDeterminerRule({
  name: 'person.relationships[].otherPerson.phoneNumbers[].number',
  necessaryIf: [START_TOKEN],
})

const phoneNumberLanguage = makeDeterminerRule({
  name: 'person.phoneNumbers[].language',
  necessaryIf: [START_TOKEN],
})

const phoneNumberLanguageRelationship = makeDeterminerRule({
  name: 'person.relationships[].otherPerson.phoneNumbers[].language',
  necessaryIf: [START_TOKEN],
})

const phoneNumberUsedInApp = makeDeterminerRule({
  name: 'person.phoneNumbers[].usedInApp',
  necessaryIf: [START_TOKEN],
})

const phoneNumberUsedInAppRelationship = makeDeterminerRule({
  name: 'person.relationships[].otherPerson.phoneNumbers[].usedInApp',
  necessaryIf: [START_TOKEN],
})

const phoneNumberUsedForEsign = makeDeterminerRule({
  name: 'person.phoneNumbers[].usedForEsign',
  necessaryIf: [START_TOKEN],
})

const phoneNumberUsedForEsignRelationship = makeDeterminerRule({
  name: 'person.relationships[].otherPerson.phoneNumbers[].usedForEsign',
  necessaryIf: [START_TOKEN],
})

const taxFilingStatusRelationship = makeDeterminerRule({
  name: 'person.relationships[].otherPerson.taxFilingStatus',
  necessaryIf: [START_TOKEN],
})

const dependentOfTaxFiler = makeDeterminerRule({
  name: 'person.dependentOfTaxFilerId',
  necessaryIf: {'person.taxFilingStatus': TAX_FILING_STATUS_DEPENDENT},
})

const dependentOfTaxFilerRelationship = makeDeterminerRule({
  name: 'person.relationships[].otherPerson.dependentOfTaxFilerId',
  necessaryIf: {
    'person.relationships[].otherPerson.taxFilingStatus': TAX_FILING_STATUS_DEPENDENT,
  },
})

const incomeSourceType = makeDeterminerRule({
  name: 'person.incomeSources[].incomeType',
  necessaryIf: [START_TOKEN],
})

const incomeSourceTypeRelationship = makeDeterminerRule({
  name: 'person.relationships[].otherPerson.incomeSources[].incomeType',
  necessaryIf: [START_TOKEN],
})

const incomeSourceEmployerName = makeDeterminerRule({
  name: 'person.incomeSources[].employerName',
  necessaryIf: [START_TOKEN],
})

const incomeSourceEmployerNameRelationship = makeDeterminerRule({
  name: 'person.relationships[].otherPerson.incomeSources[].employerName',
  necessaryIf: [START_TOKEN],
})

const incomeSourceEmployerNameMedicaid = makeDeterminerRule({
  name: 'person.incomeSources[].employerName',
  necessaryIf: {
    'person.incomeSources[].incomeType': [
      INCOME_SOURCE_TYPE_SALARY_WAGES,
      INCOME_SOURCE_TYPE_FREELANCE,
      INCOME_SOURCE_TYPE_SELF_EMPLOYED,
    ],
  },
})

const incomeSourceEmployerNameRelationshipMedicaid = makeDeterminerRule({
  name: 'person.relationships[].otherPerson.incomeSources[].employerName',
  necessaryIf: {
    'person.relationships[].otherPerson.incomeSources[].incomeType': [
      INCOME_SOURCE_TYPE_SALARY_WAGES,
      INCOME_SOURCE_TYPE_FREELANCE,
      INCOME_SOURCE_TYPE_SELF_EMPLOYED,
    ],
  },
})

const incomeSourceEmployerNameWhenUsedForEsign = makeDeterminerRule({
  name: 'person.incomeSources[].employerName',
  necessaryIf: {
    'person.incomeSources[].usedForEsign': true,
  },
})

const incomeSourceEmployerNameWhenUsedForEsignRelationship = makeDeterminerRule(
  {
    name: 'person.relationships[].otherPerson.incomeSources[].employerName',
    necessaryIf: {
      'person.relationships[].otherPerson.incomeSources[].usedForEsign': true,
    },
  }
)

const incomeSourceEmployerAddressStreet = makeDeterminerRule({
  name: 'person.incomeSources[].employerAddressStreet',
  necessaryIf: {
    'person.incomeSources[].employerInsurance': true,
  },
})

const incomeSourceEmployerAddressStreetRelationship = makeDeterminerRule({
  name:
    'person.relationships[].otherPerson.incomeSources[].employerAddressStreet',
  necessaryIf: {
    'person.relationships[].otherPerson.incomeSources[].employerInsurance': true,
  },
})

const incomeSourceEmployerAddressStreetWhenUsedForEsign = makeDeterminerRule({
  name: 'person.incomeSources[].employerAddressStreet',
  necessaryIf: {
    'person.incomeSources[].usedForEsign': true,
  },
})

const incomeSourceEmployerAddressStreetWhenUsedForEsignRelationship = makeDeterminerRule(
  {
    name:
      'person.relationships[].otherPerson.incomeSources[].employerAddressStreet',
    necessaryIf: {
      'person.relationships[].otherPerson.incomeSources[].usedForEsign': true,
    },
  }
)

const incomeSourceEmployerAddressCity = makeDeterminerRule({
  name: 'person.incomeSources[].employerAddressCity',
  necessaryIf: {
    'person.incomeSources[].employerInsurance': true,
  },
})

const incomeSourceEmployerAddressCityRelationship = makeDeterminerRule({
  name:
    'person.relationships[].otherPerson.incomeSources[].employerAddressCity',
  necessaryIf: {
    'person.relationships[].otherPerson.incomeSources[].employerInsurance': true,
  },
})

const incomeSourceEmployerAddressCityWhenUsedForEsign = makeDeterminerRule({
  name: 'person.incomeSources[].employerAddressCity',
  necessaryIf: {
    'person.incomeSources[].usedForEsign': true,
  },
})

const incomeSourceEmployerAddressCityWhenUsedForEsignRelationship = makeDeterminerRule(
  {
    name:
      'person.relationships[].otherPerson.incomeSources[].employerAddressCity',
    necessaryIf: {
      'person.relationships[].otherPerson.incomeSources[].usedForEsign': true,
    },
  }
)

const incomeSourceEmployerAddressState = makeDeterminerRule({
  name: 'person.incomeSources[].employerAddressState',
  necessaryIf: {
    'person.incomeSources[].employerInsurance': true,
  },
})

const incomeSourceEmployerAddressStateRelationship = makeDeterminerRule({
  name:
    'person.relationships[].otherPerson.incomeSources[].employerAddressState',
  necessaryIf: {
    'person.relationships[].otherPerson.incomeSources[].employerInsurance': true,
  },
})

const incomeSourceEmployerAddressStateWhenUsedForEsign = makeDeterminerRule({
  name: 'person.incomeSources[].employerAddressState',
  necessaryIf: {
    'person.incomeSources[].usedForEsign': true,
  },
})

const incomeSourceEmployerAddressStateWhenUsedForEsignRelationship = makeDeterminerRule(
  {
    name:
      'person.relationships[].otherPerson.incomeSources[].employerAddressState',
    necessaryIf: {
      'person.relationships[].otherPerson.incomeSources[].usedForEsign': true,
    },
  }
)

const incomeSourceEmployerAddressZip = makeDeterminerRule({
  name: 'person.incomeSources[].employerAddressZip',
  necessaryIf: {
    'person.incomeSources[].employerInsurance': true,
  },
})

const incomeSourceEmployerAddressZipRelationship = makeDeterminerRule({
  name: 'person.relationships[].otherPerson.incomeSources[].employerAddressZip',
  necessaryIf: {
    'person.relationships[].otherPerson.incomeSources[].employerInsurance': true,
  },
})

const incomeSourceEmployerAddressZipWhenUsedForEsign = makeDeterminerRule({
  name: 'person.incomeSources[].employerAddressZip',
  necessaryIf: {
    'person.incomeSources[].usedForEsign': true,
  },
})

const incomeSourceEmployerAddressZipWhenUsedForEsignRelationship = makeDeterminerRule(
  {
    name:
      'person.relationships[].otherPerson.incomeSources[].employerAddressZip',
    necessaryIf: {
      'person.relationships[].otherPerson.incomeSources[].usedForEsign': true,
    },
  }
)

const incomeSourceEmployerInsurance = makeDeterminerRule({
  name: 'person.incomeSources[].employerInsurance',
  necessaryIf: {
    'person.incomeSources[].incomeType': [
      INCOME_SOURCE_TYPE_SALARY_WAGES,
      INCOME_SOURCE_TYPE_FREELANCE,
      INCOME_SOURCE_TYPE_SELF_EMPLOYED,
    ],
  },
})

const incomeSourceEmployerInsuranceRelationship = makeDeterminerRule({
  name: 'person.relationships[].otherPerson.incomeSources[].employerInsurance',
  necessaryIf: {
    'person.relationships[].otherPerson.incomeSources[].incomeType': [
      INCOME_SOURCE_TYPE_SALARY_WAGES,
      INCOME_SOURCE_TYPE_FREELANCE,
      INCOME_SOURCE_TYPE_SELF_EMPLOYED,
    ],
  },
})

const incomeSourceFullPartTime = makeDeterminerRule({
  name: 'person.incomeSources[].fullPartTime',
  necessaryIf: {
    'person.incomeSources[].incomeType': [
      INCOME_SOURCE_TYPE_SALARY_WAGES,
      INCOME_SOURCE_TYPE_FREELANCE,
      INCOME_SOURCE_TYPE_SELF_EMPLOYED,
    ],
  },
})

const incomeSourceFullPartTimeRelationship = makeDeterminerRule({
  name: 'person.relationships[].otherPerson.incomeSources[].fullPartTime',
  necessaryIf: {
    'person.relationships[].otherPerson.incomeSources[].incomeType': [
      INCOME_SOURCE_TYPE_SALARY_WAGES,
      INCOME_SOURCE_TYPE_FREELANCE,
      INCOME_SOURCE_TYPE_SELF_EMPLOYED,
    ],
  },
})

const incomeSourceStartMonth = makeDeterminerRule({
  name: 'person.incomeSources[].startMonth',
  necessaryIf: [START_TOKEN],
})

const incomeSourceStartMonthRelationship = makeDeterminerRule({
  name: 'person.relationships[].otherPerson.incomeSources[].startMonth',
  necessaryIf: [START_TOKEN],
})

const incomeSourceStartYear = makeDeterminerRule({
  name: 'person.incomeSources[].startYear',
  necessaryIf: [START_TOKEN],
})

const incomeSourceStartYearRelationship = makeDeterminerRule({
  name: 'person.relationships[].otherPerson.incomeSources[].startYear',
  necessaryIf: [START_TOKEN],
})

const incomeSourceEndMonth = makeDeterminerRule({
  name: 'person.incomeSources[].endMonth',
  necessaryIf: [START_TOKEN],
})

const incomeSourceEndMonthRelationship = makeDeterminerRule({
  name: 'person.relationships[].otherPerson.incomeSources[].endMonth',
  necessaryIf: [START_TOKEN],
})

const incomeSourceEndYear = makeDeterminerRule({
  name: 'person.incomeSources[].endYear',
  necessaryIf: [START_TOKEN],
})

const incomeSourceEndYearRelationship = makeDeterminerRule({
  name: 'person.relationships[].otherPerson.incomeSources[].endYear',
  necessaryIf: [START_TOKEN],
})

const incomeSourceAmount = makeDeterminerRule({
  name: 'person.incomeSources[].amount',
  necessaryIf: [START_TOKEN],
})

const incomeSourceAmountRelationship = makeDeterminerRule({
  name: 'person.relationships[].otherPerson.incomeSources[].amount',
  necessaryIf: [START_TOKEN],
})

const incomeSourcePayFrequency = makeDeterminerRule({
  name: 'person.incomeSources[].payFrequency',
  necessaryIf: [START_TOKEN],
})

const incomeSourcePayFrequencyRelationship = makeDeterminerRule({
  name: 'person.relationships[].otherPerson.incomeSources[].payFrequency',
  necessaryIf: [START_TOKEN],
})

const incomeSourceUsedForEsign = makeDeterminerRule({
  name: 'person.incomeSources[].usedForEsign',
  necessaryIf: [START_TOKEN],
})

const incomeSourceUsedForEsignRelationship = makeDeterminerRule({
  name: 'person.relationships[].otherPerson.incomeSources[].usedForEsign',
  necessaryIf: [START_TOKEN],
})

const changeJobInLast6Mon = makeDeterminerRule({
  name: 'person.changeJobInLast6Mon',
  necessaryIf: [START_TOKEN],
})

const changeJobInLast6MonRelationship = makeDeterminerRule({
  name: 'person.relationships[].otherPerson.changeJobInLast6Mon',
  necessaryIf: [START_TOKEN],
})

const hasDeduction = makeDeterminerRule({
  name: 'person.hasDeduction',
  necessaryIf: [START_TOKEN],
})

const hasDeductionRelationship = makeDeterminerRule({
  name: 'person.relationships[].otherPerson.hasDeduction',
  necessaryIf: [START_TOKEN],
})

const deductionType = makeDeterminerRule({
  name: 'person.deductions[].deductionType',
  necessaryIf: [START_TOKEN],
})

const deductionTypeRelationship = makeDeterminerRule({
  name: 'person.relationships[].otherPerson.deductions[].deductionType',
  necessaryIf: [START_TOKEN],
})

const deductionStartMonth = makeDeterminerRule({
  name: 'person.deductions[].startMonth',
  necessaryIf: [START_TOKEN],
})

const deductionStartMonthRelationship = makeDeterminerRule({
  name: 'person.relationships[].otherPerson.deductions[].startMonth',
  necessaryIf: [START_TOKEN],
})

const deductionStartYear = makeDeterminerRule({
  name: 'person.deductions[].startYear',
  necessaryIf: [START_TOKEN],
})

const deductionStartYearRelationship = makeDeterminerRule({
  name: 'person.relationships[].otherPerson.deductions[].startYear',
  necessaryIf: [START_TOKEN],
})

const deductionEndMonth = makeDeterminerRule({
  name: 'person.deductions[].endMonth',
  necessaryIf: [START_TOKEN],
})

const deductionEndMonthRelationship = makeDeterminerRule({
  name: 'person.relationships[].otherPerson.deductions[].endMonth',
  necessaryIf: [START_TOKEN],
})

const deductionEndYear = makeDeterminerRule({
  name: 'person.deductions[].endYear',
  necessaryIf: [START_TOKEN],
})

const deductionEndYearRelationship = makeDeterminerRule({
  name: 'person.relationships[].otherPerson.deductions[].endYear',
  necessaryIf: [START_TOKEN],
})

const deductionAmount = makeDeterminerRule({
  name: 'person.deductions[].amount',
  necessaryIf: [START_TOKEN],
})

const deductionAmountRelationship = makeDeterminerRule({
  name: 'person.relationships[].otherPerson.deductions[].amount',
  necessaryIf: [START_TOKEN],
})

const deductionFrequency = makeDeterminerRule({
  name: 'person.deductions[].deductionFrequency',
  necessaryIf: [START_TOKEN],
})

const deductionFrequencyRelationship = makeDeterminerRule({
  name: 'person.relationships[].otherPerson.deductions[].deductionFrequency',
  necessaryIf: [START_TOKEN],
})

const incomeInLast30Days = makeDeterminerRule({
  name: 'person.income',
  necessaryIf: [START_TOKEN],
})

const incomeInLast30DaysRelationship = makeDeterminerRule({
  name: 'person.relationships[].otherPerson.income',
  necessaryIf: [START_TOKEN],
})

const whoSupportWithoutIncome = makeDeterminerRule({
  name: 'person.whoSupportWithoutIncome',
  necessaryIf: {
    'person.income': false,
  },
})

const whoSupportWithoutIncomeRelationship = makeDeterminerRule({
  name: 'person.relationships[].otherPerson.whoSupportWithoutIncome',
  necessaryIf: {
    'person.relationships[].otherPerson.income': false,
  },
})

const othersSupportWithoutIncome = makeDeterminerRule({
  name: 'person.othersSupportWithoutIncome',
  necessaryIf: {
    'person.whoSupportWithoutIncome': WHO_SUPPORT_WITHOUT_INCOME_OTHER,
  },
})

const othersSupportWithoutIncomeRelationship = makeDeterminerRule({
  name: 'person.relationships[].otherPerson.othersSupportWithoutIncome',
  necessaryIf: {
    'person.relationships[].otherPerson.whoSupportWithoutIncome': WHO_SUPPORT_WITHOUT_INCOME_OTHER,
  },
})

const selfSupportWithoutIncome = makeDeterminerRule({
  name: 'person.selfSupportWithoutIncome',
  necessaryIf: {
    'person.whoSupportWithoutIncome': WHO_SUPPORT_WITHOUT_INCOME_SELF,
  },
})

const selfSupportWithoutIncomeRelationship = makeDeterminerRule({
  name: 'person.relationships[].otherPerson.selfSupportWithoutIncome',
  necessaryIf: {
    'person.relationships[].otherPerson.whoSupportWithoutIncome': WHO_SUPPORT_WITHOUT_INCOME_SELF,
  },
})

const insuranceLast3Months = makeDeterminerRule({
  name: 'person.insuranceLast3Months',
  necessaryIf: [START_TOKEN],
})

const insuranceLast3MonthsRelationship = makeDeterminerRule({
  name: 'person.relationships[].otherPerson.insuranceLast3Months',
  necessaryIf: {'person.relationships[].otherPerson.wantsCoverage': true},
})

const alaskanNativeamerican = makeDeterminerRule({
  name: 'person.alaskanNativeamerican',
  necessaryIf: [START_TOKEN],
})

const alaskanNativeamericanRelationship = makeDeterminerRule({
  name: 'person.relationships[].otherPerson.alaskanNativeamerican',
  necessaryIf: {'person.relationships[].otherPerson.wantsCoverage': true},
})

const fosterCareAfter18 = makeDeterminerRule({
  name: 'person.fosterCareAfter18',
  necessaryIf: [START_TOKEN],
})

const fosterCareAfter18Relationship = makeDeterminerRule({
  name: 'person.relationships[].otherPerson.fosterCareAfter18',
  necessaryIf: {'person.relationships[].otherPerson.wantsCoverage': true},
})

const firstName = makeDeterminerRule({
  name: 'person.firstName',
  necessaryIf: [START_TOKEN],
})

const firstNameRelationship = makeDeterminerRule({
  name: 'person.relationships[].otherPerson.firstName',
  necessaryIf: [START_TOKEN],
})

const lastName = makeDeterminerRule({
  name: 'person.lastName',
  necessaryIf: [START_TOKEN],
})

const lastNameRelationship = makeDeterminerRule({
  name: 'person.relationships[].otherPerson.lastName',
  necessaryIf: [START_TOKEN],
})

const relationshipType = makeDeterminerRule({
  name: 'person.relationships[].relationshipType',
  necessaryIf: [START_TOKEN],
})

const liveTogether = makeDeterminerRule({
  name: 'person.relationships[].liveTogether',
  necessaryIf: [START_TOKEN],
})

const identityDocument = makeDeterminerRule({
  name: 'person.identityDocument',
  necessaryIf: [START_TOKEN],
})

const identityDocumentMedicaid = (
  application: Person_person_openApplications_MedicaidApplication
) =>
  makeDeterminerRule({
    name: 'person.identityDocument',
    necessaryIf: [START_TOKEN],
    necessaryIfResolved: necessaryUnlessNewbornMedicaid(application),
  })

const identityDocumentRelationship = makeDeterminerRule({
  name: 'person.relationships[].otherPerson.identityDocument',
  necessaryIf: {'person.relationships[].otherPerson.wantsCoverage': true},
})

const identityDocumentRelationshipMedicaid = (
  application: Person_person_openApplications_MedicaidApplication
) =>
  makeDeterminerRule({
    name: 'person.relationships[].otherPerson.identityDocument',
    necessaryIf: {'person.relationships[].otherPerson.wantsCoverage': true},
    necessaryIfResolved: necessaryUnlessNewbornMedicaid(application),
  })

const desiredMco = makeDeterminerRule({
  name: 'person.desiredMco',
  necessaryIf: [START_TOKEN],
})

const pcp = makeDeterminerRule({
  name: 'person.pcp',
  necessaryIf: [START_TOKEN],
})

const childrenPcp = makeDeterminerRule({
  name: 'person.childrenPcp',
  necessaryIf: [START_TOKEN],
})

const preferredLanguage = makeDeterminerRule({
  name: 'person.preferredLanguage',
  necessaryIf: [START_TOKEN],
})

const gender = makeDeterminerRule({
  name: 'person.gender',
  necessaryIf: [START_TOKEN],
})

const genderRelationship = makeDeterminerRule({
  name: 'person.relationships[].otherPerson.gender',
  necessaryIf: [START_TOKEN],
})

const minorNotLivingWithParent = ({t}: {t: TFunction}) =>
  makeDeterminerRule({
    name: 'person.dob',
    necessaryIf: [START_TOKEN],
    ineligibleIf: ({
      value,
      values: {
        person: {maritalStatus, spouseLiveWithMe, estParentLiveWithClient},
      },
    }) => {
      if (
        !(
          value &&
          maritalStatus &&
          (maritalStatus !== MARITAL_STATUS_MARRIED || spouseLiveWithMe != null)
        )
      )
        return false
      if (
        isUnder18(value) &&
        !(maritalStatus === MARITAL_STATUS_MARRIED && spouseLiveWithMe) &&
        estParentLiveWithClient === 0
      )
        return {
          isEligible: false,
          reasonOrInfo: t(
            'personForm.eligibilityCheck.minorNotLivingWithParent'
          ),
        }
      return false
    },
    clearNecessaryIfIneligible: true,
  })

const doesntLiveInNjFull = {
  ...doesntLiveInNj,
  necessaryIf: [START_TOKEN],
  ineligibleIf: undefined,
}

const ciStatusFull = {
  ...ciStatus,
  necessaryIf: [START_TOKEN],
}

const pendingUsCitizenship = {
  name: 'person.pendingUsCitizenship',
  necessaryIf: [START_TOKEN],
}

const maritalStatusFull = {
  ...maritalStatus,
  necessaryIf: [START_TOKEN],
}

const pregnantFull = {
  ...pregnant,
  necessaryIf: [START_TOKEN],
  necessaryIfResolved: ({values}: any) => values.person.gender !== 'Male',
}

const expectedChildrenFull = {
  ...expectedChildren,
  necessaryIf: {'person.pregnant': true},
}

const livesWithSpouseCharityCare = {
  ...livesWithSpouse,
  necessaryIf: {
    'person.maritalStatus': MARITAL_STATUS_MARRIED,
  },
}

const identityDocumentSpouseCharityCare = makeDeterminerRule({
  ...identityDocumentRelationship,
  necessaryIf: {
    'person.relationships[].relationshipType': ({
      value,
      values: {
        person: {relationships},
      },
      resolvedIndexes,
    }: any) => {
      const {liveTogether} = relationships[resolvedIndexes[0]]
      return value === RELATIONSHIP_TYPE_SPOUSE && liveTogether
    },
  },
})

const isOver65Full = {
  ...isOver65,
  ineligibleIf: undefined,
}

const hasAsset = makeDeterminerRule({
  name: 'person.hasAsset',
  necessaryIf: [START_TOKEN],
})

const hasAssetRelationship = makeDeterminerRule({
  name: 'person.relationships[].otherPerson.hasAsset',
  necessaryIf: [START_TOKEN],
})

const assetType = makeDeterminerRule({
  name: 'person.assets[].assetType',
  necessaryIf: [START_TOKEN],
})

const assetTypeRelationship = makeDeterminerRule({
  name: 'person.relationships[].otherPerson.assets[].assetType',
  necessaryIf: [START_TOKEN],
})

const assetAmount = makeDeterminerRule({
  name: 'person.assets[].amount',
  necessaryIf: [START_TOKEN],
})

const assetAmountRelationship = makeDeterminerRule({
  name: 'person.relationships[].otherPerson.assets[].amount',
  necessaryIf: [START_TOKEN],
})

const estHouseholdIncome = ({
  federalPovertyLevels,
  t,
}: {
  federalPovertyLevels: FederalPovertyLevels_federalPovertyLevels[]
  t: TFunction
}) =>
  makeDeterminerRule({
    name: 'person.estHouseholdIncome',
    necessaryIf: [CI_ELIGIBLE],
    ineligibleIf: ({value, values}) =>
      value != null &&
      isHouseholdIncomeIneligiblePreliminaryMedicaid({
        values,
        federalPovertyLevels,
        t,
      }),
    clearNecessaryIfIneligible: false,
  })

const estHouseholdIncomeCharityCare = ({
  federalPovertyLevels,
  t,
}: {
  federalPovertyLevels: FederalPovertyLevels_federalPovertyLevels[]
  t: TFunction
}) =>
  makeDeterminerRule({
    name: 'person.estHouseholdIncome',
    necessaryIf: [CI_ELIGIBLE],
    ineligibleIf: ({value, values}) =>
      value != null &&
      isHouseholdIncomeIneligiblePreliminaryCharityCare({
        values,
        federalPovertyLevels,
        t,
      }),
    clearNecessaryIfIneligible: false,
  })

const taxFilingStatusFullMedicaid = ({
  federalPovertyLevels,
  t,
}: {
  federalPovertyLevels: FederalPovertyLevels_federalPovertyLevels[]
  t: TFunction
}) =>
  makeDeterminerRule({
    name: 'person.taxFilingStatus',
    necessaryIf: [START_TOKEN],
    ineligibleIf: ({value, values}) =>
      value &&
      isHouseholdIncomeIneligibleFullMedicaid({
        values,
        federalPovertyLevels,
        t,
      }),
    clearNecessaryIfIneligible: false,
    isEligibleIfPassesIneligibilityCheck: true,
  })

const taxFilingStatusFullMedicaidLastMonth = ({
  federalPovertyLevels,
  t,
}: {
  federalPovertyLevels: FederalPovertyLevels_federalPovertyLevels[]
  t: TFunction
}) =>
  makeDeterminerRule({
    ...taxFilingStatusFullMedicaid({federalPovertyLevels, t}),
    ineligibleIf: ({value, values}) =>
      value &&
      isHouseholdIncomeIneligibleFullMedicaid({
        values,
        federalPovertyLevels,
        lastMonth: true,
        t,
      }),
  })

const ageFullCharityCare = ({
  federalPovertyLevels,
  application,
  t,
}: {
  federalPovertyLevels: FederalPovertyLevels_federalPovertyLevels[]
  application: Person_person_openApplications_CharityCareApplication
  t: TFunction
}) =>
  makeDeterminerRule({
    name: 'person.dob',
    necessaryIf: [START_TOKEN],
    ineligibleIf: ({value, values}) =>
      isCharityCareApplicationWithInitialDateOfService(application)
        ? value &&
          isHouseholdIncomeIneligibleFullCharityCare({
            values,
            federalPovertyLevels,
            application,
            t,
          })
        : {
            reasonOrInfo: t(
              'personForm.eligibilityCheck.missingInitialDateOfService'
            ),
            isEligible: null,
            shouldPreventEligibleDetermination: true,
            numMonths: CHARITY_CARE_INCOME_ANNUAL_NUM_MONTHS,
          },
    clearNecessaryIfIneligible: false,
    isEligibleIfPassesIneligibilityCheck: !!application.initialDateOfService,
  })

const incomeFullSlide = ({
  federalPovertyLevels,
  application,
  t,
}: {
  federalPovertyLevels: FederalPovertyLevels_federalPovertyLevels[]
  application: Person_person_openApplications_SlideApplication
  t: TFunction
}) =>
  makeDeterminerRule({
    name: 'person.income',
    necessaryIf: [START_TOKEN],
    ineligibleIf: ({value, values}) =>
      isSlideApplicationWithInitialDateOfService(application)
        ? value &&
          isHouseholdIncomeIneligibleFullSlide({
            values,
            federalPovertyLevels,
            application,
            t,
          })
        : {
            reasonOrInfo: t(
              'personForm.eligibilityCheck.missingInitialDateOfService'
            ),
            isEligible: null,
            shouldPreventEligibleDetermination: true,
            numMonths: CHARITY_CARE_INCOME_ANNUAL_NUM_MONTHS,
          },
    // ineligibleIf: ({values}) =>
    //   isHouseholdIncomeIneligibleFullSlide({
    //     values,
    //     federalPovertyLevels,
    //     application,
    //     t,
    //   }),
    clearNecessaryIfIneligible: false,
    // isEligibleIfPassesIneligibilityCheck: true,
    isEligibleIfPassesIneligibilityCheck: !!application.initialDateOfService,
  })

const incomeFullRyanWhite = ({
  federalPovertyLevels,
  application,
  t,
}: {
  federalPovertyLevels: FederalPovertyLevels_federalPovertyLevels[]
  application: Person_person_openApplications_RyanWhiteApplication
  t: TFunction
}) =>
  makeDeterminerRule({
    name: 'person.income',
    necessaryIf: [START_TOKEN],
    ineligibleIf: ({value, values}) =>
      isRyanWhiteApplicationWithInitialDateOfService(application)
        ? value &&
          isHouseholdIncomeIneligibleFullRyanWhite({
            values,
            federalPovertyLevels,
            application,
            t,
          })
        : {
            reasonOrInfo: t(
              'personForm.eligibilityCheck.missingInitialDateOfService'
            ),
            isEligible: null,
            shouldPreventEligibleDetermination: true,
            numMonths: CHARITY_CARE_INCOME_ANNUAL_NUM_MONTHS,
          },
    // ineligibleIf: ({values}) =>
    //   isHouseholdIncomeIneligibleFullRyanWhite({
    //     values,
    //     federalPovertyLevels,
    //     application,
    //     t,
    //   }),
    clearNecessaryIfIneligible: false,
    // isEligibleIfPassesIneligibilityCheck: true,
    isEligibleIfPassesIneligibilityCheck: !!application.initialDateOfService,
  })

const noIncomeSinceMonth = makeDeterminerRule({
  name: 'person.noIncomeSinceMonth',
  necessaryIf: {'person.income': false},
})

const noIncomeSinceMonthRelationship = makeDeterminerRule({
  name: 'person.relationships[].otherPerson.noIncomeSinceMonth',
  necessaryIf: [START_TOKEN],
  necessaryIfResolved: ({
    values: {
      person: {relationships},
    },
    resolvedIndexes,
  }: any) => {
    const relationshipValues = relationships[resolvedIndexes[0]]
    const personValues = relationshipValues.otherPerson
    return (
      relationshipValues.liveTogether &&
      personValues.income === false &&
      !isUnder18(personValues.dob)
    )
  },
})

const noIncomeSinceYear = makeDeterminerRule({
  name: 'person.noIncomeSinceYear',
  necessaryIf: {'person.income': false},
})

const noIncomeSinceYearRelationship = makeDeterminerRule({
  name: 'person.relationships[].otherPerson.noIncomeSinceYear',
  necessaryIf: [START_TOKEN],
  necessaryIfResolved: ({
    values: {
      person: {relationships},
    },
    resolvedIndexes,
  }: any) => {
    const relationshipValues = relationships[resolvedIndexes[0]]
    const personValues = relationshipValues.otherPerson
    return (
      relationshipValues.liveTogether &&
      personValues.income === false &&
      !isUnder18(personValues.dob)
    )
  },
})

const noAssetSinceMonth = makeDeterminerRule({
  name: 'person.noAssetSinceMonth',
  necessaryIf: {'person.hasAsset': false},
})

const noAssetSinceMonthRelationship = makeDeterminerRule({
  name: 'person.relationships[].otherPerson.noAssetSinceMonth',
  necessaryIf: [START_TOKEN],
  necessaryIfResolved: ({
    values: {
      person: {relationships},
    },
    resolvedIndexes,
  }: any) => {
    const relationshipValues = relationships[resolvedIndexes[0]]
    const personValues = relationshipValues.otherPerson
    return (
      relationshipValues.liveTogether &&
      personValues.hasAsset === false &&
      !isUnder18(personValues.dob)
    )
  },
})

const noAssetSinceYear = makeDeterminerRule({
  name: 'person.noAssetSinceYear',
  necessaryIf: {'person.hasAsset': false},
})

const noAssetSinceYearRelationship = makeDeterminerRule({
  name: 'person.relationships[].otherPerson.noAssetSinceYear',
  necessaryIf: [START_TOKEN],
  necessaryIfResolved: ({
    values: {
      person: {relationships},
    },
    resolvedIndexes,
  }: any) => {
    const relationshipValues = relationships[resolvedIndexes[0]]
    const personValues = relationshipValues.otherPerson
    return (
      relationshipValues.liveTogether &&
      personValues.hasAsset === false &&
      !isUnder18(personValues.dob)
    )
  },
})

const homelessSinceMonth = makeDeterminerRule({
  name: 'person.homelessSinceMonth',
  necessaryIf: {'person.njResidencyDoc': NJ_RESIDENCY_DOC_HOMELESS_ATTESTATION},
})

const homelessSinceYear = makeDeterminerRule({
  name: 'person.homelessSinceYear',
  necessaryIf: {'person.njResidencyDoc': NJ_RESIDENCY_DOC_HOMELESS_ATTESTATION},
})

const supporterRelationship = makeDeterminerRule({
  name: 'person.supporterRelationship',
  necessaryIf: {
    'person.whoSupportWithoutIncome': WHO_SUPPORT_WITHOUT_INCOME_OTHER,
  },
})

const supportSinceMonth = makeDeterminerRule({
  name: 'person.supportSinceMonth',
  necessaryIf: {
    'person.whoSupportWithoutIncome': WHO_SUPPORT_WITHOUT_INCOME_OTHER,
  },
})

const supportSinceYear = makeDeterminerRule({
  name: 'person.supportSinceYear',
  necessaryIf: {
    'person.whoSupportWithoutIncome': WHO_SUPPORT_WITHOUT_INCOME_OTHER,
  },
})

const supportType = makeDeterminerRule({
  name: 'person.supportType',
  necessaryIf: {
    'person.whoSupportWithoutIncome': WHO_SUPPORT_WITHOUT_INCOME_OTHER,
  },
})

const supporterAddressStreet = makeDeterminerRule({
  name: 'person.supporterAddressStreet',
  necessaryIf: {
    'person.whoSupportWithoutIncome': WHO_SUPPORT_WITHOUT_INCOME_OTHER,
  },
})

const supporterAddressCity = makeDeterminerRule({
  name: 'person.supporterAddressCity',
  necessaryIf: {
    'person.whoSupportWithoutIncome': WHO_SUPPORT_WITHOUT_INCOME_OTHER,
  },
})

const supporterAddressState = makeDeterminerRule({
  name: 'person.supporterAddressState',
  necessaryIf: {
    'person.whoSupportWithoutIncome': WHO_SUPPORT_WITHOUT_INCOME_OTHER,
  },
})

const supporterAddressZip = makeDeterminerRule({
  name: 'person.supporterAddressZip',
  necessaryIf: {
    'person.whoSupportWithoutIncome': WHO_SUPPORT_WITHOUT_INCOME_OTHER,
  },
})

const supporterPhone = makeDeterminerRule({
  name: 'person.supporterPhone',
  necessaryIf: {
    'person.whoSupportWithoutIncome': WHO_SUPPORT_WITHOUT_INCOME_OTHER,
  },
})

const othersAttestationWho = makeDeterminerRule({
  name: 'person.othersAttestationWho',
  necessaryIf: {
    'person.njResidencyDoc': NJ_RESIDENCY_DOC_OTHERS_ATTESTATION,
  },
})

const othersAttestationRelationship = makeDeterminerRule({
  name: 'person.othersAttestationRelationship',
  necessaryIf: {
    'person.njResidencyDoc': NJ_RESIDENCY_DOC_OTHERS_ATTESTATION,
  },
})

const othersAttestationPhone = makeDeterminerRule({
  name: 'person.othersAttestationPhone',
  necessaryIf: {
    'person.njResidencyDoc': NJ_RESIDENCY_DOC_OTHERS_ATTESTATION,
  },
})

const addressSinceMonth = makeDeterminerRule({
  name: 'person.addressSinceMonth',
  necessaryIf: {
    'person.njResidencyDoc': NJ_RESIDENCY_DOC_OTHERS_ATTESTATION,
  },
})

const addressSinceYear = makeDeterminerRule({
  name: 'person.addressSinceYear',
  necessaryIf: {
    'person.njResidencyDoc': NJ_RESIDENCY_DOC_OTHERS_ATTESTATION,
  },
})

export const determinerPreliminaryMedicaid = ({
  federalPovertyLevels,
  t,
}: {
  federalPovertyLevels: FederalPovertyLevels_federalPovertyLevels[]
  t: TFunction
}) =>
  makeDeterminer({
    name: 'medicaid',
    isPreliminary: true,
    rules: [
      isOver65,
      alreadyHasInsurance,
      doesntLiveInNj,
      ciStatus,
      notFiveYearsInUs,
      specialCategory,
      pregnant,
      maritalStatus,
      livesWithSpouse,
      spousePregnant,
      expectedChildren,
      taxFilingStatus,
      claimedBySomeoneOtherThanParents,
      livingWithParentsWhoDoNotFileJointly,
      estTaxDependentCount,
      taxDependentInclSpouseLiveWithClient,
      estParentLiveWithClient,
      estChildren19LiveWithClient,
      estMinor19SiblingLiveWithClient,
      estHouseholdIncome({federalPovertyLevels, t}),
      fullTimeStudent,
    ],
  })

export const determinerPreliminaryCharityCare = ({
  federalPovertyLevels,
  t,
}: {
  federalPovertyLevels: FederalPovertyLevels_federalPovertyLevels[]
  t: TFunction
}) =>
  makeDeterminer({
    name: 'charityCare',
    isPreliminary: true,
    rules: [
      {
        ...alreadyHasInsurance,
        necessaryIf: [START_TOKEN],
      },
      {
        ...doesntLiveInNj,
        achieves: ({value}) => (value ? CI_ELIGIBLE : null),
      },
      maritalStatus,
      {
        ...pregnant,
        necessaryIf: {
          [CI_ELIGIBLE]: ({values}: any) => values.person.gender !== 'Male',
        },
      },
      {
        ...expectedChildren,
        necessaryIf: {'person.pregnant': true},
      },
      livesWithSpouseCharityCare,
      spousePregnant,
      age,
      {
        ...fullTimeStudent,
        necessaryIf: {
          'person.dob': ({
            value,
            values: {
              person: {maritalStatus, spouseLiveWithMe},
            },
          }: any) =>
            value &&
            !isUnder18(value) &&
            !(maritalStatus === MARITAL_STATUS_MARRIED && spouseLiveWithMe),
        },
      },
      {
        ...estParentLiveWithClient,
        necessaryIf: {
          [CI_ELIGIBLE]: ({
            values: {
              person: {dob, maritalStatus, spouseLiveWithMe, isStudent},
            },
          }: any) =>
            !(maritalStatus === MARITAL_STATUS_MARRIED && spouseLiveWithMe) &&
            !(dob && !isUnder18(dob) && !isStudent),
        },
      },
      children18LiveWithClient,
      minor18SiblingLiveWithClient,
      estHouseholdIncomeCharityCare({federalPovertyLevels, t}),
      minorNotLivingWithParent({t}),
    ],
  })

export const determinerFullMedicaid = ({
  application,
  federalPovertyLevels,
  t,
}: {
  application: Person_person_openApplications_MedicaidApplication
  federalPovertyLevels: FederalPovertyLevels_federalPovertyLevels[]
  t: TFunction
}) =>
  makeDeterminer({
    name: 'medicaid',
    isPreliminary: false,
    rules: [
      isOver65Full,
      isOver65Relationship,
      {
        ...alreadyHasInsurance,
        necessaryIf: [START_TOKEN],
        ineligibleIf: undefined,
      },
      alreadyHasInsuranceRelationship,
      insuranceName,
      insurancePolicyNumber,
      doesntLiveInNjFull,
      doesntLiveInNjRelationship,
      njResidencyDocMedicaid(application),
      njResidencyDocRelationshipMedicaid(application),
      ciStatusFull,
      ciStatusRelationship,
      notFiveYearsInUs,
      notFiveYearsInUsRelationship,
      {
        ...specialCategory,
        necessaryIf: {
          'person.ciStatus': ({value}: any) =>
            [CI_STATUS_LEGAL_RESIDENT, CI_STATUS_OTHER].includes(value),
        },
        ineligibleIf: undefined,
      },
      specialCategoryRelationship,
      ciDocumentMedicaid(application),
      ciDocumentRelationshipMedicaid(application),
      wantsCoverageRelationship,
      ciDocumentNameMedicaid(application),
      ciDocumentNameRelationshipMedicaid(application),
      dateOfEntry,
      dateOfEntryRelationship,
      uscisNo,
      uscisNoRelationship,
      prCardNo,
      prCardNoRelationship,
      maritalStatusFull,
      maritalStatusRelationship,
      pregnantFull,
      pregnantRelationship,
      dueDate,
      dueDateRelationship,
      expectedChildrenFull,
      expectedChildrenRelationship,
      livesWithSpouse,
      livesWithSpouseRelationship,
      {
        ...fullTimeStudent,
        necessaryIf: [START_TOKEN],
      },
      fullTimeStudentRelationship,
      homeAddressStreet,
      homeAddressCity,
      homeAddressState,
      homeAddressZip,
      ssn,
      ssnRelationshipMedicaid,
      phoneNumber,
      phoneNumberNumber,
      phoneNumberNumberRelationship,
      phoneNumberLanguage,
      phoneNumberLanguageRelationship,
      phoneNumberUsedInApp,
      phoneNumberUsedInAppRelationship,
      phoneNumberUsedForEsign,
      phoneNumberUsedForEsignRelationship,
      taxFilingStatusFullMedicaid({federalPovertyLevels, t}),
      taxFilingStatusRelationship,
      dependentOfTaxFiler,
      dependentOfTaxFilerRelationship,
      claimedBySomeoneOtherThanParents,
      livingWithParentsWhoDoNotFileJointly,
      incomeSourceType,
      incomeSourceTypeRelationship,
      incomeSourceEmployerNameMedicaid,
      incomeSourceEmployerNameRelationshipMedicaid,
      incomeSourceEmployerNameWhenUsedForEsign,
      incomeSourceEmployerNameWhenUsedForEsignRelationship,
      incomeSourceEmployerAddressStreet,
      incomeSourceEmployerAddressStreetRelationship,
      incomeSourceEmployerAddressStreetWhenUsedForEsign,
      incomeSourceEmployerAddressStreetWhenUsedForEsignRelationship,
      incomeSourceEmployerAddressCity,
      incomeSourceEmployerAddressCityRelationship,
      incomeSourceEmployerAddressCityWhenUsedForEsign,
      incomeSourceEmployerAddressCityWhenUsedForEsignRelationship,
      incomeSourceEmployerAddressState,
      incomeSourceEmployerAddressStateRelationship,
      incomeSourceEmployerAddressStateWhenUsedForEsign,
      incomeSourceEmployerAddressStateWhenUsedForEsignRelationship,
      incomeSourceEmployerAddressZip,
      incomeSourceEmployerAddressZipRelationship,
      incomeSourceEmployerAddressZipWhenUsedForEsign,
      incomeSourceEmployerAddressZipWhenUsedForEsignRelationship,
      incomeSourceEmployerInsurance,
      incomeSourceEmployerInsuranceRelationship,
      incomeSourceFullPartTime,
      incomeSourceFullPartTimeRelationship,
      incomeSourceStartMonth,
      incomeSourceStartMonthRelationship,
      incomeSourceStartYear,
      incomeSourceStartYearRelationship,
      incomeSourceEndMonth,
      incomeSourceEndMonthRelationship,
      incomeSourceEndYear,
      incomeSourceEndYearRelationship,
      incomeSourceAmount,
      incomeSourceAmountRelationship,
      incomeSourcePayFrequency,
      incomeSourcePayFrequencyRelationship,
      incomeSourceUsedForEsign,
      incomeSourceUsedForEsignRelationship,
      changeJobInLast6Mon,
      changeJobInLast6MonRelationship,
      deductionType,
      deductionTypeRelationship,
      deductionStartMonth,
      deductionStartMonthRelationship,
      deductionStartYear,
      deductionStartYearRelationship,
      deductionEndMonth,
      deductionEndMonthRelationship,
      deductionEndYear,
      deductionEndYearRelationship,
      deductionAmount,
      deductionAmountRelationship,
      deductionFrequency,
      deductionFrequencyRelationship,
      incomeInLast30Days,
      incomeInLast30DaysRelationship,
      whoSupportWithoutIncome,
      whoSupportWithoutIncomeRelationship,
      supporterRelationship,
      supportSinceMonth,
      supportSinceYear,
      supportType,
      supporterAddressStreet,
      supporterAddressCity,
      supporterAddressState,
      supporterAddressZip,
      supporterPhone,
      othersSupportWithoutIncome,
      othersSupportWithoutIncomeRelationship,
      selfSupportWithoutIncome,
      selfSupportWithoutIncomeRelationship,
      insuranceLast3Months,
      insuranceLast3MonthsRelationship,
      alaskanNativeamerican,
      alaskanNativeamericanRelationship,
      fosterCareAfter18,
      fosterCareAfter18Relationship,
      firstName,
      firstNameRelationship,
      lastName,
      lastNameRelationship,
      relationshipType,
      liveTogether,
      identityDocumentMedicaid(application),
      identityDocumentRelationshipMedicaid(application),
      desiredMco,
      pcp,
      childrenPcp,
      preferredLanguage,
      gender,
      genderRelationship,
      hasDeduction,
      hasDeductionRelationship,
      othersAttestationWho,
      othersAttestationRelationship,
      othersAttestationPhone,
      addressSinceMonth,
      addressSinceYear,
    ],
  })

export const determinerFullMedicaidLastMonth = ({
  federalPovertyLevels,
  t,
}: {
  federalPovertyLevels: FederalPovertyLevels_federalPovertyLevels[]
  t: TFunction
}) =>
  makeDeterminer({
    name: 'medicaid-lastMonth',
    isPreliminary: false,
    rules: [taxFilingStatusFullMedicaidLastMonth({federalPovertyLevels, t})],
  })

export const determinerFullMonitor = ({
  application,
}: {
  application: Person_person_openApplications_MonitorApplication
}) =>
  makeDeterminer({
    name: 'monitor',
    isPreliminary: false,
    rules: [
      isOver65Full,
      isOver65Relationship,
      {
        ...doesntLiveInNj,
        necessaryIf: [START_TOKEN],
        ineligibleIf: undefined,
      },
      homeAddressStreet,
      homeAddressCity,
      homeAddressState,
      homeAddressZip,
      ssn,
      ssnRelationshipMonitor({application}),
      phoneNumber,
      phoneNumberNumber,
      phoneNumberNumberRelationship,
      phoneNumberLanguage,
      phoneNumberLanguageRelationship,
      phoneNumberUsedInApp,
      phoneNumberUsedInAppRelationship,
      phoneNumberUsedForEsign,
      phoneNumberUsedForEsignRelationship,
      firstName,
      firstNameRelationship,
      lastName,
      lastNameRelationship,
      relationshipType,
      preferredLanguage,
      gender,
      genderRelationship,
    ],
  })

export const determinerFullCharityCare = ({
  federalPovertyLevels,
  application,
  t,
}: {
  federalPovertyLevels: FederalPovertyLevels_federalPovertyLevels[]
  application: Person_person_openApplications_CharityCareApplication

  t: TFunction
}) =>
  makeDeterminer({
    name: 'charityCare-annual',
    isPreliminary: false,
    rules: [
      preferredLanguage,
      doesntLiveInNjFull,
      livesInNjRelationship,
      njResidencyDoc,
      livedInNjSinceMonth,
      livedInNjSinceMonthRelationship,
      livedInNjSinceYear,
      livedInNjSinceYearRelationship,
      ciStatusFull,
      pendingUsCitizenship,
      maritalStatusFull,
      pregnantFull,
      pregnantRelationship,
      expectedChildrenFull,
      expectedChildrenRelationship,
      livesWithSpouseCharityCare,
      isOver65Full,
      ageFullCharityCare({federalPovertyLevels, application, t}),
      isOver65Relationship,
      {
        ...fullTimeStudent,
        necessaryIf: {
          'person.dob': ({
            value,
            values: {
              person: {maritalStatus, spouseLiveWithMe},
            },
          }: any) =>
            value &&
            !isUnder18(value) &&
            !(maritalStatus === MARITAL_STATUS_MARRIED && spouseLiveWithMe),
        },
      },
      {
        ...fullTimeStudentRelationship,
        necessaryIf: {
          'person.relationships[].otherPerson.dob': ({
            value,
            values: {
              person: {relationships},
            },
            resolvedIndexes,
          }: any) => {
            const {liveTogether} = relationships[resolvedIndexes[0]]
            return value && !isUnder18(value) && liveTogether
          },
        },
      },
      homeAddressStreet,
      homeAddressCity,
      homeAddressState,
      homeAddressZip,
      ssn,
      phoneNumber,
      phoneNumberNumber,
      phoneNumberNumberRelationship,
      phoneNumberLanguage,
      phoneNumberLanguageRelationship,
      phoneNumberUsedInApp,
      phoneNumberUsedInAppRelationship,
      phoneNumberUsedForEsign,
      phoneNumberUsedForEsignRelationship,
      incomeSourceType,
      incomeSourceTypeRelationship,
      incomeSourceStartMonth,
      incomeSourceStartMonthRelationship,
      incomeSourceStartYear,
      incomeSourceStartYearRelationship,
      incomeSourceEndMonth,
      incomeSourceEndMonthRelationship,
      incomeSourceEndYear,
      incomeSourceEndYearRelationship,
      incomeSourceAmount,
      incomeSourceAmountRelationship,
      incomeSourcePayFrequency,
      incomeSourcePayFrequencyRelationship,
      incomeSourceEmployerName,
      incomeSourceEmployerNameRelationship,
      incomeSourceUsedForEsign,
      incomeSourceUsedForEsignRelationship,
      incomeSourceEmployerAddressStreetWhenUsedForEsign,
      incomeSourceEmployerAddressStreetWhenUsedForEsignRelationship,
      incomeSourceEmployerAddressCityWhenUsedForEsign,
      incomeSourceEmployerAddressCityWhenUsedForEsignRelationship,
      incomeSourceEmployerAddressStateWhenUsedForEsign,
      incomeSourceEmployerAddressStateWhenUsedForEsignRelationship,
      incomeSourceEmployerAddressZipWhenUsedForEsign,
      incomeSourceEmployerAddressZipWhenUsedForEsignRelationship,
      firstName,
      firstNameRelationship,
      lastName,
      lastNameRelationship,
      relationshipType,
      liveTogether,
      identityDocument,
      identityDocumentSpouseCharityCare,
      hasAsset,
      hasAssetRelationship,
      assetType,
      assetTypeRelationship,
      assetAmount,
      assetAmountRelationship,
      incomeInLast30Days,
      incomeInLast30DaysRelationship,
      whoSupportWithoutIncome,
      othersSupportWithoutIncome,
      selfSupportWithoutIncome,
      noIncomeSinceMonth,
      noIncomeSinceMonthRelationship,
      noIncomeSinceYear,
      noIncomeSinceYearRelationship,
      noAssetSinceMonth,
      noAssetSinceMonthRelationship,
      noAssetSinceYear,
      noAssetSinceYearRelationship,
      homelessSinceMonth,
      homelessSinceYear,
      supporterRelationship,
      supportSinceMonth,
      supportSinceYear,
      supportType,
      supporterAddressStreet,
      supporterAddressCity,
      supporterAddressState,
      supporterAddressZip,
      supporterPhone,
      othersAttestationWho,
      othersAttestationRelationship,
      othersAttestationPhone,
      addressSinceMonth,
      addressSinceYear,
      hasInsurance,
      hasInsuranceHouseholdMembers,
    ],
  })

export const determinerFullSlide = ({
  federalPovertyLevels,
  application,
  t,
}: {
  federalPovertyLevels: FederalPovertyLevels_federalPovertyLevels[]
  application: Person_person_openApplications_SlideApplication
  t: TFunction
}) =>
  makeDeterminer({
    name: 'slide',
    isPreliminary: false,
    rules: [incomeFullSlide({federalPovertyLevels, application, t})],
  })

export const determinerFullRyanWhite = ({
  federalPovertyLevels,
  application,
  t,
}: {
  federalPovertyLevels: FederalPovertyLevels_federalPovertyLevels[]
  application: Person_person_openApplications_RyanWhiteApplication
  t: TFunction
}) =>
  makeDeterminer({
    name: 'ryanWhite',
    isPreliminary: false,
    rules: [incomeFullRyanWhite({federalPovertyLevels, application, t})],
  })
