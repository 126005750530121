import React, {FC} from 'react'
import {flowMax, addDisplayName, addProps} from 'ad-hok'
import {TFunction} from 'i18next'
import {flow, filter, map, toPairs, isFunction} from 'lodash/fp'
import {format} from 'date-fns/fp'

import {ReceivedWebforms_receivedWebforms} from 'graphql/deserializedTypes/ReceivedWebforms'
import {addTranslationHelpers} from 'utils/i18n'
import {callWith} from 'utils/fp'
import Body1 from 'components/Body1'
import {parseDate} from 'utils/form/fieldTypes'
import {getFormattedPhoneNumber} from 'utils/phone'
import {makeClasses, addClasses} from 'theme'
import {formatCurrency} from 'utils/number'

const classes = makeClasses((theme) => ({
  fieldValue: {
    fontWeight: 700,
  },
  section: {
    marginBottom: theme.spacing(2),
  },
  sectionHeader: {
    fontWeight: 700,
  },
  groupHeader: {
    fontWeight: 700,
    textDecoration: 'underline',
  },
}))

type GroupTranslationKey =
  | string
  | ((response: WebformResponse, t: TFunction) => string)

interface WebformRecordSpec {
  type: keyof typeof RECORD_TYPES
  labelTranslationKey: string | null
  groupTranslationKey: GroupTranslationKey
}

const getRelatedPersonGroupTranslationKey = (relatedPersonNumber: number) => (
  response: WebformResponse,
  t: TFunction
) => {
  const relatedPersonRecord = response[`relatedPerson${relatedPersonNumber}`]
  const name = [relatedPersonRecord?.firstName, relatedPersonRecord?.lastName]
    .filter((x) => x)
    .join(' ')
  return name
    ? t('webformResponseDetails.relatedPersonWithName', {
        name,
        relatedPersonNumber,
      })
    : t('webformResponseDetails.relatedPerson', {relatedPersonNumber})
}

const relatedPerson1GroupTranslationKey = getRelatedPersonGroupTranslationKey(1)
const relatedPerson2GroupTranslationKey = getRelatedPersonGroupTranslationKey(2)
const relatedPerson3GroupTranslationKey = getRelatedPersonGroupTranslationKey(3)
const relatedPerson4GroupTranslationKey = getRelatedPersonGroupTranslationKey(4)
const relatedPerson5GroupTranslationKey = getRelatedPersonGroupTranslationKey(5)
const relatedPerson6GroupTranslationKey = getRelatedPersonGroupTranslationKey(6)
const relatedPerson7GroupTranslationKey = getRelatedPersonGroupTranslationKey(7)
const relatedPerson8GroupTranslationKey = getRelatedPersonGroupTranslationKey(8)
const relatedPerson9GroupTranslationKey = getRelatedPersonGroupTranslationKey(9)
const relatedPerson10GroupTranslationKey = getRelatedPersonGroupTranslationKey(
  10
)

type WebformSlug =
  | 'chemedIntake'
  | 'carepointIntake'
  | 'bergenIntake'
  | 'stpetersIntake'
  | 'bergenEd'
  | 'atlanticareIntake'
  | 'screeningIntake'
  | 'regionalonehealthIntake'

interface WebformRecordsSpec {
  [recordName: string]: WebformRecordSpec
}

const CHEMED_WEBFORM_RECORDS: WebformRecordsSpec = {
  person: {
    type: 'person',
    labelTranslationKey: null,
    groupTranslationKey: 'webformResponseDetails.patient',
  },
  phone: {
    type: 'phoneNumber',
    labelTranslationKey: 'webformResponseDetails.phoneNumber',
    groupTranslationKey: 'webformResponseDetails.patient',
  },
  income1: {
    type: 'incomeSource',
    labelTranslationKey: 'webformResponseDetails.incomeSource',
    groupTranslationKey: 'webformResponseDetails.patient',
  },
  relationship1: {
    type: 'relationship',
    labelTranslationKey: null,
    groupTranslationKey: relatedPerson1GroupTranslationKey,
  },
  relatedPerson1: {
    type: 'person',
    labelTranslationKey: null,
    groupTranslationKey: relatedPerson1GroupTranslationKey,
  },
  relatedPerson1Income1: {
    type: 'incomeSource',
    labelTranslationKey: 'webformResponseDetails.incomeSource',
    groupTranslationKey: relatedPerson1GroupTranslationKey,
  },
  relationship2: {
    type: 'relationship',
    labelTranslationKey: null,
    groupTranslationKey: relatedPerson2GroupTranslationKey,
  },
  relatedPerson2: {
    type: 'person',
    labelTranslationKey: null,
    groupTranslationKey: relatedPerson2GroupTranslationKey,
  },
  relatedPerson2Income1: {
    type: 'incomeSource',
    labelTranslationKey: 'webformResponseDetails.incomeSource',
    groupTranslationKey: relatedPerson2GroupTranslationKey,
  },
  relationship3: {
    type: 'relationship',
    labelTranslationKey: null,
    groupTranslationKey: relatedPerson3GroupTranslationKey,
  },
  relatedPerson3: {
    type: 'person',
    labelTranslationKey: null,
    groupTranslationKey: relatedPerson3GroupTranslationKey,
  },
  relatedPerson3Income1: {
    type: 'incomeSource',
    labelTranslationKey: 'webformResponseDetails.incomeSource',
    groupTranslationKey: relatedPerson3GroupTranslationKey,
  },
  relationship4: {
    type: 'relationship',
    labelTranslationKey: null,
    groupTranslationKey: relatedPerson4GroupTranslationKey,
  },
  relatedPerson4: {
    type: 'person',
    labelTranslationKey: null,
    groupTranslationKey: relatedPerson4GroupTranslationKey,
  },
  relatedPerson4Income1: {
    type: 'incomeSource',
    labelTranslationKey: 'webformResponseDetails.incomeSource',
    groupTranslationKey: relatedPerson4GroupTranslationKey,
  },
  relationship5: {
    type: 'relationship',
    labelTranslationKey: null,
    groupTranslationKey: relatedPerson5GroupTranslationKey,
  },
  relatedPerson5: {
    type: 'person',
    labelTranslationKey: null,
    groupTranslationKey: relatedPerson5GroupTranslationKey,
  },
  relatedPerson5Income1: {
    type: 'incomeSource',
    labelTranslationKey: 'webformResponseDetails.incomeSource',
    groupTranslationKey: relatedPerson5GroupTranslationKey,
  },
  relationship6: {
    type: 'relationship',
    labelTranslationKey: null,
    groupTranslationKey: relatedPerson6GroupTranslationKey,
  },
  relatedPerson6: {
    type: 'person',
    labelTranslationKey: null,
    groupTranslationKey: relatedPerson6GroupTranslationKey,
  },
  relatedPerson6Income1: {
    type: 'incomeSource',
    labelTranslationKey: 'webformResponseDetails.incomeSource',
    groupTranslationKey: relatedPerson6GroupTranslationKey,
  },
  relationship7: {
    type: 'relationship',
    labelTranslationKey: null,
    groupTranslationKey: relatedPerson7GroupTranslationKey,
  },
  relatedPerson7: {
    type: 'person',
    labelTranslationKey: null,
    groupTranslationKey: relatedPerson7GroupTranslationKey,
  },
  relatedPerson7Income1: {
    type: 'incomeSource',
    labelTranslationKey: 'webformResponseDetails.incomeSource',
    groupTranslationKey: relatedPerson7GroupTranslationKey,
  },
  relationship8: {
    type: 'relationship',
    labelTranslationKey: null,
    groupTranslationKey: relatedPerson8GroupTranslationKey,
  },
  relatedPerson8: {
    type: 'person',
    labelTranslationKey: null,
    groupTranslationKey: relatedPerson8GroupTranslationKey,
  },
  relatedPerson8Income1: {
    type: 'incomeSource',
    labelTranslationKey: 'webformResponseDetails.incomeSource',
    groupTranslationKey: relatedPerson8GroupTranslationKey,
  },
  relationship9: {
    type: 'relationship',
    labelTranslationKey: null,
    groupTranslationKey: relatedPerson9GroupTranslationKey,
  },
  relatedPerson9: {
    type: 'person',
    labelTranslationKey: null,
    groupTranslationKey: relatedPerson9GroupTranslationKey,
  },
  relatedPerson9Income1: {
    type: 'incomeSource',
    labelTranslationKey: 'webformResponseDetails.incomeSource',
    groupTranslationKey: relatedPerson9GroupTranslationKey,
  },
  relationship10: {
    type: 'relationship',
    labelTranslationKey: null,
    groupTranslationKey: relatedPerson10GroupTranslationKey,
  },
  relatedPerson10: {
    type: 'person',
    labelTranslationKey: null,
    groupTranslationKey: relatedPerson10GroupTranslationKey,
  },
  relatedPerson10Income1: {
    type: 'incomeSource',
    labelTranslationKey: 'webformResponseDetails.incomeSource',
    groupTranslationKey: relatedPerson10GroupTranslationKey,
  },
}

const CAREPOINT_WEBFORM_RECORDS: WebformRecordsSpec = {
  person: {
    type: 'person',
    labelTranslationKey: null,
    groupTranslationKey: 'webformResponseDetails.patient',
  },
  phone: {
    type: 'phoneNumber',
    labelTranslationKey: 'webformResponseDetails.phoneNumber',
    groupTranslationKey: 'webformResponseDetails.patient',
  },
  income1: {
    type: 'incomeSource',
    labelTranslationKey: 'webformResponseDetails.incomeSource',
    groupTranslationKey: 'webformResponseDetails.patient',
  },
  asset1: {
    type: 'asset',
    labelTranslationKey: 'webformResponseDetails.asset1',
    groupTranslationKey: 'webformResponseDetails.patient',
  },
  asset2: {
    type: 'asset',
    labelTranslationKey: 'webformResponseDetails.asset2',
    groupTranslationKey: 'webformResponseDetails.patient',
  },
  relationship1: {
    type: 'relationship',
    labelTranslationKey: null,
    groupTranslationKey: relatedPerson1GroupTranslationKey,
  },
  relatedPerson1: {
    type: 'person',
    labelTranslationKey: null,
    groupTranslationKey: relatedPerson1GroupTranslationKey,
  },
  relatedPerson1Income1: {
    type: 'incomeSource',
    labelTranslationKey: 'webformResponseDetails.incomeSource',
    groupTranslationKey: relatedPerson1GroupTranslationKey,
  },
  relatedPerson1Asset1: {
    type: 'asset',
    labelTranslationKey: 'webformResponseDetails.asset1',
    groupTranslationKey: relatedPerson1GroupTranslationKey,
  },
  relatedPerson1Asset2: {
    type: 'asset',
    labelTranslationKey: 'webformResponseDetails.asset2',
    groupTranslationKey: relatedPerson1GroupTranslationKey,
  },
  relationship2: {
    type: 'relationship',
    labelTranslationKey: null,
    groupTranslationKey: relatedPerson2GroupTranslationKey,
  },
  relatedPerson2: {
    type: 'person',
    labelTranslationKey: null,
    groupTranslationKey: relatedPerson2GroupTranslationKey,
  },
  relatedPerson2Income1: {
    type: 'incomeSource',
    labelTranslationKey: 'webformResponseDetails.incomeSource',
    groupTranslationKey: relatedPerson2GroupTranslationKey,
  },
  relatedPerson2Asset1: {
    type: 'asset',
    labelTranslationKey: 'webformResponseDetails.asset1',
    groupTranslationKey: relatedPerson2GroupTranslationKey,
  },
  relatedPerson2Asset2: {
    type: 'asset',
    labelTranslationKey: 'webformResponseDetails.asset2',
    groupTranslationKey: relatedPerson2GroupTranslationKey,
  },
  relationship3: {
    type: 'relationship',
    labelTranslationKey: null,
    groupTranslationKey: relatedPerson3GroupTranslationKey,
  },
  relatedPerson3: {
    type: 'person',
    labelTranslationKey: null,
    groupTranslationKey: relatedPerson3GroupTranslationKey,
  },
  relatedPerson3Income1: {
    type: 'incomeSource',
    labelTranslationKey: 'webformResponseDetails.incomeSource',
    groupTranslationKey: relatedPerson3GroupTranslationKey,
  },
  relatedPerson3Asset1: {
    type: 'asset',
    labelTranslationKey: 'webformResponseDetails.asset1',
    groupTranslationKey: relatedPerson3GroupTranslationKey,
  },
  relatedPerson3Asset2: {
    type: 'asset',
    labelTranslationKey: 'webformResponseDetails.asset2',
    groupTranslationKey: relatedPerson3GroupTranslationKey,
  },
  relationship4: {
    type: 'relationship',
    labelTranslationKey: null,
    groupTranslationKey: relatedPerson4GroupTranslationKey,
  },
  relatedPerson4: {
    type: 'person',
    labelTranslationKey: null,
    groupTranslationKey: relatedPerson4GroupTranslationKey,
  },
  relatedPerson4Income1: {
    type: 'incomeSource',
    labelTranslationKey: 'webformResponseDetails.incomeSource',
    groupTranslationKey: relatedPerson4GroupTranslationKey,
  },
  relatedPerson4Asset1: {
    type: 'asset',
    labelTranslationKey: 'webformResponseDetails.asset1',
    groupTranslationKey: relatedPerson4GroupTranslationKey,
  },
  relatedPerson4Asset2: {
    type: 'asset',
    labelTranslationKey: 'webformResponseDetails.asset2',
    groupTranslationKey: relatedPerson4GroupTranslationKey,
  },
  relationship5: {
    type: 'relationship',
    labelTranslationKey: null,
    groupTranslationKey: relatedPerson5GroupTranslationKey,
  },
  relatedPerson5: {
    type: 'person',
    labelTranslationKey: null,
    groupTranslationKey: relatedPerson5GroupTranslationKey,
  },
  relatedPerson5Income1: {
    type: 'incomeSource',
    labelTranslationKey: 'webformResponseDetails.incomeSource',
    groupTranslationKey: relatedPerson5GroupTranslationKey,
  },
  relatedPerson5Asset1: {
    type: 'asset',
    labelTranslationKey: 'webformResponseDetails.asset1',
    groupTranslationKey: relatedPerson5GroupTranslationKey,
  },
  relatedPerson5Asset2: {
    type: 'asset',
    labelTranslationKey: 'webformResponseDetails.asset2',
    groupTranslationKey: relatedPerson5GroupTranslationKey,
  },
  relationship6: {
    type: 'relationship',
    labelTranslationKey: null,
    groupTranslationKey: relatedPerson6GroupTranslationKey,
  },
  relatedPerson6: {
    type: 'person',
    labelTranslationKey: null,
    groupTranslationKey: relatedPerson6GroupTranslationKey,
  },
  relatedPerson6Income1: {
    type: 'incomeSource',
    labelTranslationKey: 'webformResponseDetails.incomeSource',
    groupTranslationKey: relatedPerson6GroupTranslationKey,
  },
  relatedPerson6Asset1: {
    type: 'asset',
    labelTranslationKey: 'webformResponseDetails.asset1',
    groupTranslationKey: relatedPerson6GroupTranslationKey,
  },
  relatedPerson6Asset2: {
    type: 'asset',
    labelTranslationKey: 'webformResponseDetails.asset2',
    groupTranslationKey: relatedPerson6GroupTranslationKey,
  },
}

const BERGEN_WEBFORM_RECORDS: WebformRecordsSpec = {
  ...CAREPOINT_WEBFORM_RECORDS,
  incomeCashSupport: {
    type: 'incomeSource',
    labelTranslationKey: 'webformResponseDetails.incomeSourceCashSupport',
    groupTranslationKey: 'webformResponseDetails.patient',
  },
}

const STPETERS_WEBFORM_RECORDS: WebformRecordsSpec = BERGEN_WEBFORM_RECORDS

const BERGEN_ED_RECORDS: WebformRecordsSpec = BERGEN_WEBFORM_RECORDS

const ATLANTICARE_WEBFORM_RECORDS: WebformRecordsSpec = BERGEN_WEBFORM_RECORDS

const SCREENING_WEBFORM_RECORDS: WebformRecordsSpec = BERGEN_WEBFORM_RECORDS

const REGIONALONEHEALTH_WEBFORM_RECORDS: WebformRecordsSpec = BERGEN_WEBFORM_RECORDS

const ALL_WEBFORM_RECORDS: Record<WebformSlug, WebformRecordsSpec> = {
  chemedIntake: CHEMED_WEBFORM_RECORDS,
  carepointIntake: CAREPOINT_WEBFORM_RECORDS,
  bergenIntake: BERGEN_WEBFORM_RECORDS,
  stpetersIntake: STPETERS_WEBFORM_RECORDS,
  bergenEd: BERGEN_ED_RECORDS,
  atlanticareIntake: ATLANTICARE_WEBFORM_RECORDS,
  screeningIntake: SCREENING_WEBFORM_RECORDS,
  regionalonehealthIntake: REGIONALONEHEALTH_WEBFORM_RECORDS,
}

export const getWebformRecordsSpec = ({slug}: {slug: string}) =>
  ALL_WEBFORM_RECORDS[slug as WebformSlug]

interface WebformRecord {
  [fieldName: string]: any
}

export interface WebformResponse {
  [recordName: string]: WebformRecord
}

interface SpecialHandlerSpec {
  fields: string[]
  getFormatted: (record: WebformRecord) => string
}

interface FieldTypeFormatters {
  [typeName: string]: (value: any, t: TFunction) => string
}

const makeFieldTypeFormatters = <
  TFieldTypeFormatters extends FieldTypeFormatters
>(
  fieldTypeFormatters: TFieldTypeFormatters
) => fieldTypeFormatters

const FIELD_TYPE_FORMATTERS = makeFieldTypeFormatters({
  date: (value) => format('M/d/yyyy')(parseDate(value)),
  boolean: (value, t) => (value ? t('form.yes') : t('form.no')),
  multiselect: (value) => value.join(', '),
  phone: (value) => getFormattedPhoneNumber(value),
  currency: (value) => formatCurrency(value),
})

type FieldType = keyof typeof FIELD_TYPE_FORMATTERS

interface FieldTypesSpec {
  [fieldName: string]: FieldType
}

interface RecordTypeSpec {
  translationKeyPrefix: string
  // specialHandlers?: SpecialHandlerSpec[]
  types?: FieldTypesSpec
  labelTranslationKeyOverrides?: {
    [fieldName: string]: string
  }
}

interface RecordTypes {
  [typeName: string]: RecordTypeSpec
}

const makeRecordTypes = <TRecordTypes extends RecordTypes>(
  recordTypes: TRecordTypes
) => recordTypes

const RECORD_TYPES = makeRecordTypes({
  person: {
    translationKeyPrefix: 'personForm.fieldLabels',
    // specialHandlers: [
    //   {
    //     fields: [
    //       'homeAddressStreet',
    //       'homeAddressCity',
    //       'homeAddressState',
    //       'homeAddressZip',
    //     ],
    //     getFormatted: () => 'abc',
    //   },
    // ],
    types: {
      currentInsurance: 'boolean',
      currentInsuranceIncludesDental: 'boolean',
      dateOfEntry: 'date',
      dob: 'date',
      dueDate: 'date',
      fiveYearsInUs: 'boolean',
      income: 'boolean',
      insuranceStartdate: 'date',
      isStudent: 'boolean',
      liveInNj: 'boolean',
      pregnant: 'boolean',
      supportType: 'multiselect',
      supporterPhone: 'phone',
      spouseLiveWithMe: 'boolean',
      estHhIncome: 'currency',
      hasAsset: 'boolean',
      appointmentDate: 'date',
      livingWithParentsWhoDoNotFileJointly: 'boolean',
      claimedBySomeoneOtherThanParents: 'boolean',
      spousePregnant: 'boolean',
      taxDependentInclSpouseLiveWithClient: 'boolean',
      ownNonresidentProperty: 'boolean',
      pendingUsCitizenship: 'boolean',
    },
    labelTranslationKeyOverrides: {
      currentInsurance: 'personForm.fieldLabels.hasInsurance',
      liveInNj: 'personForm.fieldLabels.livesInNj',
    },
  },
  phoneNumber: {
    translationKeyPrefix: 'phoneNumberForm.fieldLabels.phoneNumber',
    types: {
      number: 'phone',
    },
  },
  incomeSource: {
    translationKeyPrefix: 'incomeSourceForm.fieldLabels.incomeSource',
    types: {
      amount: 'currency',
      proofOfIncome: 'boolean',
      employerPhone: 'phone',
    },
  },
  asset: {
    translationKeyPrefix: 'assetForm.fieldLabels.asset',
    types: {
      amount: 'currency',
    },
  },
  relationship: {
    translationKeyPrefix: 'personForm.fieldLabels',
    types: {
      liveTogether: 'boolean',
    },
  },
})

interface FormattedField {
  label: string
  value: string
}

export interface FormattedSection {
  groupLabel: string | null
  label: string | null
  fields: FormattedField[]
}

const ADDRESS_FIELD_REGEX = /^(.*Address)(Street|City|State|Zip)$/
const getAddressFieldPrefix = (fieldName: string): string | null => {
  const match = ADDRESS_FIELD_REGEX.exec(fieldName)
  if (!match) return null
  return match[1]
}

const getAddressFieldLabelPrefix = (
  addressFieldNamePrefix: string,
  translationKeyPrefix: string,
  t: TFunction
): string => {
  if (!translationKeyPrefix.endsWith('fieldLabels')) return ''
  return `${t(
    `${translationKeyPrefix.replace(
      /fieldLabels$/,
      'fieldSetLabels'
    )}.${addressFieldNamePrefix}`
  )} `
}

const getFieldLabel = (
  recordTypeSpec: RecordTypeSpec,
  fieldName: string,
  t: TFunction
): string => {
  if (recordTypeSpec.labelTranslationKeyOverrides?.[fieldName])
    return t(recordTypeSpec.labelTranslationKeyOverrides[fieldName])
  const addressFieldNamePrefix = getAddressFieldPrefix(fieldName)
  if (!!addressFieldNamePrefix) {
    return `${getAddressFieldLabelPrefix(
      addressFieldNamePrefix,
      recordTypeSpec.translationKeyPrefix,
      t
    )}${t(`${recordTypeSpec.translationKeyPrefix}.${fieldName}`)}`
  }
  return t(`${recordTypeSpec.translationKeyPrefix}.${fieldName}`)
}

const getFieldValue = (
  recordTypeSpec: RecordTypeSpec,
  value: any,
  fieldName: string,
  t: TFunction
): string => {
  if (recordTypeSpec.types?.[fieldName]) {
    const fieldTypeFormatter =
      FIELD_TYPE_FORMATTERS[recordTypeSpec.types[fieldName]]
    return fieldTypeFormatter(value, t)
  }
  return value
}

const getFormattedFields = (
  responseRecord: WebformRecord,
  recordTypeSpec: RecordTypeSpec,
  t: TFunction
): FormattedField[] =>
  callWith(
    responseRecord,
    flow(
      toPairs,
      filter(([_fieldName, value]) => value != null),
      map(([fieldName, value]) => ({
        label: getFieldLabel(recordTypeSpec, fieldName, t),
        value: getFieldValue(recordTypeSpec, value, fieldName, t),
      }))
    )
  )

const getFormattedResponseSection = (
  response: WebformResponse,
  previousGroupTranslationKey: GroupTranslationKey | null,
  t: TFunction
) => (
  webformRecordSpec: WebformRecordSpec,
  recordName: string
): FormattedSection | null =>
  response[recordName]
    ? {
        groupLabel:
          webformRecordSpec.groupTranslationKey !== previousGroupTranslationKey
            ? isFunction(webformRecordSpec.groupTranslationKey)
              ? webformRecordSpec.groupTranslationKey(response, t)
              : t(webformRecordSpec.groupTranslationKey)
            : null,
        label: webformRecordSpec.labelTranslationKey
          ? t(webformRecordSpec.labelTranslationKey)
          : null,
        fields: getFormattedFields(
          response[recordName],
          RECORD_TYPES[webformRecordSpec.type],
          t
        ),
      }
    : null

export const getFormattedResponseSections = (
  webformRecordsSpec: WebformRecordsSpec,
  response: WebformResponse,
  t: TFunction
): FormattedSection[] => {
  const sections: FormattedSection[] = []
  let previousGroupTranslationKey: GroupTranslationKey | null = null
  toPairs(webformRecordsSpec).forEach(([recordName, webformRecordSpec]) => {
    const formattedSection = getFormattedResponseSection(
      response,
      previousGroupTranslationKey,
      t
    )(webformRecordSpec, recordName)
    if (formattedSection) {
      sections.push(formattedSection)
      previousGroupTranslationKey = webformRecordSpec.groupTranslationKey
    }
  })
  return sections
}

interface WebformResponseSectionProps {
  section: FormattedSection
}

export const WebformResponseSection: FC<WebformResponseSectionProps> = flowMax(
  addDisplayName('WebformResponseSection'),
  addClasses(classes),
  ({section, classes}) => (
    <div className={classes.section}>
      {section.groupLabel && (
        <Body1 className={classes.groupHeader}>{section.groupLabel}</Body1>
      )}
      <Body1 className={classes.sectionHeader}>{section.label}</Body1>
      {section.fields.map(({label, value}, index) => (
        <Body1 key={index}>
          {label}: <span className={classes.fieldValue}>{value}</span>
        </Body1>
      ))}
    </div>
  )
)

interface WebformResponseDetailsProps {
  receivedWebform: ReceivedWebforms_receivedWebforms
}

export const WebformResponseDetails: FC<WebformResponseDetailsProps> = flowMax(
  addDisplayName('WebformResponseDetails'),
  addProps(({receivedWebform: {webform}}) => ({
    webformRecordsSpec: getWebformRecordsSpec(webform),
  })),
  addTranslationHelpers,
  addProps(
    ({webformRecordsSpec, receivedWebform: {response}, t}) => ({
      formattedResponseSections: getFormattedResponseSections(
        webformRecordsSpec,
        response,
        t
      ),
    }),
    ['webformRecordsSpec', 'receivedWebform', 't']
  ),
  ({formattedResponseSections}) => (
    <>
      {formattedResponseSections.map((section, index) => (
        <WebformResponseSection section={section} key={index} />
      ))}
    </>
  )
)
