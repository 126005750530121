import React from 'react'
import {
  flowMax,
  addDisplayName,
  addProps,
  addStateHandlers,
  addHandlers,
  addWrapper,
  addEffect,
  addState,
} from 'ad-hok'
import {FC} from 'react'
import {flow, isEqual} from 'lodash/fp'
import {mapValues, isArray, omit} from 'lodash'
import {addPropIdentityStabilization, addInterval} from 'ad-hok-utils'
import produce from 'immer'

import {
  addUpdatePersonMutation,
  addSaveEligibilityDeterminationsMutation,
  addFederalPovertyLevelsQuery,
  addPersonForAwaitingPhoneNumberEsignSessionIdQuery,
  addWebformsQuery,
} from 'graphql/generated'
import {addClasses, makeClasses, sharedStyles} from 'theme'
import {
  editPersonFormSchema,
  PersonWithRelationshipType,
} from 'components/EditPersonForm/schema'
import Form, {OnSubmitSuccessOptions} from 'components/Form'
import TextField from 'components/TextField'
import FormSection from 'components/FormSection'
import ReadOnlyTextField from 'components/ReadOnlyTextField'
import {addAppSnackbarContext} from 'utils/addAppSnackbar'
import {addTranslationHelpers} from 'utils/i18n'
import addRouteParams from 'utils/addRouteParams'
import {addPersonQuery} from 'graphql/generated'
import {addLoadingIndicator} from 'utils/dataLoading'
import Grid from 'components/Grid'
import {
  determinerPreliminaryMedicaid,
  determinerFullMonitor,
  determinerPreliminaryCharityCare,
  determinerFullCharityCare,
  determinerFullSlide,
  determinerFullRyanWhite,
} from 'components/EditPersonForm/determiners'
import {
  getHouseholdSizeDescriptionPreliminaryMedicaid,
  getHouseholdSizeDescriptionPreliminaryCharityCare,
  getHouseholdSizeDescriptionFullCharityCare,
  getHouseholdSizeDescriptionFullSlide,
  getHouseholdSizeDescriptionFullRyanWhite,
  determineHouseholdSizePartsFullMedicaid,
  determineHouseholdSizePartsFullCharityCare,
  determineHouseholdSizePartsFullSlide,
  determineHouseholdSizePartsFullRyanWhite,
} from 'utils/householdSize'
import {checkMedicaidEligibility} from 'components/EditPersonForm/checkMedicaidEligibility'
import {
  addFormContext,
  addFormCanonicalValuesContextTyped,
  addFieldsFreshnessContext,
} from 'utils/form/context'
import {addFormikTyped} from 'utils/form/formik'
import {
  ExtractFormSchemaFields,
  FormCanonicalValues,
  FormSchemaFields,
} from 'utils/form/schema'
import FormPrefix from 'components/FormPrefix'
import {
  SharedPersonDataFields,
  SharedPersonSections,
} from 'components/EditPersonForm/sharedFields'
import {
  determineDocuments,
  RequiredDocument,
} from 'components/EditPersonForm/documents'
import DocumentsSection from 'components/EditPersonForm/DocumentsSection'
import {getCanonicalValues} from 'utils/form/getValues'
import LeftColumn from 'components/EditPersonForm/LeftColumn'
import RightColumn from 'components/EditPersonForm/RightColumn'
import {addDocumentsSectionContextProvider} from 'components/EditPersonForm/documentsSectionContext'
import PersonSectionHeader from 'components/EditPersonForm/PersonSectionHeader'
import {BenefitType} from 'utils/benefits'
import {
  DeterminedEligibilitiesForBenefit,
  DeterminedEligibilitiesForFullMedicaid,
  Determiner,
  DeterminedEligibilitiesForFullCharityCare,
  DeterminedEligibilitiesForFullSlide,
  DeterminedEligibilitiesForFullRyanWhite,
} from 'utils/form/determiners'
import {determinerIncomeSourceFormCharityCare} from 'components/IncomeSourceDialog/determiner'
import {addRightColumnContextProvider} from 'components/EditPersonForm/rightColumnContext'
import {isApplicationHouseholdFrozen} from 'utils/applicationStatuses'
import {RelationshipSection} from 'components/EditPersonForm/relationships'
import typedAs from 'utils/typedAs'
import {
  VIEWING_RELATIONSHIP_INDEX_CLIENT,
  VIEWING_RELATIONSHIP_INDEX_DOCUMENTS,
} from 'components/EditPersonForm/viewingRelationshipIndex'
import {EligibilityDeterminationFields} from 'graphql/deserializedTypes/EligibilityDeterminationFields'
import {
  Person_person,
  Person_person_openApplications_MedicaidApplication_eligibilityDeterminations,
  Person_person_openApplications_CharityCareApplication_eligibilityDeterminations,
  Person_person_openApplications_MonitorApplication,
  Person_person_openApplications_CharityCareApplication,
  Person_person_openApplications,
  Person_person_openApplications_SlideApplication_eligibilityDeterminations,
  Person_person_openApplications_SlideApplication,
  Person_person_openApplications_RyanWhiteApplication_eligibilityDeterminations,
  Person_person_openApplications_RyanWhiteApplication,
} from 'graphql/deserializedTypes/Person'
import {
  EligibilityDeterminationInput,
  ApplicationHouseholdMembersInput,
  ApplicationHouseholdMemberInput,
} from 'graphql/deserializedTypes/globalTypes'
import {Assert, SchemaDoesntHaveExtraFields} from 'utils/form/typeHelpers'
import {
  addPhoneNumberOrIncomeSourceEsignSessionUrlsContext,
  addPhoneNumberOrIncomeSourceEsignSessionUrlsProviding,
  getPhoneNumberOrIncomeSourceEsignSessionUrls,
} from 'components/EditPersonForm/phoneNumberEsignSessionUrlsContext'
import {addWebformsContextProvider} from 'components/EditPersonForm/webformsContext'
import {getIsBenefitTypeWithEligibilityDeterminations} from 'components/ApplicationEligibilityCheckList'
import {addGetIsConfiguredBenefit} from 'utils/configContext'
import {addShouldShowApplicationPromptContextProviderFromRightColumnContext} from 'components/PhoneFieldArray'
import {createApplicationFormSchemaStatic} from 'components/CreateApplicationDialog/schema'
import {CreateApplication} from 'graphql/types/CreateApplication'
import {
  eligibilityStatusToApiValue,
  getEligibilityStatus,
} from 'utils/eligibilityStatus'
import {isPersonWithoutIncome} from 'utils/income'

const FORM_COLUMN_VERTICAL_OFFSET = 10
const classes = makeClasses((theme) => ({
  container: {
    ...sharedStyles.formColumnsContainer,
    paddingTop: FORM_COLUMN_VERTICAL_OFFSET,
  },
  formColumnContainer: {
    ...sharedStyles.formColumnContainer,
    width: 450,
    marginTop: -FORM_COLUMN_VERTICAL_OFFSET,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  formColumnInnerContainer: {
    position: 'relative',
    top: FORM_COLUMN_VERTICAL_OFFSET,
  },
}))

type EligibilityStatusApiValue = 'Ineligible' | 'Pass' | 'Not enough data'

const eligibilityStatusApiValueToIsEligible = (
  status: EligibilityStatusApiValue
): boolean | null =>
  status === 'Ineligible' ? false : status === 'Pass' ? true : null

const findHouseholdSizeDescriptionByBenefit = (benefit: BenefitType) => (
  existingDeterminations: EligibilityDeterminationFields[]
): string =>
  existingDeterminations.find(
    (existingDetermination) => existingDetermination.benefit === benefit
  )?.householdSizeDescription ?? ''

const extractExistingDeterminedEligibilitiesMedicaidFull = (
  existingDeterminationsFull: (
    | Person_person_openApplications_MedicaidApplication_eligibilityDeterminations
    | Person_person_openApplications_CharityCareApplication_eligibilityDeterminations
    | Person_person_openApplications_SlideApplication_eligibilityDeterminations
    | Person_person_openApplications_RyanWhiteApplication_eligibilityDeterminations
  )[]
): DeterminedEligibilitiesForFullMedicaid[] =>
  existingDeterminationsFull
    .filter(({benefit}) => /^medicaid/.test(benefit))
    .map(({benefit, reasonOrInfo, secondaryInfo, month, status}) => ({
      name: benefit,
      isPreliminary: false,
      reasonOrInfo,
      isEligible: eligibilityStatusApiValueToIsEligible(
        status as EligibilityStatusApiValue
      ),
      secondaryInfo: secondaryInfo!,
      month: month!,
    }))

const getIsCharityCareDeterminedEligibility = ({
  benefit,
}: {
  benefit: string
}): boolean => /^charityCare/.test(benefit)

const extractExistingDeterminedEligibilitiesCharityCareFull = (
  existingDeterminationsFull: (
    | Person_person_openApplications_MedicaidApplication_eligibilityDeterminations
    | Person_person_openApplications_CharityCareApplication_eligibilityDeterminations
    | Person_person_openApplications_SlideApplication_eligibilityDeterminations
    | Person_person_openApplications_RyanWhiteApplication_eligibilityDeterminations
  )[]
): DeterminedEligibilitiesForFullCharityCare[] =>
  existingDeterminationsFull
    .filter(getIsCharityCareDeterminedEligibility)
    .map(
      ({
        benefit,
        reasonOrInfo,
        secondaryInfo,
        numMonths,
        additionalData,
        status,
      }) => ({
        name: benefit,
        isPreliminary: false,
        reasonOrInfo,
        isEligible: eligibilityStatusApiValueToIsEligible(
          status as EligibilityStatusApiValue
        ),
        secondaryInfo: secondaryInfo ?? undefined,
        numMonths: numMonths ?? 1,
        additionalData: additionalData ?? undefined,
      })
    )

const extractExistingDeterminedEligibilitiesSlideFull = (
  existingDeterminationsFull: (
    | Person_person_openApplications_MedicaidApplication_eligibilityDeterminations
    | Person_person_openApplications_CharityCareApplication_eligibilityDeterminations
    | Person_person_openApplications_SlideApplication_eligibilityDeterminations
    | Person_person_openApplications_RyanWhiteApplication_eligibilityDeterminations
  )[]
): DeterminedEligibilitiesForFullSlide[] =>
  existingDeterminationsFull
    .filter(({benefit}) => /^slide/.test(benefit))
    .map(({benefit, reasonOrInfo, secondaryInfo, status}) => ({
      name: benefit,
      isPreliminary: false,
      reasonOrInfo,
      isEligible: eligibilityStatusApiValueToIsEligible(
        status as EligibilityStatusApiValue
      ),
      secondaryInfo: secondaryInfo ?? undefined,
    }))

const extractExistingDeterminedEligibilitiesRyanWhiteFull = (
  existingDeterminationsFull: (
    | Person_person_openApplications_MedicaidApplication_eligibilityDeterminations
    | Person_person_openApplications_CharityCareApplication_eligibilityDeterminations
    | Person_person_openApplications_SlideApplication_eligibilityDeterminations
    | Person_person_openApplications_RyanWhiteApplication_eligibilityDeterminations
  )[]
): DeterminedEligibilitiesForFullRyanWhite[] =>
  existingDeterminationsFull
    .filter(({benefit}) => /^ryanWhite/.test(benefit))
    .map(({benefit, reasonOrInfo, secondaryInfo, status}) => ({
      name: benefit,
      isPreliminary: false,
      reasonOrInfo,
      isEligible: eligibilityStatusApiValueToIsEligible(
        status as EligibilityStatusApiValue
      ),
      secondaryInfo: secondaryInfo ?? undefined,
    }))

const extractExistingDeterminedEligibilities = (
  person: Person_person
): {
  existingDeterminedEligibilitiesPreliminary: DeterminedEligibilitiesForBenefit[]
  existingHouseholdSizeDescriptionMedicaidPreliminary: string
  existingHouseholdSizeDescriptionCharityCarePreliminary: string
  existingDeterminedEligibilitiesMedicaidFull: DeterminedEligibilitiesForFullMedicaid[]
  existingDeterminedEligibilitiesCharityCareFull: DeterminedEligibilitiesForFullCharityCare[]
  existingDeterminedEligibilitiesSlideFull: DeterminedEligibilitiesForFullSlide[]
  existingDeterminedEligibilitiesRyanWhiteFull: DeterminedEligibilitiesForFullRyanWhite[]
  existingHouseholdSizeDescriptionMedicaidFull: string
  existingHouseholdSizeDescriptionCharityCareFull: string
  existingHouseholdSizeDescriptionSlideFull: string
  existingHouseholdSizeDescriptionRyanWhiteFull: string
} => {
  const existingDeterminationsPreliminary =
    person.openTriages.find(
      ({eligibilityDeterminations}) => eligibilityDeterminations.length
    )?.eligibilityDeterminations ?? []
  const existingDeterminationsFull: (
    | Person_person_openApplications_MedicaidApplication_eligibilityDeterminations
    | Person_person_openApplications_CharityCareApplication_eligibilityDeterminations
    | Person_person_openApplications_SlideApplication_eligibilityDeterminations
    | Person_person_openApplications_RyanWhiteApplication_eligibilityDeterminations
  )[] = [
    ...(person.openApplications.find(
      ({eligibilityDeterminations, benefit}) =>
        eligibilityDeterminations.length && benefit === 'medicaid'
    )?.eligibilityDeterminations ?? []),
    ...(person.openApplications.find(
      ({eligibilityDeterminations, benefit}) =>
        eligibilityDeterminations.length && benefit === 'charityCare'
    )?.eligibilityDeterminations ?? []),
    ...(person.openApplications.find(
      ({eligibilityDeterminations, benefit}) =>
        eligibilityDeterminations.length && benefit === 'slide'
    )?.eligibilityDeterminations ?? []),
    ...(person.openApplications.find(
      ({eligibilityDeterminations, benefit}) =>
        eligibilityDeterminations.length && benefit === 'ryanWhite'
    )?.eligibilityDeterminations ?? []),
  ]
  return {
    existingDeterminedEligibilitiesPreliminary: existingDeterminationsPreliminary.map(
      ({benefit, reasonOrInfo, status, secondaryInfo}) => ({
        name: benefit,
        isPreliminary: true,
        reasonOrInfo,
        isEligible: eligibilityStatusApiValueToIsEligible(
          status as EligibilityStatusApiValue
        ),
        secondaryInfo,
      })
    ),
    existingHouseholdSizeDescriptionMedicaidPreliminary: findHouseholdSizeDescriptionByBenefit(
      BenefitType.medicaid
    )(existingDeterminationsPreliminary),
    existingHouseholdSizeDescriptionCharityCarePreliminary: findHouseholdSizeDescriptionByBenefit(
      BenefitType.charityCare
    )(existingDeterminationsPreliminary),
    existingDeterminedEligibilitiesMedicaidFull: extractExistingDeterminedEligibilitiesMedicaidFull(
      existingDeterminationsFull
    ),
    existingDeterminedEligibilitiesCharityCareFull: extractExistingDeterminedEligibilitiesCharityCareFull(
      existingDeterminationsFull
    ),
    existingDeterminedEligibilitiesSlideFull: extractExistingDeterminedEligibilitiesSlideFull(
      existingDeterminationsFull
    ),
    existingDeterminedEligibilitiesRyanWhiteFull: extractExistingDeterminedEligibilitiesRyanWhiteFull(
      existingDeterminationsFull
    ),
    existingHouseholdSizeDescriptionMedicaidFull:
      existingDeterminationsFull.find(({benefit}) => benefit === 'medicaid')
        ?.householdSizeDescription ?? '',
    existingHouseholdSizeDescriptionCharityCareFull:
      existingDeterminationsFull.find(getIsCharityCareDeterminedEligibility)
        ?.householdSizeDescription ?? '',
    existingHouseholdSizeDescriptionSlideFull:
      existingDeterminationsFull.find(({benefit}) => benefit === 'slide')
        ?.householdSizeDescription ?? '',
    existingHouseholdSizeDescriptionRyanWhiteFull:
      existingDeterminationsFull.find(({benefit}) => benefit === 'ryanWhite')
        ?.householdSizeDescription ?? '',
  }
}

type CanonicalValues = FormCanonicalValues<
  ExtractFormSchemaFields<typeof editPersonFormSchema>
>

const getFieldsUpdatedAt = (fieldsFreshness: any): any =>
  mapValues(fieldsFreshness, (field) =>
    isArray(field)
      ? field.map((fieldItem) => getFieldsUpdatedAt(fieldItem))
      : field.confirmed
      ? new Date()
      : field.updatedAt
  )

type MapFieldsUpdatedAtType = <TFields extends FormSchemaFields>(
  canonicalValues: FormCanonicalValues<TFields>,
  fieldsFreshness: any
) => any

const mapFieldsUpdatedAt: MapFieldsUpdatedAtType = (
  canonicalValues,
  fieldsFreshness
) => ({
  person: {
    ...canonicalValues.person,
    fieldsUpdatedAt: getFieldsUpdatedAt(
      omit(fieldsFreshness.person, ['relationships'])
    ),
    relationships: (canonicalValues.person.relationships as any[]).map(
      (relationship, index) => ({
        ...relationship,
        fieldsUpdatedAt: getFieldsUpdatedAt(
          omit(fieldsFreshness.person.relationships[index], ['otherPerson'])
        ),
        otherPerson: {
          ...relationship.otherPerson,
          fieldsUpdatedAt: getFieldsUpdatedAt(
            fieldsFreshness.person.relationships[index].otherPerson
          ),
        },
      })
    ),
  },
})

type PhoneNumber = CanonicalValues['person']['phoneNumbers'][number]
type PhoneNumberWithIndex = PhoneNumber & {
  index: number
}
type IncomeSource = CanonicalValues['person']['incomeSources'][number]
type IncomeSourceWithIndex = IncomeSource & {
  index: number
}
type PhoneNumbersOrIncomeSourcesWithIndex = {
  phoneNumbers: PhoneNumberWithIndex[]
  incomeSources: IncomeSourceWithIndex[]
}

const getPhoneNumbersOrIncomeSourcesAwaitingEsignSessionIdSinglePerson = (
  person:
    | CanonicalValues['person']
    | CanonicalValues['person']['relationships'][number]['otherPerson']
): PhoneNumbersOrIncomeSourcesWithIndex => ({
  phoneNumbers: person.phoneNumbers
    .map((phoneNumber, index) => ({
      ...phoneNumber,
      index,
    }))
    .filter(
      ({usedForEsign, esignSessionId}) => usedForEsign && !esignSessionId
    ),
  incomeSources: person.incomeSources
    .map((incomeSource, index) => ({
      ...incomeSource,
      index,
    }))
    .filter(
      ({usedForEsign, esignSessionId}) => usedForEsign && !esignSessionId
    ),
})

const getPhoneNumbersOrIncomeSourcesAwaitingEsignSessionId = (
  person: CanonicalValues['person']
): PhoneNumbersOrIncomeSourcesWithIndex[] =>
  [person, ...person.relationships.map(({otherPerson}) => otherPerson)].map(
    getPhoneNumbersOrIncomeSourcesAwaitingEsignSessionIdSinglePerson
  )

const getMatchingPhoneNumber = (
  phoneNumberWaiting: PhoneNumberWithIndex,
  phoneNumbersPersonWithIndex: PhoneNumberWithIndex[]
): PhoneNumberWithIndex | undefined => {
  if (phoneNumberWaiting.id) {
    return phoneNumbersPersonWithIndex.find(
      ({id}) => id === phoneNumberWaiting.id
    )
  }
  return phoneNumbersPersonWithIndex.find(
    ({index, number}) =>
      index === phoneNumberWaiting.index && number === phoneNumberWaiting.number
  )
}

const getMatchingPhoneNumbersLookup = (
  phoneNumbersWaiting: PhoneNumberWithIndex[],
  phoneNumbersPerson: PhoneNumber[]
): {matchingPhoneNumbersLookup: number[]; didMatchAll: boolean} => {
  const lookup = typedAs<number[]>([])
  let didMatchAll = true
  const phoneNumbersPersonWithIndex = phoneNumbersPerson.map(
    (phoneNumber, index) => ({
      ...phoneNumber,
      index,
    })
  )
  phoneNumbersWaiting.forEach((phoneNumberWaiting, phoneNumberWaitingIndex) => {
    const matchingPhoneNumberPerson = getMatchingPhoneNumber(
      phoneNumberWaiting,
      phoneNumbersPersonWithIndex
    )
    if (matchingPhoneNumberPerson) {
      lookup[matchingPhoneNumberPerson.index] = phoneNumberWaitingIndex
    } else {
      didMatchAll = false
    }
  })

  return {
    matchingPhoneNumbersLookup: lookup,
    didMatchAll,
  }
}

const getMatchingIncomeSource = (
  incomeSourceWaiting: IncomeSourceWithIndex,
  incomeSourcesPersonWithIndex: IncomeSourceWithIndex[]
): IncomeSourceWithIndex | undefined => {
  if (incomeSourceWaiting.id) {
    return incomeSourcesPersonWithIndex.find(
      ({id}) => id === incomeSourceWaiting.id
    )
  }
  return incomeSourcesPersonWithIndex.find(
    ({index, employerPhone}) =>
      index === incomeSourceWaiting.index &&
      employerPhone === incomeSourceWaiting.employerPhone
  )
}

const getMatchingIncomeSourcesLookup = (
  incomeSourcesWaiting: IncomeSourceWithIndex[],
  incomeSourcesPerson: IncomeSource[]
): {matchingIncomeSourcesLookup: number[]; didMatchAll: boolean} => {
  const lookup = typedAs<number[]>([])
  let didMatchAll = true
  const incomeSourcesPersonWithIndex = incomeSourcesPerson.map(
    (incomeSource, index) => ({
      ...incomeSource,
      index,
    })
  )
  incomeSourcesWaiting.forEach(
    (incomeSourceWaiting, incomeSourceWaitingIndex) => {
      const matchingIncomeSourcePerson = getMatchingIncomeSource(
        incomeSourceWaiting,
        incomeSourcesPersonWithIndex
      )
      if (matchingIncomeSourcePerson) {
        lookup[matchingIncomeSourcePerson.index] = incomeSourceWaitingIndex
      } else {
        didMatchAll = false
      }
    }
  )

  return {
    matchingIncomeSourcesLookup: lookup,
    didMatchAll,
  }
}

interface WaitForPhoneNumberOrIncomeSourceEsignSessionIdProps {
  personId: string
  phoneNumbersOrIncomeSources: PhoneNumbersOrIncomeSourcesWithIndex[]
  onPopulated: (
    personIndex: number,
    phoneNumberOrIncomeSourceId: string,
    esignSessionId: string,
    collectionName: 'phoneNumbers' | 'incomeSources'
  ) => void
  onFinishedPopulating: () => void
  totalNumExpectedPhoneNumbersOrIncomeSources: {
    phoneNumbers: number
    incomeSources: number
  }[]
}

const WaitForPhoneNumberOrIncomeSourceEsignSessionId: FC<WaitForPhoneNumberOrIncomeSourceEsignSessionIdProps> = flowMax(
  addDisplayName('WaitForPhoneNumberOrIncomeSourceEsignSessionId'),
  addPersonForAwaitingPhoneNumberEsignSessionIdQuery({
    variables: ({personId: id}) => ({
      id,
    }),
    fetchPolicy: 'no-cache',
  }),
  addInterval(
    ({refetchPersonForAwaitingPhoneNumberEsignSessionId}) => () => {
      refetchPersonForAwaitingPhoneNumberEsignSessionId()
    },
    5000
  ),
  addLoadingIndicator({}),
  addPropIdentityStabilization('person'),
  addPhoneNumberOrIncomeSourceEsignSessionUrlsContext,
  addEffect(
    ({person, updatePhoneNumberOrIncomeSourceEsignSessionUrls}) => () => {
      updatePhoneNumberOrIncomeSourceEsignSessionUrls(
        getPhoneNumberOrIncomeSourceEsignSessionUrls(person)
      )
    },
    ['person']
  ),
  addStateHandlers(
    ({phoneNumbersOrIncomeSources}) => ({
      phoneNumberOrIncomeSourceIsWaitingStatuses: phoneNumbersOrIncomeSources.map(
        ({phoneNumbers, incomeSources}) => ({
          phoneNumbers: phoneNumbers.map(() => true),
          incomeSources: incomeSources.map(() => true),
        })
      ),
    }),
    {
      onPhoneNumberOrIncomeSourceSessionIdPopulated: ({
        phoneNumberOrIncomeSourceIsWaitingStatuses,
      }) => (
        personIndex: number,
        phoneNumberOrIncomeSourceIndex: number,
        collectionName: 'phoneNumbers' | 'incomeSources'
      ) => ({
        phoneNumberOrIncomeSourceIsWaitingStatuses: produce(
          phoneNumberOrIncomeSourceIsWaitingStatuses,
          (draftStatuses) => {
            draftStatuses[personIndex][collectionName][
              phoneNumberOrIncomeSourceIndex
            ] = false
          }
        ),
      }),
    }
  ),
  addState('hasSeenFullLengthResponse', 'setHasSeenFullLengthResponse', false),
  addEffect(
    ({
      person,
      phoneNumbersOrIncomeSources,
      onPhoneNumberOrIncomeSourceSessionIdPopulated,
      onFinishedPopulating,
      onPopulated,
      phoneNumberOrIncomeSourceIsWaitingStatuses,
      setHasSeenFullLengthResponse,
      hasSeenFullLengthResponse,
      totalNumExpectedPhoneNumbersOrIncomeSources,
    }) => () => {
      const personAndRelatedPeople = [
        person,
        ...person.relationships.map(({otherPerson}) => otherPerson),
      ]
      if (personAndRelatedPeople.length < phoneNumbersOrIncomeSources.length) {
        if (hasSeenFullLengthResponse) {
          onFinishedPopulating()
        }
        return
      }
      let didSeeNonFullLengthResponse = false
      for (
        let personIndex = 0;
        personIndex < personAndRelatedPeople.length;
        personIndex++
      ) {
        const personOrRelatedPerson = personAndRelatedPeople[personIndex]
        const {
          phoneNumbers: totalNumExpectedPhoneNumbers,
          incomeSources: totalNumExpectedIncomeSources,
        } = totalNumExpectedPhoneNumbersOrIncomeSources[personIndex]
        if (
          personOrRelatedPerson.phoneNumbers.length <
          totalNumExpectedPhoneNumbers
        ) {
          if (hasSeenFullLengthResponse) {
            onFinishedPopulating()
            return
          }
          didSeeNonFullLengthResponse = true
        }
        const {
          matchingPhoneNumbersLookup,
          didMatchAll: didMatchAllPhoneNumbers,
        } = getMatchingPhoneNumbersLookup(
          phoneNumbersOrIncomeSources[personIndex].phoneNumbers,
          personOrRelatedPerson.phoneNumbers
        )
        if (!didMatchAllPhoneNumbers && hasSeenFullLengthResponse) {
          onFinishedPopulating()
          return
        }
        personOrRelatedPerson.phoneNumbers
          .map((phoneNumber, index) => ({
            ...phoneNumber,
            index,
          }))
          // eslint-disable-next-line no-loop-func
          .filter(({index, esignSessionId}) => {
            if (!esignSessionId) return false
            const matchingPhoneNumberIndex = matchingPhoneNumbersLookup[index]
            if (matchingPhoneNumberIndex == null) return false
            return phoneNumberOrIncomeSourceIsWaitingStatuses[personIndex]
              .phoneNumbers[matchingPhoneNumberIndex]
          })
          .forEach(({id, index, esignSessionId}) => {
            onPopulated(personIndex, id, esignSessionId!, 'phoneNumbers')
            onPhoneNumberOrIncomeSourceSessionIdPopulated(
              personIndex,
              matchingPhoneNumbersLookup[index],
              'phoneNumbers'
            )
          })
        if (
          personOrRelatedPerson.incomeSources.length <
          totalNumExpectedIncomeSources
        ) {
          if (hasSeenFullLengthResponse) {
            onFinishedPopulating()
            return
          }
          didSeeNonFullLengthResponse = true
        }
        const {
          matchingIncomeSourcesLookup,
          didMatchAll: didMatchAllIncomeSources,
        } = getMatchingIncomeSourcesLookup(
          phoneNumbersOrIncomeSources[personIndex].incomeSources,
          personOrRelatedPerson.incomeSources
        )
        if (!didMatchAllIncomeSources && hasSeenFullLengthResponse) {
          onFinishedPopulating()
          return
        }
        personOrRelatedPerson.incomeSources
          .map((incomeSource, index) => ({
            ...incomeSource,
            index,
          }))
          // eslint-disable-next-line no-loop-func
          .filter(({index, esignSessionId}) => {
            if (!esignSessionId) return false
            const matchingIncomeSourceIndex = matchingIncomeSourcesLookup[index]
            if (matchingIncomeSourceIndex == null) return false
            return phoneNumberOrIncomeSourceIsWaitingStatuses[personIndex]
              .incomeSources[matchingIncomeSourceIndex]
          })
          .forEach(({id, index, esignSessionId}) => {
            onPopulated(personIndex, id, esignSessionId!, 'incomeSources')
            onPhoneNumberOrIncomeSourceSessionIdPopulated(
              personIndex,
              matchingIncomeSourcesLookup[index],
              'incomeSources'
            )
          })
      }
      if (!didSeeNonFullLengthResponse) {
        setHasSeenFullLengthResponse(true)
      }
    },
    ['person']
  ),
  addProps(({phoneNumberOrIncomeSourceIsWaitingStatuses}) => ({
    isDonePopulating: phoneNumberOrIncomeSourceIsWaitingStatuses
      .flatMap(({phoneNumbers, incomeSources}) => [
        ...phoneNumbers,
        ...incomeSources,
      ])
      .every((waiting) => !waiting),
  })),
  addEffect(
    ({isDonePopulating, onFinishedPopulating}) => () => {
      if (!isDonePopulating) return

      onFinishedPopulating()
    },
    ['isDonePopulating']
  ),
  () => null
)

type AddPhoneNumberOrIncomeSourceEsignSessionUrlsProvidingAndUpdating = <
  TProps extends {
    person: Person_person
  }
>(
  props: TProps
) => TProps

const addPhoneNumberOrIncomeSourceEsignSessionUrlsProvidingAndUpdating: AddPhoneNumberOrIncomeSourceEsignSessionUrlsProvidingAndUpdating = flowMax(
  addPhoneNumberOrIncomeSourceEsignSessionUrlsProviding,
  addEffect(
    ({person, updatePhoneNumberOrIncomeSourceEsignSessionUrls}) => () => {
      updatePhoneNumberOrIncomeSourceEsignSessionUrls(
        getPhoneNumberOrIncomeSourceEsignSessionUrls(person)
      )
    },
    ['person']
  )
)

const getHouseholdMembers = ({
  application,
  formCanonicalValues,
}: {
  application: Person_person_openApplications
  formCanonicalValues: FormCanonicalValues<
    ExtractFormSchemaFields<typeof editPersonFormSchema>
  >
}): ApplicationHouseholdMemberInput[] => {
  if (application.benefit === 'monitor') {
    return [
      {
        personId: formCanonicalValues.person.id,
        wantsCoverage: formCanonicalValues.person.wantsCoverage,
      },
    ]
  }

  const determineHouseholdSizePartsFull =
    application.benefit === 'medicaid'
      ? determineHouseholdSizePartsFullMedicaid
      : application.benefit === 'charityCare'
      ? determineHouseholdSizePartsFullCharityCare
      : application.benefit === 'slide'
      ? determineHouseholdSizePartsFullSlide
      : determineHouseholdSizePartsFullRyanWhite

  return (determineHouseholdSizePartsFull(formCanonicalValues)
    .flatMap(([_factor, people]) => people)
    .filter(
      (householdMember) => !isPersonWithoutIncome(householdMember)
    ) as PersonWithRelationshipType[]).map(({id: personId, wantsCoverage}) => ({
    personId: personId!,
    wantsCoverage,
  }))
}

type Check = Assert<
  SchemaDoesntHaveExtraFields<
    typeof editPersonFormSchema,
    typeof addUpdatePersonMutation
  >
>

const EditPersonForm: FC = flowMax(
  addDisplayName('EditPersonForm'),
  addAppSnackbarContext,
  addTranslationHelpers,
  addRouteParams<{id: string}>(),
  addPersonQuery({
    variables: ({id}) => ({id}),
  }),
  addState('benefit', 'setBenefit', 'charityCare'),
  addState('initialDateOfService', 'setInitialDateOfService', new Date()),
  addFederalPovertyLevelsQuery({
    variables: ({benefit, initialDateOfService}) => ({
      benefit,
      initialDateOfService,
    }),
  }),
  addWebformsQuery({}),
  addLoadingIndicator({}),
  addWebformsContextProvider,
  addUpdatePersonMutation({}),
  addClasses(classes),
  addStateHandlers(
    flow(
      ({person}) => extractExistingDeterminedEligibilities(person),
      ({
        existingDeterminedEligibilitiesPreliminary,
        existingHouseholdSizeDescriptionMedicaidPreliminary,
        existingHouseholdSizeDescriptionCharityCarePreliminary,
        existingDeterminedEligibilitiesMedicaidFull,
        existingDeterminedEligibilitiesCharityCareFull,
        existingDeterminedEligibilitiesSlideFull,
        existingDeterminedEligibilitiesRyanWhiteFull,
        existingHouseholdSizeDescriptionMedicaidFull,
        existingHouseholdSizeDescriptionCharityCareFull,
        existingHouseholdSizeDescriptionSlideFull,
        existingHouseholdSizeDescriptionRyanWhiteFull,
      }) => ({
        determinedEligibilitiesPreliminaryRealtime: existingDeterminedEligibilitiesPreliminary,
        determinedEligibilitiesPreliminary: existingDeterminedEligibilitiesPreliminary,
        determinedEligibilitiesPreliminaryPrevious: existingDeterminedEligibilitiesPreliminary,
        householdSizeDescriptionMedicaidPreliminaryRealtime: existingHouseholdSizeDescriptionMedicaidPreliminary,
        householdSizeDescriptionMedicaidPreliminary: existingHouseholdSizeDescriptionMedicaidPreliminary,
        householdSizeDescriptionMedicaidPreliminaryPrevious: existingHouseholdSizeDescriptionMedicaidPreliminary,
        householdSizeDescriptionCharityCarePreliminaryRealtime: existingHouseholdSizeDescriptionCharityCarePreliminary,
        householdSizeDescriptionCharityCarePreliminary: existingHouseholdSizeDescriptionCharityCarePreliminary,
        householdSizeDescriptionCharityCarePreliminaryPrevious: existingHouseholdSizeDescriptionCharityCarePreliminary,
        determinedEligibilitiesMedicaidFull: existingDeterminedEligibilitiesMedicaidFull,
        determinedEligibilitiesMedicaidFullPrevious: existingDeterminedEligibilitiesMedicaidFull,
        determinedEligibilitiesCharityCareFullRealtime: existingDeterminedEligibilitiesCharityCareFull,
        determinedEligibilitiesCharityCareFull: existingDeterminedEligibilitiesCharityCareFull,
        determinedEligibilitiesCharityCareFullPrevious: existingDeterminedEligibilitiesCharityCareFull,
        determinedEligibilitiesSlideFullRealtime: existingDeterminedEligibilitiesSlideFull,
        determinedEligibilitiesSlideFull: existingDeterminedEligibilitiesSlideFull,
        determinedEligibilitiesSlideFullPrevious: existingDeterminedEligibilitiesSlideFull,
        determinedEligibilitiesRyanWhiteFullRealtime: existingDeterminedEligibilitiesRyanWhiteFull,
        determinedEligibilitiesRyanWhiteFull: existingDeterminedEligibilitiesRyanWhiteFull,
        determinedEligibilitiesRyanWhiteFullPrevious: existingDeterminedEligibilitiesRyanWhiteFull,
        householdSizeDescriptionMedicaidFull: existingHouseholdSizeDescriptionMedicaidFull,
        householdSizeDescriptionMedicaidFullPrevious: existingHouseholdSizeDescriptionMedicaidFull,
        householdSizeDescriptionCharityCareFullRealtime: existingHouseholdSizeDescriptionCharityCareFull,
        householdSizeDescriptionCharityCareFull: existingHouseholdSizeDescriptionCharityCareFull,
        householdSizeDescriptionCharityCareFullPrevious: existingHouseholdSizeDescriptionCharityCareFull,
        householdSizeDescriptionSlideFullRealtime: existingHouseholdSizeDescriptionSlideFull,
        householdSizeDescriptionSlideFull: existingHouseholdSizeDescriptionSlideFull,
        householdSizeDescriptionSlideFullPrevious: existingHouseholdSizeDescriptionSlideFull,
        householdSizeDescriptionRyanWhiteFullRealtime: existingHouseholdSizeDescriptionRyanWhiteFull,
        householdSizeDescriptionRyanWhiteFull: existingHouseholdSizeDescriptionRyanWhiteFull,
        householdSizeDescriptionRyanWhiteFullPrevious: existingHouseholdSizeDescriptionRyanWhiteFull,
      })
    ),
    {
      onEligibilityCheck: () => (
        determinedEligibilities: Array<DeterminedEligibilitiesForBenefit>
      ) => ({
        determinedEligibilitiesPreliminaryRealtime: determinedEligibilities.filter(
          ({isPreliminary}) => isPreliminary
        ),
        determinedEligibilitiesCharityCareFullRealtime: determinedEligibilities.filter(
          ({isPreliminary, name}) => !isPreliminary && /^charityCare/.test(name)
        ) as DeterminedEligibilitiesForFullCharityCare[],
        determinedEligibilitiesSlideFullRealtime: determinedEligibilities.filter(
          ({isPreliminary, name}) => !isPreliminary && /^slide/.test(name)
        ) as DeterminedEligibilitiesForFullSlide[],
        determinedEligibilitiesRyanWhiteFullRealtime: determinedEligibilities.filter(
          ({isPreliminary, name}) => !isPreliminary && /^ryanWhite/.test(name)
        ) as DeterminedEligibilitiesForFullRyanWhite[],
      }),
      updateEligibilityPreliminary: ({
        determinedEligibilitiesPreliminary,
        determinedEligibilitiesPreliminaryRealtime,
      }) => () => ({
        determinedEligibilitiesPreliminary: determinedEligibilitiesPreliminaryRealtime,
        determinedEligibilitiesPreliminaryPrevious: determinedEligibilitiesPreliminary,
      }),
      setEligibilityMedicaidFull: ({determinedEligibilitiesMedicaidFull}) => (
        newDeterminedEligibilitiesMedicaidFull: DeterminedEligibilitiesForFullMedicaid[]
      ) => ({
        determinedEligibilitiesMedicaidFull: newDeterminedEligibilitiesMedicaidFull,
        determinedEligibilitiesMedicaidFullPrevious: determinedEligibilitiesMedicaidFull,
      }),
      updateEligibilityCharityCareFull: ({
        determinedEligibilitiesCharityCareFull,
        determinedEligibilitiesCharityCareFullRealtime,
      }) => () => ({
        determinedEligibilitiesCharityCareFull: determinedEligibilitiesCharityCareFullRealtime,
        determinedEligibilitiesCharityCareFullPrevious: determinedEligibilitiesCharityCareFull,
      }),
      updateEligibilitySlideFull: ({
        determinedEligibilitiesSlideFull,
        determinedEligibilitiesSlideFullRealtime,
      }) => () => ({
        determinedEligibilitiesSlideFull: determinedEligibilitiesSlideFullRealtime,
        determinedEligibilitiesSlideFullPrevious: determinedEligibilitiesSlideFull,
      }),
      updateEligibilityRyanWhiteFull: ({
        determinedEligibilitiesRyanWhiteFull,
        determinedEligibilitiesRyanWhiteFullRealtime,
      }) => () => ({
        determinedEligibilitiesRyanWhiteFull: determinedEligibilitiesRyanWhiteFullRealtime,
        determinedEligibilitiesRyanWhiteFullPrevious: determinedEligibilitiesRyanWhiteFull,
      }),
      revertDeterminedEligibilitiesAndHouseholdSizeDescriptionPreliminary: ({
        determinedEligibilitiesPreliminaryPrevious,
        householdSizeDescriptionMedicaidPreliminaryPrevious,
        householdSizeDescriptionCharityCarePreliminaryPrevious,
      }) => () => ({
        determinedEligibilitiesPreliminary: determinedEligibilitiesPreliminaryPrevious,
        householdSizeDescriptionMedicaidPreliminary: householdSizeDescriptionMedicaidPreliminaryPrevious,
        householdSizeDescriptionCharityCarePreliminary: householdSizeDescriptionCharityCarePreliminaryPrevious,
      }),
      revertDeterminedEligibilitiesAndHouseholdSizeDescriptionMedicaidFull: ({
        determinedEligibilitiesMedicaidFullPrevious,
        householdSizeDescriptionMedicaidFullPrevious,
      }) => () => ({
        determinedEligibilitiesMedicaidFull: determinedEligibilitiesMedicaidFullPrevious,
        householdSizeDescriptionMedicaidFull: householdSizeDescriptionMedicaidFullPrevious,
      }),
      revertDeterminedEligibilitiesAndHouseholdSizeDescriptionCharityCareFull: ({
        determinedEligibilitiesCharityCareFullPrevious,
        householdSizeDescriptionCharityCareFullPrevious,
      }) => () => ({
        determinedEligibilitiesCharityCareFull: determinedEligibilitiesCharityCareFullPrevious,
        householdSizeDescriptionCharityCareFull: householdSizeDescriptionCharityCareFullPrevious,
      }),
      revertDeterminedEligibilitiesAndHouseholdSizeDescriptionSlideFull: ({
        determinedEligibilitiesSlideFullPrevious,
        householdSizeDescriptionSlideFullPrevious,
      }) => () => ({
        determinedEligibilitiesSlideFull: determinedEligibilitiesSlideFullPrevious,
        householdSizeDescriptionSlideFull: householdSizeDescriptionSlideFullPrevious,
      }),
      revertDeterminedEligibilitiesAndHouseholdSizeDescriptionRyanWhiteFull: ({
        determinedEligibilitiesRyanWhiteFullPrevious,
        householdSizeDescriptionRyanWhiteFullPrevious,
      }) => () => ({
        determinedEligibilitiesRyanWhiteFull: determinedEligibilitiesRyanWhiteFullPrevious,
        householdSizeDescriptionRyanWhiteFull: householdSizeDescriptionRyanWhiteFullPrevious,
      }),
      updateHouseholdSizeDescriptionMedicaidPreliminaryRealtime: () => (
        description: string
      ) => ({
        householdSizeDescriptionMedicaidPreliminaryRealtime: description,
      }),
      updateHouseholdSizeDescriptionCharityCarePreliminaryRealtime: () => (
        description: string
      ) => ({
        householdSizeDescriptionCharityCarePreliminaryRealtime: description,
      }),
      updateHouseholdSizeDescriptionMedicaidPreliminary: ({
        householdSizeDescriptionMedicaidPreliminary,
        householdSizeDescriptionMedicaidPreliminaryRealtime,
      }) => () => ({
        householdSizeDescriptionMedicaidPreliminary: householdSizeDescriptionMedicaidPreliminaryRealtime,
        householdSizeDescriptionMedicaidPreliminaryPrevious: householdSizeDescriptionMedicaidPreliminary,
      }),
      updateHouseholdSizeDescriptionCharityCarePreliminary: ({
        householdSizeDescriptionCharityCarePreliminary,
        householdSizeDescriptionCharityCarePreliminaryRealtime,
      }) => () => ({
        householdSizeDescriptionCharityCarePreliminary: householdSizeDescriptionCharityCarePreliminaryRealtime,
        householdSizeDescriptionCharityCarePreliminaryPrevious: householdSizeDescriptionCharityCarePreliminary,
      }),
      updateHouseholdSizeDescriptionCharityCareFullRealtime: () => (
        description: string
      ) => ({
        householdSizeDescriptionCharityCareFullRealtime: description,
      }),
      updateHouseholdSizeDescriptionSlideFullRealtime: () => (
        description: string
      ) => ({
        householdSizeDescriptionSlideFullRealtime: description,
      }),
      updateHouseholdSizeDescriptionRyanWhiteFullRealtime: () => (
        description: string
      ) => ({
        householdSizeDescriptionRyanWhiteFullRealtime: description,
      }),
      setHouseholdSizeDescriptionMedicaidFull: ({
        householdSizeDescriptionMedicaidFull,
      }) => (newHouseholdSizeDescription?: string) => ({
        householdSizeDescriptionMedicaidFull: newHouseholdSizeDescription,
        householdSizeDescriptionMedicaidFullPrevious: householdSizeDescriptionMedicaidFull,
      }),
      updateHouseholdSizeDescriptionCharityCareFull: ({
        householdSizeDescriptionCharityCareFull,
        householdSizeDescriptionCharityCareFullRealtime,
      }) => () => ({
        householdSizeDescriptionCharityCareFull: householdSizeDescriptionCharityCareFullRealtime,
        householdSizeDescriptionCharityCareFullPrevious: householdSizeDescriptionCharityCareFull,
      }),
      updateHouseholdSizeDescriptionSlideFull: ({
        householdSizeDescriptionSlideFull,
        householdSizeDescriptionSlideFullRealtime,
      }) => () => ({
        householdSizeDescriptionSlideFull: householdSizeDescriptionSlideFullRealtime,
        householdSizeDescriptionSlideFullPrevious: householdSizeDescriptionSlideFull,
      }),
      updateHouseholdSizeDescriptionRyanWhiteFull: ({
        householdSizeDescriptionRyanWhiteFull,
        householdSizeDescriptionRyanWhiteFullRealtime,
      }) => () => ({
        householdSizeDescriptionRyanWhiteFull: householdSizeDescriptionRyanWhiteFullRealtime,
        householdSizeDescriptionRyanWhiteFullPrevious: householdSizeDescriptionRyanWhiteFull,
      }),
    }
  ),
  addHandlers(
    {
      onUpdateSuccess: ({showSnackbarMessage, t}) => () => {
        showSnackbarMessage(t('personForm.updatedPerson'))
      },
    },
    ['showSnackbarMessage', 't']
  ),
  addProps(
    ({person: {openApplications, openTriages}}) => ({
      openMonitorApplication: openApplications.find(
        ({benefit}) => benefit === 'monitor'
      ) as Person_person_openApplications_MonitorApplication | undefined,
      openCharityCareApplication: openApplications.find(
        ({benefit}) => benefit === 'charityCare'
      ) as Person_person_openApplications_CharityCareApplication | undefined,
      openSlideApplication: openApplications.find(
        ({benefit}) => benefit === 'slide'
      ) as Person_person_openApplications_SlideApplication | undefined,
      openRyanWhiteApplication: openApplications.find(
        ({benefit}) => benefit === 'ryanWhite'
      ) as Person_person_openApplications_RyanWhiteApplication | undefined,
      hasOpenTriage: !!openTriages.length,
    }),
    ['person']
  ),
  addProps(
    ({
      openCharityCareApplication,
      openMonitorApplication,
      openSlideApplication,
      openRyanWhiteApplication,
    }) => ({
      hasCharityCareApplication: !!openCharityCareApplication,
      hasMonitorApplication: !!openMonitorApplication,
      hasSlideApplication: !!openSlideApplication,
      hasRyanWhiteApplication: !!openRyanWhiteApplication,
    }),
    [
      'openCharityCareApplication',
      'openMonitorApplication',
      'openSlideApplication',
      'openRyanWhiteApplication',
    ]
  ),
  addGetIsConfiguredBenefit,
  addProps(
    ({
      hasMonitorApplication,
      hasCharityCareApplication,
      hasSlideApplication,
      hasRyanWhiteApplication,
      openCharityCareApplication,
      openMonitorApplication,
      openSlideApplication,
      openRyanWhiteApplication,
      hasOpenTriage,
      federalPovertyLevels,
      getIsConfiguredBenefit,
      t,
    }) => ({
      determiners: [
        hasOpenTriage &&
          getIsConfiguredBenefit(BenefitType.medicaid) &&
          determinerPreliminaryMedicaid({federalPovertyLevels, t}),
        hasOpenTriage &&
          getIsConfiguredBenefit(BenefitType.charityCare) &&
          determinerPreliminaryCharityCare({federalPovertyLevels, t}),
        hasMonitorApplication &&
          determinerFullMonitor({
            application: openMonitorApplication!,
          }),
        hasCharityCareApplication &&
          determinerFullCharityCare({
            federalPovertyLevels,
            application: openCharityCareApplication!,
            t,
          }),
        hasSlideApplication &&
          determinerFullSlide({
            federalPovertyLevels,
            application: openSlideApplication!,
            t,
          }),
        hasRyanWhiteApplication &&
          determinerFullRyanWhite({
            federalPovertyLevels,
            application: openRyanWhiteApplication!,
            t,
          }),
      ].filter((x) => x) as Determiner[],
      incomeSourceFormDeterminers: [
        hasCharityCareApplication && determinerIncomeSourceFormCharityCare,
      ].filter((x) => x) as Determiner[],
    }),
    [
      'hasOpenTriage',
      'federalPovertyLevels',
      'hasMonitorApplication',
      'hasCharityCareApplication',
      'hasSlideApplication',
      'hasRyanWhiteApplication',
      'openCharityCareApplication',
      'openMonitorApplication',
      'openSlideApplication',
      'openRyanWhiteApplication',
      'getIsConfiguredBenefit',
      't',
    ]
  ),
  addPropIdentityStabilization('person'),
  addPhoneNumberOrIncomeSourceEsignSessionUrlsProvidingAndUpdating,
  addProps(
    ({person}) => ({
      initialValues: {person},
    }),
    ['person']
  ),
  addWrapper(
    (
      render,
      {
        mutateUpdatePerson,
        onUpdateSuccess,
        initialValues,
        onEligibilityCheck,
        classes,
        determiners,
      }
    ) => (
      <Form
        name="personForm"
        mutate={mutateUpdatePerson}
        onSubmitSuccess={onUpdateSuccess}
        shouldResetFormOnInitialValuesChange
        resetOnSaveSuccess={true}
        schema={editPersonFormSchema}
        initialValues={initialValues}
        determiners={determiners}
        onEligibilityCheck={onEligibilityCheck}
        shouldTrackFieldsUpdatedAt
        mapFieldsUpdatedAt={mapFieldsUpdatedAt}
        className={classes.form}
      >
        {render()}
      </Form>
    )
  ),
  addSaveEligibilityDeterminationsMutation({
    onError: ({
      revertDeterminedEligibilitiesAndHouseholdSizeDescriptionPreliminary,
      revertDeterminedEligibilitiesAndHouseholdSizeDescriptionMedicaidFull,
      revertDeterminedEligibilitiesAndHouseholdSizeDescriptionCharityCareFull,
      person: {openTriages, openApplications},
      t,
    }) => () => {
      if (openTriages.length) {
        revertDeterminedEligibilitiesAndHouseholdSizeDescriptionPreliminary()
      }
      const nonfrozenApplications = openApplications.filter(
        (application) => !isApplicationHouseholdFrozen(application)
      )
      if (
        nonfrozenApplications.filter(({benefit}) => benefit === 'medicaid')
          .length
      ) {
        revertDeterminedEligibilitiesAndHouseholdSizeDescriptionMedicaidFull()
      }
      if (
        nonfrozenApplications.filter(({benefit}) => benefit === 'charityCare')
          .length
      ) {
        revertDeterminedEligibilitiesAndHouseholdSizeDescriptionCharityCareFull()
      }
      window.alert(t('personForm.eligibilityCheck.failedToSave'))
    },
  }),
  addFormCanonicalValuesContextTyped(editPersonFormSchema),
  // eslint-disable-next-line ad-hok/dependencies
  addEffect(
    ({
      updateHouseholdSizeDescriptionMedicaidPreliminaryRealtime,
      updateHouseholdSizeDescriptionCharityCarePreliminaryRealtime,
      updateHouseholdSizeDescriptionCharityCareFullRealtime,
      updateHouseholdSizeDescriptionSlideFullRealtime,
      updateHouseholdSizeDescriptionRyanWhiteFullRealtime,
      formCanonicalValues,
      t,
    }) => () => {
      const descriptionMedicaidPreliminary = getHouseholdSizeDescriptionPreliminaryMedicaid(
        formCanonicalValues,
        t
      )
      const descriptionCharityCarePreliminary = getHouseholdSizeDescriptionPreliminaryCharityCare(
        formCanonicalValues,
        t
      )
      const descriptionCharityCareFull = getHouseholdSizeDescriptionFullCharityCare(
        formCanonicalValues,
        t
      )
      const descriptionSlideFull = getHouseholdSizeDescriptionFullSlide(
        formCanonicalValues,
        t
      )
      const descriptionRyanWhiteFull = getHouseholdSizeDescriptionFullRyanWhite(
        formCanonicalValues,
        t
      )
      updateHouseholdSizeDescriptionMedicaidPreliminaryRealtime(
        descriptionMedicaidPreliminary
      )
      updateHouseholdSizeDescriptionCharityCarePreliminaryRealtime(
        descriptionCharityCarePreliminary
      )
      updateHouseholdSizeDescriptionCharityCareFullRealtime(
        descriptionCharityCareFull
      )
      updateHouseholdSizeDescriptionSlideFullRealtime(descriptionSlideFull)
      updateHouseholdSizeDescriptionRyanWhiteFullRealtime(
        descriptionRyanWhiteFull
      )
    },
    ['formCanonicalValues']
  ),
  addFormikTyped(editPersonFormSchema),
  addState('documents', 'setDocuments', (): RequiredDocument[] => []),
  // eslint-disable-next-line ad-hok/dependencies
  addEffect(
    ({
      formik: {values},
      documents,
      setDocuments,
      person: {openApplications},
    }) => () => {
      const determinedDocuments = determineDocuments(
        getCanonicalValues(values, editPersonFormSchema),
        openApplications
      )
      if (
        determinedDocuments !== documents &&
        !isEqual(determinedDocuments, documents)
      ) {
        setDocuments(determinedDocuments)
      }
    },
    ['formik.values', 'person.openApplications']
  ),
  addFormContext,
  addFieldsFreshnessContext,
  addHandlers(
    {
      saveAndCheckEligibilities: ({
        formik: {validateForm, resetForm},
        mutateSaveEligibilityDeterminations,
        updateEligibilityPreliminary,
        determinedEligibilitiesPreliminaryRealtime,
        updateHouseholdSizeDescriptionMedicaidPreliminary,
        updateHouseholdSizeDescriptionCharityCarePreliminary,
        householdSizeDescriptionMedicaidPreliminaryRealtime,
        householdSizeDescriptionCharityCarePreliminaryRealtime,
        formCanonicalValues: {person},
        formCanonicalValues,
        fieldsFreshness,
        person: {openTriages, openApplications},
        federalPovertyLevels,
        setEligibilityMedicaidFull,
        updateEligibilityCharityCareFull,
        updateEligibilitySlideFull,
        updateEligibilityRyanWhiteFull,
        setHouseholdSizeDescriptionMedicaidFull,
        updateHouseholdSizeDescriptionCharityCareFull,
        updateHouseholdSizeDescriptionSlideFull,
        updateHouseholdSizeDescriptionRyanWhiteFull,
        determinedEligibilitiesCharityCareFullRealtime,
        determinedEligibilitiesSlideFullRealtime,
        determinedEligibilitiesRyanWhiteFullRealtime,
        householdSizeDescriptionCharityCareFullRealtime,
        householdSizeDescriptionSlideFullRealtime,
        householdSizeDescriptionRyanWhiteFullRealtime,
        onUpdateSuccess,
        t,
      }) => async () => {
        const errors = await validateForm()
        if (Object.keys(errors).length) {
          window.alert(t('personForm.eligibilityCheck.invalidPersonForm'))
          return
        }
        const determinedEligibilities: {
          input: EligibilityDeterminationInput
          determination: DeterminedEligibilitiesForFullMedicaid
        }[] = []
        const determinations: EligibilityDeterminationInput[] = []
        const applicationHouseholdMembers: ApplicationHouseholdMembersInput[] = []
        if (openTriages.length) {
          updateEligibilityPreliminary()
          updateHouseholdSizeDescriptionMedicaidPreliminary()
          updateHouseholdSizeDescriptionCharityCarePreliminary()
          openTriages.forEach(({id: triageId}) => {
            determinations.push(
              ...determinedEligibilitiesPreliminaryRealtime.map(
                ({name, isEligible, reasonOrInfo, secondaryInfo}) => ({
                  benefit: name,
                  personId: person.id,
                  triageId,
                  status: eligibilityStatusToApiValue(
                    getEligibilityStatus({
                      isEligible,
                    })
                  ),
                  reasonOrInfo,
                  householdSizeDescription:
                    name === 'medicaid'
                      ? householdSizeDescriptionMedicaidPreliminaryRealtime
                      : name === 'charityCare'
                      ? householdSizeDescriptionCharityCarePreliminaryRealtime
                      : null,
                  secondaryInfo,
                })
              )
            )
          })
        }
        const nonfrozenApplications = openApplications.filter(
          (application) =>
            !isApplicationHouseholdFrozen(application) &&
            getIsBenefitTypeWithEligibilityDeterminations(application.benefit)
        )
        if (
          nonfrozenApplications.filter(({benefit}) => benefit === 'charityCare')
            .length
        ) {
          updateEligibilityCharityCareFull()
          updateHouseholdSizeDescriptionCharityCareFull()
        }
        if (
          nonfrozenApplications.filter(({benefit}) => benefit === 'slide')
            .length
        ) {
          updateEligibilitySlideFull()
          updateHouseholdSizeDescriptionSlideFull()
        }

        if (
          nonfrozenApplications.filter(({benefit}) => benefit === 'ryanWhite')
            .length
        ) {
          updateEligibilityRyanWhiteFull()
          updateHouseholdSizeDescriptionRyanWhiteFull()
        }
        const openMedicaidApplication = nonfrozenApplications.find(
          (application) => application.benefit === 'medicaid'
        )
        const eligibilityDeterminations = checkMedicaidEligibility({
          person,
          application: openMedicaidApplication,
          federalPovertyLevels,
          t,
        })
        determinedEligibilities.push(...eligibilityDeterminations)

        determinations.push(
          ...eligibilityDeterminations.map(({input}) => input)
        )

        nonfrozenApplications.forEach((application) => {
          applicationHouseholdMembers.push({
            applicationId: application.id,
            householdMembers: getHouseholdMembers({
              application,
              formCanonicalValues,
            }),
          })

          if (application.benefit === 'medicaid') {
            return
          }

          const determinedEligibilitiesFullRealtime: DeterminedEligibilitiesForBenefit[] =
            application.benefit === 'charityCare'
              ? determinedEligibilitiesCharityCareFullRealtime
              : application.benefit === 'slide'
              ? determinedEligibilitiesSlideFullRealtime
              : determinedEligibilitiesRyanWhiteFullRealtime

          const householdSizeDescriptionFullRealtime =
            application.benefit === 'charityCare'
              ? householdSizeDescriptionCharityCareFullRealtime
              : application.benefit === 'slide'
              ? householdSizeDescriptionSlideFullRealtime
              : householdSizeDescriptionRyanWhiteFullRealtime
          determinations.push(
            ...determinedEligibilitiesFullRealtime.map(
              ({
                name,
                isEligible,
                reasonOrInfo,
                secondaryInfo,
                month,
                numMonths,
                additionalData,
              }) => ({
                benefit: name,
                personId: person.id,
                applicationId: application.id,
                status: eligibilityStatusToApiValue(
                  getEligibilityStatus({
                    isEligible,
                  })
                ),
                reasonOrInfo,
                secondaryInfo,
                month,
                numMonths,
                additionalData,
                householdSizeDescription: householdSizeDescriptionFullRealtime,
              })
            )
          )
        })
        mutateSaveEligibilityDeterminations({
          variables: {
            ...mapFieldsUpdatedAt({person}, fieldsFreshness),
            determinations,
            householdMembers: applicationHouseholdMembers,
          },
        }).then(() => {
          resetForm()

          setEligibilityMedicaidFull(
            determinedEligibilities.map(({determination}) => determination)
          )
          if (determinedEligibilities.length > 0) {
            setHouseholdSizeDescriptionMedicaidFull(
              determinedEligibilities[0].input.householdSizeDescription ||
                undefined
            )
          }
          onUpdateSuccess()
        })
      },
    },
    [
      'formik.validateForm',
      'formik.resetForm',
      'mutateSaveEligibilityDeterminations',
      'updateEligibilityPreliminary',
      'determinedEligibilitiesPreliminaryRealtime',
      'updateHouseholdSizeDescriptionMedicaidPreliminary',
      'updateHouseholdSizeDescriptionCharityCarePreliminary',
      'formCanonicalValues',
      'householdSizeDescriptionMedicaidPreliminaryRealtime',
      'householdSizeDescriptionCharityCarePreliminaryRealtime',
      'fieldsFreshness',
      'person.openTriages',
      'person.openApplications',
      'federalPovertyLevels',
      'setEligibilityMedicaidFull',
      'updateEligibilityCharityCareFull',
      'updateEligibilitySlideFull',
      'updateEligibilityRyanWhiteFull',
      'setHouseholdSizeDescriptionMedicaidFull',
      'updateHouseholdSizeDescriptionCharityCareFull',
      'updateHouseholdSizeDescriptionSlideFull',
      'updateHouseholdSizeDescriptionRyanWhiteFull',
      'determinedEligibilitiesCharityCareFullRealtime',
      'determinedEligibilitiesSlideFullRealtime',
      'determinedEligibilitiesRyanWhiteFullRealtime',
      'householdSizeDescriptionCharityCareFullRealtime',
      'householdSizeDescriptionSlideFullRealtime',
      'householdSizeDescriptionRyanWhiteFullRealtime',
      'onUpdateSuccess',
      't',
    ]
  ),
  addStateHandlers(
    {
      shouldSaveAndCheckEligibilitiesOnceAllRelatedPeopleHaveIds: false,
    },
    {
      markShouldSaveAndCheckEligibilitiesOnceAllRelatedPeopleHaveIds: () => () => ({
        shouldSaveAndCheckEligibilitiesOnceAllRelatedPeopleHaveIds: true,
      }),
      clearShouldSaveAndCheckEligibilitiesOnceAllRelatedPeopleHaveIds: () => () => ({
        shouldSaveAndCheckEligibilitiesOnceAllRelatedPeopleHaveIds: false,
      }),
    }
  ),
  addEffect(
    ({
      shouldSaveAndCheckEligibilitiesOnceAllRelatedPeopleHaveIds,
      saveAndCheckEligibilities,
      formCanonicalValues: {
        person: {relationships},
      },
      clearShouldSaveAndCheckEligibilitiesOnceAllRelatedPeopleHaveIds,
    }) => () => {
      if (!shouldSaveAndCheckEligibilitiesOnceAllRelatedPeopleHaveIds) return

      if (relationships.some(({otherPerson: {id}}) => !id)) return

      saveAndCheckEligibilities()
      clearShouldSaveAndCheckEligibilitiesOnceAllRelatedPeopleHaveIds()
    },
    [
      'shouldSaveAndCheckEligibilitiesOnceAllRelatedPeopleHaveIds',
      'formCanonicalValues.person.relationships',
    ]
  ),
  addState(
    'phoneNumbersOrIncomeSourcesAwaitingEsignSessionId',
    'setPhoneNumbersOrIncomeSourcesAwaitingEsignSessionId',
    typedAs<{
      phoneNumbersOrIncomeSources: PhoneNumbersOrIncomeSourcesWithIndex[]
      totalNumExpectedPhoneNumbersOrIncomeSources: {
        phoneNumbers: number
        incomeSources: number
      }[]
    } | null>(null)
  ),
  addWrapper(
    (
      render,
      {
        phoneNumbersOrIncomeSourcesAwaitingEsignSessionId,
        setPhoneNumbersOrIncomeSourcesAwaitingEsignSessionId,
        formik: {setFieldValue},
        person,
        showSnackbarMessage,
        t,
      }
    ) => (
      <>
        {!!phoneNumbersOrIncomeSourcesAwaitingEsignSessionId && (
          <WaitForPhoneNumberOrIncomeSourceEsignSessionId
            personId={person.id}
            {...phoneNumbersOrIncomeSourcesAwaitingEsignSessionId}
            onPopulated={(
              personIndex: number,
              phoneNumberOrIncomeSourceId: string,
              esignSessionId: string,
              collectionName: 'phoneNumbers' | 'incomeSources'
            ) => {
              const personAndRelatedPeople = [
                person,
                ...person.relationships.map(({otherPerson}) => otherPerson),
              ]
              const phoneNumberOrIncomeSourceIndex = typedAs<
                {
                  id: string
                }[]
              >(personAndRelatedPeople[personIndex][collectionName]).findIndex(
                ({id}) => id === phoneNumberOrIncomeSourceId
              )
              if (phoneNumberOrIncomeSourceIndex === -1) return
              setFieldValue(
                `person.${
                  personIndex > 0
                    ? `relationships[${personIndex - 1}].otherPerson.`
                    : ''
                }${collectionName}[${phoneNumberOrIncomeSourceIndex}].esignSessionId`,
                esignSessionId
              )
              showSnackbarMessage(t('personForm.createdEsignSession'))
            }}
            onFinishedPopulating={() => {
              setPhoneNumbersOrIncomeSourcesAwaitingEsignSessionId(null)
            }}
          />
        )}
        {render()}
      </>
    )
  ),
  addHandlers(
    {
      saveAndCheckEligibilities: ({
        formik: {validateForm},
        saveAndCheckEligibilities,
        formCanonicalValues: {person},
        mutateUpdatePerson,
        markShouldSaveAndCheckEligibilitiesOnceAllRelatedPeopleHaveIds,
        clearShouldSaveAndCheckEligibilitiesOnceAllRelatedPeopleHaveIds,
        fieldsFreshness,
        setPhoneNumbersOrIncomeSourcesAwaitingEsignSessionId,
        t,
      }) => async () => {
        const errors = await validateForm()
        if (Object.keys(errors).length) {
          window.alert(t('personForm.eligibilityCheck.invalidPersonForm'))
          return
        }
        const phoneNumbersOrIncomeSourcesAwaitingEsignSessionId = getPhoneNumbersOrIncomeSourcesAwaitingEsignSessionId(
          person
        )
        if (
          phoneNumbersOrIncomeSourcesAwaitingEsignSessionId.flatMap(
            ({phoneNumbers, incomeSources}) => [
              ...phoneNumbers,
              ...incomeSources,
            ]
          ).length
        ) {
          setPhoneNumbersOrIncomeSourcesAwaitingEsignSessionId({
            phoneNumbersOrIncomeSources: phoneNumbersOrIncomeSourcesAwaitingEsignSessionId,
            totalNumExpectedPhoneNumbersOrIncomeSources: [
              person,
              ...person.relationships.map(({otherPerson}) => otherPerson),
            ].map(({phoneNumbers, incomeSources}) => ({
              phoneNumbers: phoneNumbers.length,
              incomeSources: incomeSources.length,
            })),
          })
        }
        const hasUnsavedRelatedPerson = person.relationships.some(
          ({otherPerson: {id}}) => !id
        )
        if (!hasUnsavedRelatedPerson) {
          saveAndCheckEligibilities()
          return
        }

        mutateUpdatePerson({
          variables: {
            ...mapFieldsUpdatedAt({person}, fieldsFreshness),
          },
        }).catch(() => {
          clearShouldSaveAndCheckEligibilitiesOnceAllRelatedPeopleHaveIds()
          window.alert(t('personForm.eligibilityCheck.failedToSave'))
        })
        markShouldSaveAndCheckEligibilitiesOnceAllRelatedPeopleHaveIds()
      },
    },
    [
      'formik.validateForm',
      'saveAndCheckEligibilities',
      'formCanonicalValues',
      'mutateUpdatePerson',
      'markShouldSaveAndCheckEligibilitiesOnceAllRelatedPeopleHaveIds',
      'clearShouldSaveAndCheckEligibilitiesOnceAllRelatedPeopleHaveIds',
      'fieldsFreshness',
      'setPhoneNumbersOrIncomeSourcesAwaitingEsignSessionId',
      't',
    ]
  ),
  addStateHandlers(
    {
      shouldSaveAndCheckEligibilitiesOnceApplicationHasEligibilityDeterminations: typedAs<{
        id: string
        benefit: BenefitType
      } | null>(null),
    },
    {
      markShouldSaveAndCheckEligibilitiesOnceApplicationHasEligibilityDeterminations: () => (application: {
        id: string
        benefit: BenefitType
      }) => ({
        shouldSaveAndCheckEligibilitiesOnceApplicationHasEligibilityDeterminations: application,
      }),
      clearShouldSaveAndCheckEligibilitiesOnceApplicationHasEligibilityDeterminations: () => () => ({
        shouldSaveAndCheckEligibilitiesOnceApplicationHasEligibilityDeterminations: null,
      }),
    }
  ),
  addEffect(
    ({
      shouldSaveAndCheckEligibilitiesOnceApplicationHasEligibilityDeterminations,
      saveAndCheckEligibilities,
      determinedEligibilitiesCharityCareFullRealtime,
      determinedEligibilitiesSlideFullRealtime,
      determinedEligibilitiesRyanWhiteFullRealtime,
      clearShouldSaveAndCheckEligibilitiesOnceApplicationHasEligibilityDeterminations,
      person: {openApplications},
    }) => () => {
      if (
        !shouldSaveAndCheckEligibilitiesOnceApplicationHasEligibilityDeterminations
      )
        return

      if (
        !openApplications.some(
          (openApplication) =>
            openApplication.id ===
            shouldSaveAndCheckEligibilitiesOnceApplicationHasEligibilityDeterminations.id
        )
      )
        return

      const fire = () => {
        saveAndCheckEligibilities()
        clearShouldSaveAndCheckEligibilitiesOnceApplicationHasEligibilityDeterminations()
      }

      const benefit =
        shouldSaveAndCheckEligibilitiesOnceApplicationHasEligibilityDeterminations.benefit

      if (benefit === BenefitType.monitor || benefit === BenefitType.medicaid) {
        fire()
        return
      }

      const determinedEligibilitiesByBenefit: Record<
        typeof benefit,
        DeterminedEligibilitiesForBenefit[]
      > = {
        [BenefitType.charityCare]: determinedEligibilitiesCharityCareFullRealtime,
        [BenefitType.slide]: determinedEligibilitiesSlideFullRealtime,
        [BenefitType.ryanWhite]: determinedEligibilitiesRyanWhiteFullRealtime,
      }
      if (!determinedEligibilitiesByBenefit[benefit].length) return

      fire()
    },
    [
      'shouldSaveAndCheckEligibilitiesOnceApplicationHasEligibilityDeterminations',
      'determinedEligibilitiesCharityCareFullRealtime',
      'determinedEligibilitiesSlideFullRealtime',
      'determinedEligibilitiesRyanWhiteFullRealtime',
      'person.openApplications',
    ]
  ),
  addHandlers(
    {
      onApplicationCreate: ({
        refetchPerson,
        id,
        markShouldSaveAndCheckEligibilitiesOnceApplicationHasEligibilityDeterminations,
      }) => (
        opts: OnSubmitSuccessOptions<
          ExtractFormSchemaFields<typeof createApplicationFormSchemaStatic>,
          CreateApplication
        >
      ) => {
        refetchPerson({id})
        markShouldSaveAndCheckEligibilitiesOnceApplicationHasEligibilityDeterminations(
          opts.data!.createApplication
        )
      },
    },
    [
      'refetchPerson',
      'id',
      'markShouldSaveAndCheckEligibilitiesOnceApplicationHasEligibilityDeterminations',
    ]
  ),
  addRightColumnContextProvider,
  addShouldShowApplicationPromptContextProviderFromRightColumnContext,
  addStateHandlers(
    {
      hasNecessaryDocumentsByPersonOrApplication: {} as {
        [personOrApplicationSlug: string]: boolean
      },
    },
    {
      setHasNecessaryDocuments: ({
        hasNecessaryDocumentsByPersonOrApplication,
      }) => (
        personOrApplicationSlug: string,
        hasNecessaryDocuments: boolean
      ) => ({
        hasNecessaryDocumentsByPersonOrApplication: {
          ...hasNecessaryDocumentsByPersonOrApplication,
          [personOrApplicationSlug]: hasNecessaryDocuments,
        },
      }),
    }
  ),
  addProps(
    ({hasNecessaryDocumentsByPersonOrApplication}) => ({
      hasNecessaryDocuments: Object.values(
        hasNecessaryDocumentsByPersonOrApplication
      ).some((hasNecessaryDocuments) => hasNecessaryDocuments),
    }),
    ['hasNecessaryDocumentsByPersonOrApplication']
  ),
  addDocumentsSectionContextProvider,
  addStateHandlers(
    {
      viewingRelationshipIndex: VIEWING_RELATIONSHIP_INDEX_CLIENT,
      scrollToSectionName: typedAs<string | null>(null),
      scrollToFieldName: typedAs<{
        sectionName: string
        fieldName: string
      } | null>(null),
    },
    {
      setViewingRelationshipIndex: () => (relationshipIndex: number) => ({
        viewingRelationshipIndex: relationshipIndex,
      }),
      setScrollToSectionName: () => (sectionName: string) => ({
        scrollToSectionName: sectionName,
        scrollToFieldName: null,
      }),
      setScrollToFieldName: () => (sectionName: string, fieldName: string) => ({
        scrollToSectionName: null,
        scrollToFieldName: {
          sectionName,
          fieldName,
        },
      }),
    }
  ),
  addEffect(
    ({scrollToSectionName, scrollToFormSection}) => () => {
      if (!scrollToSectionName) return

      scrollToFormSection(scrollToSectionName)
    },
    ['viewingRelationshipIndex']
  ),
  addEffect(
    ({scrollToFieldName, scrollToFormField}) => () => {
      if (!scrollToFieldName) return

      scrollToFormField(
        scrollToFieldName.sectionName,
        scrollToFieldName.fieldName
      )
    },
    ['viewingRelationshipIndex']
  ),
  ({
    classes,
    person,
    formik: {
      values: {
        person: {relationships},
      },
      values,
    },
    documents,
    saveAndCheckEligibilities,
    viewingRelationshipIndex,
    setViewingRelationshipIndex,
    setScrollToSectionName,
    setScrollToFieldName,
  }) => (
    <Grid container className={classes.container} direction="row">
      <LeftColumn
        person={person}
        relationships={relationships}
        documents={documents}
        saveAndCheckEligibilities={saveAndCheckEligibilities}
        viewingRelationshipIndex={viewingRelationshipIndex}
        setViewingRelationshipIndex={setViewingRelationshipIndex}
        setScrollToSectionName={setScrollToSectionName}
        setScrollToFieldName={setScrollToFieldName}
      />
      <Grid item className={classes.formColumnContainer}>
        <div className={classes.formColumnInnerContainer}>
          {viewingRelationshipIndex === VIEWING_RELATIONSHIP_INDEX_CLIENT && (
            <>
              <PersonSectionHeader personValues={values.person} />
              <FormPrefix prefix="person.">
                <FormSection labelTranslationKey="personData">
                  <ReadOnlyTextField name="id" noConfirmButton />
                  <TextField name="hospitalPatientId" />
                  <SharedPersonDataFields />
                </FormSection>
                <SharedPersonSections />
              </FormPrefix>
            </>
          )}
          {viewingRelationshipIndex >= 0 && (
            <RelationshipSection relationshipIndex={viewingRelationshipIndex} />
          )}
          <DocumentsSection
            show={
              viewingRelationshipIndex === VIEWING_RELATIONSHIP_INDEX_DOCUMENTS
            }
          />
        </div>
      </Grid>
      <RightColumn />
    </Grid>
  )
)

export default EditPersonForm
